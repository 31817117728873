import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated } from "../store";

const ExtracurricularFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [name, setName] = useState("");
  const [score, setScore] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const { student_id, classroom_id, academic_id, period_id } = useParams();
  const records = {
    student_id,
    classroom_id,
    academic_id,
    period_id,
    name,
    score,
    description,
  };

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post("/extracurriculars", records, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setName("");
        setScore("");
        setDescription("");
      }

      // console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col">
          <label htmlFor="nama" className="form-label">
            Nama Extrakurikuler
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col">
          <label htmlFor="score" className="form-label">
            Nilai
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nilai"
            id="score"
            value={score}
            onChange={(e) => setScore(e.target.value)}
          />
          {errors.score ? (
            <div className="invalid-feedback">{errors.score[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12">
          <label htmlFor="description" className="form-label">
            Keterangan
          </label>
          <textarea
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Keterangan"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={4}
          ></textarea>
          {errors.description ? (
            <div className="invalid-feedback">{errors.description[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => redirect(-1)}
          >
            BACK
          </button>
        </div>
        <div className="col">
          <button type="submit" className="btn btn-primary float-end">
            SUBMIT
          </button>
        </div>
      </div>
    </form>
  );
};

export default ExtracurricularFormComponent;
