import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import LessonsFormComponent from "../components/LessonsFormComponent";
import { authenticated, lcategorielist } from "../store";

const LessonsFormContainer = () => {
  const [lcategories, setLcategories] = useRecoilState(lcategorielist);
  const [auth, setAuth] = useRecoilState(authenticated);
  const [errors, setErrors] = useState(false);
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/lessons/create", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setLcategories(response.data);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Mata Pelajaran</h2>
      </div>
      <div className="card-body">
        <LessonsFormComponent />
      </div>
    </div>
  );
};

export default LessonsFormContainer;
