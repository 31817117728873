import axios from "axios";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import AlertComponent from "../components/AlertComponent";
import { authenticated } from "../store";
import MarginForm from "./MarginForm";

const MarginContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [configs, setConfigs] = useState([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get(`/configs/margin`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setConfigs(response.data);
        setErrors([]);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const updateConfig = async (name, records) => {
    try {
      let response = await axios.put(
        `configs/update`,
        { name: name, setting: records },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });

        setErrors([]);
      }
    } catch (error) {
      setErrors(error.response.data);
      //   console.log(error);
    }
  };

  const whereSetting = (name) => {
    const data = configs.find((row) => {
      return row.name === name;
    });

    return data;
  };

  // const inputConfig = (name, el) => {
  //   const change = configs.find((row) => {
  //     return row.name === name;
  //   });

  //   const input = el.target;
  //   change[input.name] = input.value;

  //   const update = configs.filter((row) => {
  //     return row.name !== name;
  //   });

  //   update.push(change);

  //   setConfigs(update);
  //   console.log(configs);
  // };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }

    let mounted = true;
    if (mounted) {
      getData();
    }

    return () => {
      mounted = false;
    };
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Pengaturan</h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <AlertComponent color={"danger"} message={errors.message} />
          ) : (
            ""
          )}
        </div>

        {whereSetting("ismuba-margin") !== undefined ? (
          <MarginForm
            title="Margin Rapot Ismuba"
            margin={whereSetting("ismuba-margin")}
            errors={errors}
            updateConfig={(records) => updateConfig("ismuba-margin", records)}
          />
        ) : (
          ""
        )}
        {whereSetting("pts-margin") !== undefined ? (
          <MarginForm
            title="Margin Rapot PTS"
            margin={whereSetting("pts-margin")}
            errors={errors}
            updateConfig={(records) => updateConfig("pts-margin", records)}
          />
        ) : (
          ""
        )}
        {whereSetting("pas-margin") !== undefined ? (
          <MarginForm
            title="Margin Rapot PAS"
            margin={whereSetting("pas-margin")}
            errors={errors}
            updateConfig={(records) => updateConfig("pas-margin", records)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MarginContainer;
