import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import ExtracurricularFormComponent from "../components/ExtracurricularFormComponent";
import { authenticated, extradetail, lcategorielist } from "../store";

const ExtracurricularFormContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [extra, setExtra] = useState(false);
  const [errors, setErrors] = useState(false);
  const { student_id, classroom_id, academic_id, period_id } = useParams();
  const params =
    student_id + "/" + classroom_id + "/" + academic_id + "/" + period_id;
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get(
        "/extracurriculars/" + params + "/create",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setExtra(response.data);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Extrakurikuler Siswa {extra?.student?.name}</h2>
      </div>
      <div className="card-body">
        <div className="row mb-5">
          <div className="col-md-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Nama Siswa</th>
                  <th>:</th>
                  <th>{extra?.student?.name}</th>
                </tr>
                <tr>
                  <th>NIS</th>
                  <th>:</th>
                  <th>{extra?.student?.nis}</th>
                </tr>
                <tr>
                  <th>Nama Wali Kelas</th>
                  <th>:</th>
                  <th>{auth?.user?.name}</th>
                </tr>
                <tr>
                  <th>Kelas</th>
                  <th>:</th>
                  <th>{extra?.classroom?.name}</th>
                </tr>
                <tr>
                  <th>Tahun Ajaran</th>
                  <th>:</th>
                  <th>{extra?.academic?.nickname}</th>
                </tr>
                <tr>
                  <th>Semester</th>
                  <th>:</th>
                  <th>
                    <span className="badge bg-success fs-6">
                      {extra?.period?.name}
                    </span>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div className="row">
          <ExtracurricularFormComponent />
        </div>
      </div>
    </div>
  );
};

export default ExtracurricularFormContainer;
