import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PaginationComponent from "./PaginationComponent";
import Swal from "sweetalert2";

const NoticeListComponent = (props) => {
  const [errors, setErrors] = useState([]);

  const page = () => {
    let url = window.location.href;
    url = url.split("#page/", 2);
    if (url[1] !== undefined) {
      return url[1];
    } else {
      return null;
    }
  };

  const delData = async (id) => {
    try {
      let response = await axios.delete("/notices/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });

        props.updateData(page());
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="row">
          <div className="col">
            <table className="table table-bordered">
              <thead>
                <tr className="text-center">
                  <th scope="col">#</th>
                  <th scope="col">Nama</th>
                  <th colSpan={2}>Action</th>
                </tr>
              </thead>
              <tbody>
                {props.notices.data.map((row, index) => (
                  <tr key={index} className="text-center">
                    <th scope="row">{index + 1}</th>
                    <td>{row.name}</td>
                    <td>
                      <Link to={"/notices/" + row.id + "/edit"}>
                        <button className="btn btn-primary">EDIT</button>
                      </Link>
                    </td>
                    <td>
                      <button
                        onClick={() => delData(row.id)}
                        className="btn btn-danger"
                      >
                        HAPUS
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <PaginationComponent
          data={props.notices.links}
          handlePaginate={(page) => props.updateData(page)}
        />
      </div>
    </div>
  );
};

export default NoticeListComponent;
