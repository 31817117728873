import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, classroomshow, teachersinclasslist } from "../store";
import CopyDataComponent from "./CopyDataComponent";
import SmallLoadingComponent from "./SmallLoadingComponent";

const AddTeacherFormComponent = (props) => {
  const [auth] = useRecoilState(authenticated);
  const [classroomdetail] = useRecoilState(classroomshow);
  const [teachersinclass, setTeachersinclass] =
    useRecoilState(teachersinclasslist);
  const [lessons, setLessons] = useState("");
  const [teachers, setTeachers] = useState("");
  const [lesson_id, setLesson_id] = useState("");
  const [name, setName] = useState("");
  const [nip, setNip] = useState("");
  const [errors, setErrors] = useState([]);
  const [show, setShow] = useState(false);
  const redirect = useNavigate();
  const { id } = useParams();

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/teachers?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setTeachers(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const addTeachers = async (item) => {
    try {
      let response = await axios.post(
        "groups/teacher",
        {
          user_id: item.id,
          classroom_id: id,
          lesson_id,
          academic_id: classroomdetail.classroom.academic_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        // setTeachersinclass([...teachersinclass, response.data.teacher]);
        props.updateData();
        getData();
        setName("");
        setNip("");

        return true;
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Your work not save teacher",
        showConfirmButton: true,
      });

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const nameSearch = (name) => {
    setName(name);
    const src = "src=" + name;
    getData(src);
  };

  const nipSearch = (nip) => {
    setNip(nip);
    const src = "nip=" + nip;
    getData(src);
  };

  const delTeacher = async (index, row) => {
    try {
      let response = await axios.post(
        "groups/teacher/delete",
        {
          user_id: row.user_id,
          classroom_id: row.classroom_id,
          academic_id: classroomdetail.classroom.academic_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });

        const coldelete = [
          ...teachersinclass.slice(0, index),
          ...teachersinclass.slice(index + 1),
        ];

        setTeachersinclass(coldelete);
        return true;
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Your work not deleted",
        showConfirmButton: true,
      });

      setErrors(e.response.data);

      return false;
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    if (classroomdetail) {
      setLessons(classroomdetail.lessons);
    }
  }, [auth.login, classroomdetail.academic_id]);

  return (
    <form>
      <div className="row mb-4">
        <div className="col-md-4 col-xs-12 mb-2">
          <label htmlFor="lesson_id" className="form-label">
            Pelajaran
          </label>
          <select
            className={`form-select mb-1 ${
              errors.lesson_id ? "is-invalid" : ""
            }`}
            type="text"
            id="lesson_id"
            value={lesson_id}
            onChange={(e) => setLesson_id(e.target.value)}
          >
            <option value="">Pilih Pelajaran</option>
            {lessons
              ? lessons.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))
              : ""}
          </select>
          {errors.lesson_id ? (
            <div className="invalid-feedback">{errors.lesson_id[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-4 col-xs-12 mb-2">
          <label htmlFor="nama" className="form-label">
            Nama Guru
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => nameSearch(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
          <ul className="list-group suggest">
            {name && teachers
              ? teachers.data.map((row, index) => (
                  <li
                    key={index}
                    className="list-group-item"
                    onClick={() => addTeachers(row)}
                  >
                    {row.name} - {row.nip}
                  </li>
                ))
              : ""}
          </ul>
        </div>
        <div className="col-md-4 col-xs-12 mb-2">
          <label htmlFor="nip" className="form-label">
            NIP Guru
          </label>
          <input
            className={`form-control mb-1 ${errors.nip ? "is-invalid" : ""}`}
            type="text"
            placeholder="NIP"
            id="nama"
            value={nip}
            onChange={(e) => nipSearch(e.target.value)}
          />
          {errors.nip ? (
            <div className="invalid-feedback">{errors.nip[0]}</div>
          ) : (
            ""
          )}
          <ul className="list-group suggest">
            {nip && teachers
              ? teachers.data.map((row, index) => (
                  <li
                    key={index}
                    className="list-group-item"
                    onClick={() => addTeachers(row)}
                  >
                    {row.nip} - {row.name}
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
      <div className="row row-cols-1 mb-4">
        <div className="col">
          <CopyDataComponent
            show={show}
            type={"teacher"}
            actionModal={() => setShow(!show)}
            refreshData={() => props.updateData()}
          />
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-success float-end"
            onClick={() => setShow(!show)}
          >
            COPY DATA
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col table-responsive">
          {teachersinclass ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">NBM</th>
                  <th className="text-center">PELAJARAN</th>
                  <th className="text-center">NAMA</th>
                  <th className="text-center">TAHUN AJARAN</th>
                  <th className="text-center">ACTION</th>
                </tr>
              </thead>

              <tbody>
                {teachersinclass.map((row, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{row.nip}</td>
                    <td>{row.lesson}</td>
                    <td>{row.teacher}</td>
                    <td className="text-center">{row.year}</td>
                    <td className="text-center">
                      <button
                        type="button"
                        onClick={() => delTeacher(index, row)}
                        className="btn btn-danger"
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <SmallLoadingComponent />
          )}
        </div>
      </div>

      <Link to="/classrooms">
        <button className="btn btn-warning mx-4 float-end mt-5">BACK</button>
      </Link>
    </form>
  );
};

export default AddTeacherFormComponent;
