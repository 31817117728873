import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import NoticeFormComponent from "../components/NoticeFormComponent";
import { authenticated } from "../store";

const NoticeFormContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const redirect = useNavigate();

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Pengumuman</h2>
      </div>
      <div className="card-body">
        <NoticeFormComponent />
      </div>
    </div>
  );
};

export default NoticeFormContainer;
