import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilValue } from "recoil";
import SmallLoadingComponent from "../../components/SmallLoadingComponent";
import { authenticated } from "../../store";
import AddKemuhTeacherFormComponent from "./AddKemuhTeacherFormComponent";

const AddKemuhTeacherFormContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [classroom, setClassroom] = useState(false);
  const [errors, setErrors] = useState("");
  const { id } = useParams();
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/regulers/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassroom(response.data.classroom);

      //   console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      {classroom ? (
        <Fragment>
          <div className="card-header">
            <h2>
              Guru Kemuhammadiyahan Kelas{" "}
              <span className="badge bg-success fs-4">{classroom.name}</span>{" "}
              <span className="badge bg-success fs-4 text-wrap">
                {classroom.academic.name}
              </span>
            </h2>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <AddKemuhTeacherFormComponent
                  classroom={classroom}
                  updateData={() => getData()}
                />
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AddKemuhTeacherFormContainer;
