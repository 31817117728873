import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import LoadingComponent from "../components/LoadingComponent";
import ReportClassroomListComponent from "../components/ReportClassroomListComponent";
import {
  authenticated,
  pasdetail,
  ptsdetail,
  reportshow,
  studentlist,
  waiting,
} from "../store";

const ReportClassroomListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [loading, setLoading] = useRecoilState(waiting);
  const [report, setReport] = useRecoilState(reportshow);
  const [pts, setPts] = useRecoilState(ptsdetail);
  const [pas, setPas] = useRecoilState(pasdetail);
  const [students, setStudents] = useRecoilState(studentlist);
  const navigate = useNavigate();

  useEffect(() => {
    setPts(false);
    setPas(false);
    setReport(false);
    setStudents(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }
  }, [auth.login]);

  return (
    <div className="card">
      {loading ? <LoadingComponent /> : <ReportClassroomListComponent />}
    </div>
  );
};

export default ReportClassroomListContainer;
