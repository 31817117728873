import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";

const CompetenceDetailExportComponent = ({
  categorie,
  lesson,
  competence,
  classroom,
  setErrors,
}) => {
  const { id } = useParams();

  const exportTasks = async () => {
    try {
      let response = await axios.get(
        "/competences/" + id + "/export?option=task",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const filename =
          categorie.name +
          "-" +
          lesson.name +
          "-" +
          competence.name +
          "-Kelas " +
          classroom.name;
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  return (
    <div>
      {/* <button
        className="btn btn-success float-end"
        onClick={() => exportCompetence()}
      >
        Dowanload Template
      </button> */}

      <button
        className="btn btn-success float-end mx-4"
        onClick={() => exportTasks()}
      >
        Download Template
      </button>
    </div>
  );
};

export default CompetenceDetailExportComponent;
