import axios from "axios";
import React, { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const StudentInNoteList = (props) => {
  const [errors, setErrors] = useState([]);

  const deleteData = async (id) => {
    try {
      let response = await axios.delete("/in/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });

        props.updateData();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Gagal Hapus Data !",
        showConfirmButton: false,
        timer: 1500,
      });

      setErrors(e.response.data);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4>KETERANGAN PINDAH MASUK SEKOLAH</h4>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-12">
            <Link to={"/students/" + props.student_id + "/in/create"}>
              <button className="btn btn-success float-end m-4">CREATE</button>
            </Link>
          </div>
          <div className="col-12 table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>NIS</th>
                  <th>ASAL SEKOLAH</th>
                  <th>TANGGAL</th>
                  <th>DI KELAS</th>
                  <th colSpan={2}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {props.inData.map((row, index) => (
                  <tr key={index} className="text-center">
                    <td>{index + 1}</td>
                    <td>{row.nis}</td>
                    <td>{row.school_name}</td>
                    <td>
                      <Moment format="DD MMMM YYYY">
                        {row.in_school_date}
                      </Moment>
                    </td>
                    <td>{row.in_classroom}</td>
                    <td>
                      <Link to={"/students/in/" + row.id + "/edit"}>
                        <button className="btn btn-primary">EDIT</button>
                      </Link>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => deleteData(row.id)}
                      >
                        HAPUS
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentInNoteList;
