import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { dashboardlist } from "../store";

const NoticeStatusComponent = () => {
  const [dashboards] = useRecoilState(dashboardlist);

  return (
    <div className="col mb-4">
      <div className="card">
        <div className="card-header text-white bg-success">
          <h4>
            <i className="bi bi-easel-fill"></i> Pengumuman
          </h4>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              {dashboards?.notices?.map((row, index) => (
                <tr key={index}>
                  <th>{row.name}</th>
                  <th>
                    <Link to={"/notices/" + row.id}>
                      <button className="btn btn-success float-end">
                        SELENGKAPNYA
                      </button>
                    </Link>
                  </th>
                </tr>
              ))}
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NoticeStatusComponent;
