import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, categorielist } from "../store";

const AttitudeFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [categories, setCategories] = useRecoilState(categorielist);
  const [categorie_id, setCategorie_id] = useState("");
  const [predicate, setPredicate] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState([]);
  const records = { categorie_id, predicate, description };
  const redirect = useNavigate();

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post("/admin/attitudes", records, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setCategorie_id("");
        setPredicate("");
        setDescription("");
        setErrors([]);
      }

      // console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col-md-6 col-xs-12 mb-2">
          <label htmlFor="categorie_id" className="form-label">
            Kategori
          </label>
          <select
            className={`form-select mb-1 ${
              errors.categorie_id ? "is-invalid" : ""
            }`}
            type="text"
            id="categorie_id"
            value={categorie_id}
            onChange={(e) => setCategorie_id(e.target.value)}
          >
            <option value="">===Pilih Kategori===</option>
            {categories
              ? categories.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))
              : ""}
          </select>
          {errors.categorie_id ? (
            <div className="invalid-feedback">{errors.categorie_id[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-xs-12 mb-2">
          <label htmlFor="predikat" className="form-label">
            Predikat
          </label>
          <select
            className={`form-select mb-1 ${
              errors.predicate ? "is-invalid" : ""
            }`}
            type="text"
            id="predicate"
            value={predicate}
            onChange={(e) => setPredicate(e.target.value)}
          >
            <option value="">===Pilih Nilai Minimal===</option>
            <option value="Sangat Kurang">Sangat Kurang</option>
            <option value="Kurang">Kurang</option>
            <option value="Cukup">Cukup</option>
            <option value="Baik">Baik</option>
            <option value="Sangat Baik">Sangat Baik</option>
          </select>
          {errors.predicate ? (
            <div className="invalid-feedback">{errors.predicate[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12 mb-4">
          <label htmlFor="description" className="form-label">
            Deskripsi
          </label>
          <textarea
            className={`form-control mb-1 ${
              errors.description ? "is-invalid" : ""
            }`}
            type="text"
            rows={5}
            placeholder="Deskripsi"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          {errors.description ? (
            <div className="invalid-feedback">{errors.description[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary float-end">
            SUBMIT
          </button>

          <Link to="/admin/attitudes">
            <button className="btn btn-warning mx-4 float-end">BACK</button>
          </Link>
        </div>
      </div>
    </form>
  );
};

export default AttitudeFormComponent;
