import React from "react";

const DuplicateTeacher = ({ duplicates }) => {
  return (
    <div>
      {duplicates.duplicateMailDatabase ? (
        <ul className="list-group mb-4">
          <li className="list-group-item bg-warning fw-bold">
            Duplicate Email Guru Pada Aplikasi
          </li>
          {duplicates.duplicateMailDatabase.map((row, index) => (
            <li className="list-group-item" key={index}>
              <span className="badge bg-success fs-6">{row.name}</span> EMAIL{" "}
              <span className="badge bg-success fs-6">{row.email}</span>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
      {duplicates.duplicateMailExcel ? (
        <ul className="list-group mb-4">
          <li className="list-group-item bg-warning fw-bold">
            Duplicate Email Guru Pada Excel
          </li>
          {duplicates.duplicateMailExcel.map((row, index) => (
            <li className="list-group-item" key={index}>
              <span className="badge bg-success fs-6">{row}</span>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

export default DuplicateTeacher;
