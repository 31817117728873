import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

const NavbarAdministratorComponent = ({ outAction, hideNav }) => {
  return (
    <ul className="side-nav">
      <li className="nav-item mb-4">
        <div className="row muga-mobile-center justify-content-sm-center justify-content-md-center">
          <div className="muga-mobile col-sm-8 col-md-8 pt-3">
            <img
              src="/muga-logo-200.png"
              alt="SMA Muhammadiyah 3 Jakarta Logo"
              className="img-fluid"
            />
            {/* <img
              src="/sample-logo.png"
              alt="Sample logo"
              className="img-fluid py-3"
            /> */}
          </div>
        </div>
      </li>
      <Accordion flush={true}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Administrators</Accordion.Header>
          <Accordion.Body className="bg-purple p-0">
            <li className="nav-item" onClick={() => hideNav()}>
              <Link
                to="/dashboards"
                className="nav-link active"
                aria-current="page"
              >
                <i className="bi bi-activity"></i> Dashboard
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/administrators" className="nav-link">
                <i className="bi bi-person-badge-fill"></i> Administrator
              </Link>
            </li>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Sekolah</Accordion.Header>
          <Accordion.Body className="bg-purple p-0">
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/academics" className="nav-link">
                <i className="bi bi-calendar-check-fill"></i> Tahun Ajaran
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/clessons" className="nav-link">
                <i className="bi bi-border-all"></i> Kelompok Pelajaran
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/lessons" className="nav-link">
                <i className="bi bi-book-half"></i> Pelajaran
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/categories" className="nav-link">
                <i className="bi bi-tags-fill"></i> Kategori Kompetensi
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="admin/attitudes" className="nav-link">
                <i className="bi bi-list-columns-reverse"></i> Deskripsi Sikap
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/surats" className="nav-link">
                <i className="bi bi-list-columns"></i> Surat
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/classrooms" className="nav-link">
                <i className="bi bi-stack"></i> Kelas
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/teachers" className="nav-link">
                <i className="bi bi-mortarboard-fill"></i> Guru
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/students" className="nav-link">
                <i className="bi bi-people-fill"></i> Siswa
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/notices" className="nav-link">
                <i className="bi bi-easel-fill"></i> Pengumuman
              </Link>
            </li>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Nilai</Accordion.Header>
          <Accordion.Body className="bg-purple p-0">
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/status/score" className="nav-link">
                <i className="bi bi-list-check"></i> Status Input Nilai
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/classrooms/ledger" className="nav-link">
                <i className="bi bi-file-text-fill"></i> Ledger Kelas
              </Link>
            </li>
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/classrooms/report" className="nav-link">
                <i className="bi bi-file-earmark-text-fill"></i> Rapot
              </Link>
            </li>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Pengaturan</Accordion.Header>
          <Accordion.Body className="bg-purple p-0">
            <li className="nav-item" onClick={() => hideNav()}>
              <Link to="/margins" className="nav-link">
                <i className="bi bi-gear-fill"></i> Margin Rapot
              </Link>
            </li>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <li className="nav-item logout-nav" onClick={() => outAction()}>
        <span className="badge bg-danger ms-2 mt-3 pointer">
          {" "}
          <i className="bi bi-arrow-left-circle-fill"></i> Logout
        </span>
      </li>
    </ul>
  );
};

export default NavbarAdministratorComponent;
