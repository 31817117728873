import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import { authenticated } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const ExtracurricularClassroomDetailComponent = (props) => {
  const [auth] = useRecoilState(authenticated);
  const [errors, setErrors] = useState("");
  const { classroom_id, academic_id } = useParams();
  const params = classroom_id + "/" + academic_id + "/detail";
  const redirect = useNavigate();

  return (
    <div>
      <div className="card-header">
        <h2>
          Extrakurikuler Kelas{" "}
          <span className="badge bg-success fs-5">
            {props.data?.classroom?.name}
          </span>
        </h2>
      </div>

      <div className="card-body">
        <div className="col-md-5 mb-5">
          <table className="table">
            <thead>
              <tr>
                <th>Nama Wali Kelas</th>
                <th>:</th>
                <th>{auth?.user.name}</th>
              </tr>
              <tr>
                <th>Kelas</th>
                <th>:</th>
                <th>{props.data?.classroom?.name}</th>
              </tr>
              <tr>
                <th>Tahun Ajaran</th>
                <th>:</th>
                <th>{props.data?.academic?.nickname}</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="col table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">NAMA</th>
                <th className="text-center">NIS</th>
                <th className="text-center">KELAS</th>
                <th className="text-center">PRESTASI</th>
              </tr>
            </thead>
            <tbody>
              {props.data.students.map((student, index) => (
                <tr key={index}>
                  <td scope="row">{index + 1}</td>
                  <td>{student.name}</td>
                  <td>{student.nis}</td>
                  <td>{props.data.classroom.name}</td>
                  <td className="text-center">
                    <Link
                      to={
                        "/extracurriculars/" +
                        student.id +
                        "/" +
                        props.data.classroom.id +
                        "/" +
                        props.data.classroom.academic_id +
                        "/detail"
                      }
                    >
                      <button className="btn btn-primary">
                        DETAIL EXTRAKURIKULER SISWA
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col">
            <button
              type="button"
              onClick={() => redirect(-1)}
              className="btn btn-warning mx-4 float-end"
            >
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtracurricularClassroomDetailComponent;
