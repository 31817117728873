import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import AttitudeEditFormComponent from "../components/AttitudeEditFormComponent";
import AttitudeFormComponent from "../components/AttitudeFormComponent";
import { adminattitudeshow, categorielist } from "../store";

const AttitudeEditFormContainer = () => {
  const [categories, setCategories] = useRecoilState(categorielist);
  const [adminattitude, setAdminattitude] = useRecoilState(adminattitudeshow);
  const [errors, setErrors] = useState([]);
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/admin/attitudes/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setCategories(response.data.categories);
      setAdminattitude(response.data.attitude);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Edit Deskripsi Sikap</h2>
      </div>
      <div className="card-body">
        <AttitudeEditFormComponent />
      </div>
    </div>
  );
};

export default AttitudeEditFormContainer;
