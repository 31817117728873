import axios from "axios";
import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AlertComponent from "../../components/AlertComponent";
import SmallLoadingComponent from "../../components/SmallLoadingComponent";
import { deleteData } from "../../utils/ClassroomFunct";

const ClassroomSuratComponent = ({ surats, setSurats, setErrors }) => {
  const [loading, setLoading] = useState(false);
  const { classroom_id, period_id } = useParams();

  const getData = async () => {
    try {
      setLoading(true);
      let response = await axios.get(
        `/surats/classroom/${classroom_id}?period_id=${period_id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setSurats(response.data);
      setLoading(false);
      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);

      // console.log(e.response.data);
    }
  };

  const delSurat = (surat_id) => {
    Swal.fire({
      title:
        "Yakin dihapus ?, Nilai siswa pada surat ini juga akan terhapus !!",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: `Tidak jadi`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteData(
          `surats/delete/classroom?classroom_id=${classroom_id}&period_id=${period_id}&surat_id=${surat_id}/delete`
        ).then((response) => {
          if (response.status === 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Success",
              text: "Berhasil hapus surat !",
              showConfirmButton: false,
              timer: 1500,
            });

            getData();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.message,
            });
          }
        });
      }
    });
  };

  const statusNilai = (row) => {
    if (!row.findscore) {
      return <span className="badge bg-secondary fs-6">BELUM ADA NILAI</span>;
    } else if (row.findzero !== null) {
      return <span className="badge bg-warning fs-6">NILAI MISS</span>;
    } else {
      return <span className="badge bg-success fs-6">NILAI SUCCESS</span>;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="col table-responsive">
      {surats.length > 0 ? (
        <table className="table table-bordered table-striped">
          <thead className="text-center text-uppercase">
            <tr>
              <th>#</th>
              <th>surat</th>
              <th>status nilai</th>
              <th colSpan={2}>action</th>
            </tr>
          </thead>
          <tbody>
            {surats.map((row, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td>{row.name}</td>
                <td className="text-center">{statusNilai(row)}</td>
                <td className="text-center">
                  <button
                    className="btn btn-danger"
                    onClick={() => delSurat(row.id)}
                  >
                    DELETE
                  </button>
                </td>
                <td className="text-center">
                  <Link
                    to={`/qurans/classroom/${classroom_id}/period/${period_id}/surat/${row.id}`}
                  >
                    <button className="btn btn-primary">INPUT NILAI</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Fragment>
          {loading ? (
            <SmallLoadingComponent />
          ) : (
            <AlertComponent color={"warning"} message="Belum Ada Surat" />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ClassroomSuratComponent;
