import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import { academicdetail, authenticated } from "../store";

const AcademicEditFormComponent = ({ academic }) => {
  const setAuth = useSetRecoilState(authenticated);
  const [errors, setErrors] = useState([]);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [year, setYear] = useState("");
  const [max_group, setMax_group] = useState("");
  const [active, setActive] = useState("");
  const record = {
    name,
    nickname,
    year,
    max_group,
    active,
  };

  const formHandle = async (e) => {
    e.preventDefault();

    try {
      let response = await axios.put("/academics/" + id, record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setErrors([]);
      }

      //   console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (academic !== null) {
      setId(academic.id);
      setName(academic.name);
      setNickname(academic.nickname);
      setYear(academic.year);
      setMax_group(academic.max_group);
      setActive(academic.active);
    }
  }, []);

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col-md-12 col-xs-12 mb-2">
          <label htmlFor="nama" className="form-label">
            Nama Tahun Ajaran
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            name="name"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12 col-xs-12 mb-2">
          <label htmlFor="nickname" className="form-label">
            Nama Singkat
          </label>
          <input
            className={`form-control mb-1 ${
              errors.nickname ? "is-invalid" : ""
            }`}
            type="text"
            name="nickname"
            placeholder="Nama Singkat"
            id="nickname"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
          {errors.nickname ? (
            <div className="invalid-feedback">{errors.nickname[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12 col-xs-12 mb-2">
          <label htmlFor="year" className="form-label">
            Tahun Ajaran
          </label>
          <input
            className={`form-control mb-1 ${errors.year ? "is-invalid" : ""}`}
            type="text"
            name="year"
            placeholder="Tahun Ajaran"
            id="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
          {errors.year ? (
            <div className="invalid-feedback">{errors.year[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <label htmlFor="max_group" className="form-label">
            Jumlah Group Kelas Pada Tahun Ajaran
          </label>
          <input
            className={`form-control mb-1 ${
              errors.max_group ? "is-invalid" : ""
            }`}
            type="number"
            name="max_group"
            placeholder="Jumlah Group Pada Tahun Ajaran"
            id="max_group"
            value={max_group}
            onChange={(e) => setMax_group(e.target.value)}
          />
          {errors.max_group ? (
            <div className="invalid-feedback">{errors.max_group[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col">
          <label htmlFor="active" className="form-label">
            Aktifkan Tahun Ajaran
          </label>
          <select
            className="form-select"
            type="checkbox"
            id="active"
            value={active}
            onChange={() => setActive(!active)}
          >
            <option value={false}>Belum Aktif</option>
            <option value={true}>Aktif</option>
          </select>
        </div>
      </div>
      <button type="submit" className="btn btn-primary float-end">
        UPDATE
      </button>

      <Link to="/academics">
        <button className="btn btn-warning mx-4 float-end">BACK</button>
      </Link>
    </form>
  );
};

export default AcademicEditFormComponent;
