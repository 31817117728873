import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import AlertComponent from "../../components/AlertComponent";
import SmallLoadingComponent from "../../components/SmallLoadingComponent";
import { authenticated } from "../../store";
import CopySuratModalComponent from "./CopySuratModalComponent";
import QuranClassroomComponent from "./QuranClassroomComponent";
import SuratModalComponent from "./SuratModalComponent";

const QuranClassroomContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [loading, setLoading] = useState(true);
  const [classrooms, setClassroms] = useState([]);
  const [classfilter, setClassfilter] = useState([]);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const { type } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get(`/classrooms/ismuba/${type}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassroms(response.data);
      setClassfilter(response.data);
      setLoading(false);
      setErrors([]);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
      // console.log(e.response.data);
    }
  };

  const classroomFilter = (e) => {
    const src = e.target.value;
    const filteredClassrooms = classrooms.filter((item) => {
      return (
        `${item.classroom_name}`.toLowerCase().search(src.toLowerCase()) !== -1
      );
    });

    setClassfilter(filteredClassrooms);
    // console.log(filteredClassrooms);
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }

    var mounted = true;
    if (mounted) {
      setClassroms([]);
      setLoading(true);
      getData();
    }

    return () => {
      mounted = false;
    };
  }, [auth.login, type]);

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="text-capitalize">Qur'an Kelas {type}</h2>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            {errors.message ? (
              <AlertComponent color="danger" message={errors.message} />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-xs-12">
            <input
              type="text"
              className="form-control float-end mb-4 col-2"
              placeholder="Cari Nama Kelas..."
              onChange={(e) => classroomFilter(e)}
            />
          </div>
          <div className="col-md-3 offset-md-3">
            <SuratModalComponent />
          </div>
          {type === "reguler" ? (
            <Fragment>
              <div className="col-md-2">
                <CopySuratModalComponent toClassrooms={classrooms} />
              </div>
            </Fragment>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          {classrooms.length > 0 ? (
            <QuranClassroomComponent
              classrooms={classfilter}
              type={type}
              refreshData={() => getData()}
            />
          ) : (
            <Fragment>
              {loading ? (
                <SmallLoadingComponent />
              ) : (
                <AlertComponent color="warning" message="Tidak ada kelas" />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuranClassroomContainer;
