import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const StundentImportFormComponent = ({ setErrors }) => {
  const [progress, setProgress] = useState(false);
  const [selected_file, setSelected_file] = useState(false);

  const handleOnchange = (event) => {
    setSelected_file(event.target.files[0]);
  };

  const onFileUpload = async () => {
    try {
      let formData = new FormData();
      formData.append("file_import", selected_file, selected_file.name);

      let response = await axios.post("students/import", formData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(percentCompleted);
          setProgress(percentCompleted);
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });

        // props.updateData();
      }
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  return (
    <div className="row row-cols-1">
      <div className="col">
        <div className="float-end">
          <label htmlFor="taks-file" className="form-label">
            Import Siswa
          </label>
          <input
            className="form-control mb-3"
            type="file"
            id="taks-file"
            onChange={(event) => handleOnchange(event)}
          />
          {selected_file ? (
            <button
              className="btn btn-primary float-end"
              onClick={() => onFileUpload()}
            >
              Upload
            </button>
          ) : null}
        </div>
      </div>
      <div className="col mt-3 mb-4">
        {progress ? (
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StundentImportFormComponent;
