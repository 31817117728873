import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CategorieFormComponent from "../components/CategorieFormComponent";
import { authenticated } from "../store";
import { useRecoilState } from "recoil";

const CategorieFormContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const redirect = useNavigate();

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Kategori Kompetensi</h2>
      </div>
      <div className="card-body">
        <CategorieFormComponent />
      </div>
    </div>
  );
};

export default CategorieFormContainer;
