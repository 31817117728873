import React from "react";
import { Link } from "react-router-dom";

const SuratFormComponent = ({ handleInput, formHandle, records, errors }) => {
  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col-md-12 mb-2">
          <label htmlFor="name" className="form-label">
            Nama Surat
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="name"
            name="name"
            value={records.name}
            onChange={(e) => handleInput(e.target)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12 mb-2">
          <label htmlFor="number" className="form-label">
            Nomor Urut
          </label>
          <input
            className={`form-control mb-1 ${errors.number ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nomor Urut"
            id="number"
            name="number"
            value={records?.number}
            onChange={(e) => handleInput(e.target)}
          />
          {errors.number ? (
            <div className="invalid-feedback">{errors.number[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Link to="/surats">
            <button className="btn btn-warning">BACK</button>
          </Link>

          <button type="submit" className="btn btn-primary float-end">
            SUBMIT
          </button>
        </div>
      </div>
    </form>
  );
};

export default SuratFormComponent;
