import React from "react";
import LessonGroupFormComponent from "../components/LessonGroupFormComponent";

const LessonGroupFormContainer = () => {
  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Kelompok Pelajaran Form</h2>
      </div>
      <div className="card-body">
        <LessonGroupFormComponent />
      </div>
    </div>
  );
};

export default LessonGroupFormContainer;
