import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { reportlist } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const LedgerClassroomListComponent = () => {
  const [reports] = useRecoilState(reportlist);
  const [errors, setErrors] = useState("");

  const recapExport = async (
    classroom_id,
    academic_id,
    period_id,
    classname,
    academicname
  ) => {
    try {
      const params = classroom_id + "/" + academic_id + "/" + period_id;
      let response = await axios.get("classroom/recap/" + params + "/export", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename =
          "Ledger Kelas " + classname + "-" + academicname + "-recaps";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  return (
    <div>
      <div className="card-header">
        <h2>Ledger Nilai Kelas</h2>
      </div>
      {reports ? (
        <div className="card-body">
          <div className="col table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">Nama Kelas</th>
                  <th className="text-center">Ledger Nilai</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report, index) => (
                  <tr key={index}>
                    <td scope="row">{index + 1}</td>
                    <td>
                      <span className="badge bg-success fs-6">
                        {report.classroom.name}
                      </span>
                    </td>
                    <td className="text-center">
                      {report.academic.periods.map((period, index) => (
                        <Fragment key={index}>
                          {report.classroom.group_name === period.group_name ? (
                            <button
                              key={index}
                              className="btn btn-warning mx-2 mb-3 fw-bold"
                              onClick={() =>
                                recapExport(
                                  report.classroom.id,
                                  report.academic_id,
                                  period.id,
                                  report.classroom.name,
                                  report.academic.name
                                )
                              }
                            >
                              Ledger Nilai Semester {period.name}
                            </button>
                          ) : (
                            ""
                          )}
                        </Fragment>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default LedgerClassroomListComponent;
