import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import TeacherDetailComponent from "../components/TeacherDetailComponent";
import { authenticated, teacherdetail } from "../store";

const TeacherDetailContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [teacher, setTeacher] = useRecoilState(teacherdetail);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/teachers/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setTeacher(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Detail Guru</h2>
      </div>
      <div className="card-body">
        {teacher ? <TeacherDetailComponent /> : <SmallLoadingComponent />}
      </div>
    </div>
  );
};

export default TeacherDetailContainer;
