import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import CategorieListComponent from "../components/CategorieListComponent";
import { authenticated, categoriedetail } from "../store";

const CategorieListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [categorie, setCategorie] = useRecoilState(categoriedetail);
  const navigate = useNavigate();

  useEffect(() => {
    setCategorie(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    }
  }, [auth.login]);

  return (
    <div className="card">
      <CategorieListComponent />
    </div>
  );
};

export default CategorieListContainer;
