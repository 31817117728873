import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import Swal from "sweetalert2";
import SmallLoadingComponent from "../components/SmallLoadingComponent";

const IsmubaReportClassroomDetailComponent = ({
  report,
  students,
  upadateErrors,
}) => {
  const [scroll, setScroll] = useState(false);
  const [colheight, setColheight] = useState(54);
  const redirect = useNavigate();
  const { type } = useParams();

  const newTabReport = async (student, classroom, period) => {
    try {
      // /regulers/report/student/2/classroom/2/academic/1/period/1
      const params =
        "student/" +
        student.id +
        "/classroom/" +
        report?.classroom.id +
        "/academic/" +
        report?.academic.id +
        "/period/" +
        period;

      let response = await axios.get(`${type}/report/${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });

        const fileUrl = URL.createObjectURL(blob);
        const w = window.open(fileUrl, "_blank");
        w && w.focus();

        // IE
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(
        //     response.data,
        //     `${+new Date()}.pdf`
        //   );
        //   return;
        // }
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Siswa belum memiliki nilai",
        showConfirmButton: true,
      });
      // upadateErrors(e.response.data);
    }
  };

  const trHeightAppend = () => {
    const tbody = document.getElementsByTagName("tbody")[0];
    if (tbody.childNodes.length > 0) {
      const trHeight = tbody.childNodes[0].cells[0].clientHeight;
      setColheight(trHeight);
    }
  };

  const tableScrollLeft = () => {
    trHeightAppend();
    const scrollValue =
      document.getElementsByClassName("table-responsive")[0].scrollLeft;

    if (scrollValue > 200) {
      setScroll(true);
    } else if (scrollValue < 200) {
      setScroll(false);
    }
  };

  return (
    <div>
      {report ? (
        <div className="card-body">
          <div className="row">
            <div
              className="col table-responsive"
              onTouchMove={() => tableScrollLeft()}
            >
              <table className="table table-bordered table-striped">
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>KELAS</th>
                    <th>NIS</th>
                    <th>NAMA</th>
                    <th>Rapot</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, index) => (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{report.classroom.name}</td>
                      <td className="text-center">{student.nis}</td>
                      <td
                        className={scroll ? "absolute-col" : null}
                        style={{ height: colheight + "px" }}
                      >
                        <div className="fix-width">{student.name}</div>
                      </td>
                      <td key={index} className="text-center">
                        {report.academic.periods.map((row, index) => (
                          <Fragment key={index}>
                            {report.classroom.group_name === row.group_name ? (
                              <div className="row">
                                <div className="col mb-3">
                                  <button
                                    className="btn btn-dark text-capitalize"
                                    onClick={() =>
                                      newTabReport(
                                        student,
                                        report.classroom.name,
                                        row.id
                                      )
                                    }
                                  >
                                    Open Rapot Semester {row.name} {type}
                                  </button>
                                </div>

                                {/* <div className="col">
                                  <button
                                    className="btn btn-success text-capitalize"
                                    onClick={() =>
                                      newTabPas(
                                        student,
                                        report.classroom.name,
                                        row.id
                                      )
                                    }
                                  >
                                    Open Rapot Semester {row.name} {type}
                                  </button>
                                </div> */}
                              </div>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                onClick={() => redirect(-1)}
                className="btn btn-warning float-end"
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default IsmubaReportClassroomDetailComponent;
