import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const IsmubaPeriodButton = ({ classroom_id, period, type }) => {
  const regulerBtn = () => {
    return (
      <Link
        className="btn btn-primary mx-2"
        to={`/qurans/classroom/${classroom_id}/period/${period.id}/surat`}
      >
        Semester {period.name}
      </Link>
    );
  };

  const TahfidzBtn = () => {
    return (
      <Link
        className="btn btn-primary mx-2"
        to={`/qurans/classroom/${classroom_id}/period/${period.id}/${type}`}
      >
        Semester {period.name}
      </Link>
    );
  };

  return (
    <Fragment>{type === "reguler" ? regulerBtn() : TahfidzBtn()}</Fragment>
  );
};

export default IsmubaPeriodButton;
