import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, classroomshow, studentlist } from "../store";

const StundentToClassImportFormComponent = (props) => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [students, setStudents] = useRecoilState(studentlist);
  const [classroomdetail] = useRecoilState(classroomshow);
  const [errors, setErrors] = useState(false);
  const [selected_file, setSelected_file] = useState(false);
  const { id } = useParams();
  const params = id + "/" + classroomdetail.classroom.academic_id;

  const handleOnchange = (event) => {
    setSelected_file(event.target.files[0]);
  };

  const onFileUpload = async () => {
    try {
      let formData = new FormData();
      formData.append("file_import", selected_file, selected_file.name);

      let response = await axios.post(
        "students/classroom/" + params + "/import",
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });

        props.updateData();
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  return (
    <div className="float-end">
      <label htmlFor="taks-file" className="form-label">
        Import Siswa
      </label>
      <input
        className="form-control mb-3"
        type="file"
        id="taks-file"
        onChange={(event) => handleOnchange(event)}
      />
      {selected_file ? (
        <button
          className="btn btn-primary float-end"
          onClick={() => onFileUpload()}
        >
          Upload
        </button>
      ) : null}
    </div>
  );
};

export default StundentToClassImportFormComponent;
