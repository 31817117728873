import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated, homeroomscorelist, studentlist } from "../store";
import NoteClassroomListComponent from "../components/NoteClassroomListComponent";

const NoteClassroomListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [homeroomscores, setHomeroomscores] = useRecoilState(homeroomscorelist);
  const [students, setStudents] = useRecoilState(studentlist);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/reports", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setHomeroomscores(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    setStudents(false);
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      {homeroomscores ? (
        <NoteClassroomListComponent />
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default NoteClassroomListContainer;
