import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilValue } from "recoil";
import AddHomeroomTeacherFormComponent from "../components/AddHomeroomTeacherFormComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated } from "../store";

const AddHomeroomTeacherFormContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [classroomdetail, setClassroomdetail] = useState(false);
  const [errors, setErrors] = useState("");
  const { id } = useParams();
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/homerooms/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassroomdetail(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
    let mounted = true;
    if (mounted) {
      getData();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [auth.login]);

  return (
    <div className="card">
      {classroomdetail ? (
        <Fragment>
          <div className="card-header">
            <h2>
              Add Wali Kelas{" "}
              <span className="badge bg-success fs-4">
                {classroomdetail.classroom.name}
              </span>{" "}
              <span className="badge bg-success fs-4 text-wrap">
                {classroomdetail.classroom.academic.name}
              </span>
            </h2>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <AddHomeroomTeacherFormComponent
                  classroomdetail={classroomdetail}
                />
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AddHomeroomTeacherFormContainer;
