import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authenticated, waiting } from "../store";

const LoginFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const setLoading = useSetRecoilState(waiting);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const record = { email, password };

  const submitHanlder = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let response = await axios.post("/login", record);

      if (response.status === 200) {
        setLoading(false);
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("lastpath", "/dashboards");
        setAuth({ login: true, user: response.data });
      }
      // console.log(response.data);
    } catch (e) {
      if (e.response) {
        setLoading(false);
        setErrors(e.response.data);
      }

      //   console.log(e.response.data);
    }
  };

  const getMe = async () => {
    try {
      setLoading(true);
      let response = await axios.get("/me", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setLoading(false);
        setAuth({
          login: true,
          user: response.data,
        });
      }

      // console.log(response.data);
    } catch (e) {
      setLoading(false);
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      sessionStorage.removeItem("lastpath");
      return false;
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login) {
      redirect(sessionStorage.getItem("lastpath"));
    } else if (sessionStorage.getItem("token")) {
      getMe();
    }
  }, [auth.login]);

  return (
    <div>
      <div className="row py-4">
        <div className="col-12 text-center mb-3">
          <img src="./tif-logo.png" alt="Tif Logo" />
        </div>
        <div className="col-12 text-center mb-3">
          <h2 className="fw-bold text-success">TIF RAPOT LOGIN</h2>
        </div>
        <form onSubmit={submitHanlder}>
          <div className="row row-cols-1 mb-3">
            <div className="col mb-4">
              <label className="text-secondary" htmlFor="email">
                E-Mail
              </label>
              <input
                className={`form-control mt-1 mb-1 rounded login-form ${
                  errors.email ? "is-invalid" : ""
                }`}
                id="e-mail"
                name="email"
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email ? (
                <div className="invalid-feedback">{errors.email[0]}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col">
              <label className="text-secondary" htmlFor="password">
                Password
              </label>
              <input
                className={`form-control mt-1 mb-1 rounded login-form ${
                  errors.password ? "is-invalid" : ""
                }`}
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password ? (
                <div className="text-danger">{errors.password[0]}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col mt-5">
              <button
                type="submit"
                className="btn bg-green-light rounded col-12 fw-bold text-white"
              >
                LOGIN
              </button>
            </div>
            <div className="col mt-5">
              <Link className="float-end" to={"/forgot"}>
                Lupa Password ?
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginFormComponent;
