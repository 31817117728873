import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const AchievementDetailComponent = (props) => {
  const [auth] = useRecoilState(authenticated);
  const [errors, setErrors] = useState([]);
  const { student_id, classroom_id, academic_id } = useParams();
  const params = student_id + "/" + classroom_id + "/" + academic_id;
  const redirect = useNavigate();

  const delData = async (id) => {
    try {
      let response = await axios.delete("/achievements/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });

        props.updateData();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  return (
    <div>
      {props.achievements ? (
        <div className="card-body">
          <div className="row mb-5">
            <div className="col-md-5">
              <table className="table">
                <thead>
                  <tr>
                    <th>Nama Siswa</th>
                    <th>:</th>
                    <th>{props.achievements?.student?.name}</th>
                  </tr>
                  <tr>
                    <th>NIS</th>
                    <th>:</th>
                    <th>{props.achievements?.student?.nis}</th>
                  </tr>
                  <tr>
                    <th>Nama Wali Kelas</th>
                    <th>:</th>
                    <th>{auth?.user.name}</th>
                  </tr>
                  <tr>
                    <th>Kelas</th>
                    <th>:</th>
                    <th>{props.achievements?.classroom?.name}</th>
                  </tr>
                  <tr>
                    <th>Tahun Ajaran</th>
                    <th>:</th>
                    <th>{props.achievements?.academic?.nickname}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              {props.achievements?.academic?.periods.map((row, index) => (
                <Fragment key={index}>
                  {props.achievements?.classroom.group_name ===
                  row.group_name ? (
                    <Link
                      key={index}
                      to={"/achievements/" + params + "/" + row.id + "/create"}
                    >
                      <button className="btn btn-success m-2">
                        Tambah Prestasi Semester {row.name}
                      </button>
                    </Link>
                  ) : (
                    ""
                  )}
                </Fragment>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">NAMA</th>
                    <th className="text-center">SEMESTER</th>
                    <th className="text-center">KETERANGAN</th>
                    <th className="text-center" colSpan={2}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.achievements
                    ? props.achievements.student.achievements.map(
                        (row, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td>{row.name}</td>
                            <td className="text-center">
                              <span className="badge bg-success fs-6">
                                {row.period.name}
                              </span>
                            </td>
                            <td>{row.description}</td>
                            <td className="text-center">
                              <Link to={"/achievements/" + row.id + "/edit"}>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  EDIT
                                </button>
                              </Link>
                            </td>
                            <td className="text-center">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => delData(row.id)}
                              >
                                DELETE
                              </button>
                            </td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                onClick={() => redirect(-1)}
                className="btn btn-warning float-end"
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AchievementDetailComponent;
