import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated } from "../store";

const NoticeEditFormComponent = () => {
  const setAuth = useSetRecoilState(authenticated);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState([]);
  const records = { name, description };
  const redirect = useNavigate();
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/notices/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setName(response.data.name);
      setDescription(response.data.description);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const formHandle = async () => {
    try {
      let response = await axios.put("/notices/" + id, records, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setErrors([]);
      }

      // console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="row mb-4">
      <div className="col-md-12 mb-4">
        <label htmlFor="name" className="form-label">
          Judul Pengumuman
        </label>
        <input
          className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
          type="text"
          rows={5}
          placeholder="Judul Pengumuman"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name ? (
          <div className="invalid-feedback">{errors.name[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-12 mb-4">
        <label htmlFor="description" className="form-label">
          Deskripsi
        </label>
        <textarea
          className={`form-control mb-1 ${
            errors.description ? "is-invalid" : ""
          }`}
          type="text"
          rows={5}
          placeholder="Deskripsi"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        {errors.description ? (
          <div className="invalid-feedback">{errors.description[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-12">
        <button
          type="submit"
          className="btn btn-primary float-end"
          onClick={() => formHandle()}
        >
          UPDATE
        </button>

        <button
          className="btn btn-warning mx-4 float-end"
          onClick={() => redirect(-1)}
        >
          BACK
        </button>
      </div>
    </div>
  );
};

export default NoticeEditFormComponent;
