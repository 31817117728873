import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { waiting } from "../store";
import { useSetRecoilState } from "recoil";
import { Link, useNavigate, useParams } from "react-router-dom";

const ResetPasswordComponent = () => {
  const setLoading = useSetRecoilState(waiting);
  const [password, setPassword] = useState("");
  const { token } = useParams();
  const record = { token, password };
  const navigate = useNavigate();

  const updatePassword = async () => {
    try {
      setLoading(true);
      let response = await axios.post("/password/reset", record);

      if (response.status === 200) {
        setLoading(false);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      }

      // console.log(response.data);
    } catch (e) {
      setLoading(false);

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        showConfirmButton: true,
      });

      //   setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <label className="mb-2" htmlFor="password">
          New Password
        </label>
        <input
          type="password"
          className="form-control"
          placeholder="New Pasword"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="col-12 mt-4 ">
        <Link to={"/forgot"}>
          <button className="btn btn-warning float-start">LUPA PASSWORD</button>
        </Link>
        <button
          className="btn btn-success float-end"
          onClick={() => updatePassword()}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordComponent;
