import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { reportlist } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const ReportClassroomListComponent = () => {
  const [reports, setReports] = useRecoilState(reportlist);
  const [errors, setErrors] = useState("");

  const getData = async () => {
    try {
      let response = await axios.get("/reports", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setReports(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const recapExport = async (
    classroom_id,
    academic_id,
    period_id,
    classname,
    academicname
  ) => {
    try {
      const params = classroom_id + "/" + academic_id + "/" + period_id;
      let response = await axios.get("classroom/recap/" + params + "/export", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename =
          "Ledger Kelas " + classname + "-" + academicname + "-recaps";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="card-header">
        <h2>Laporan Nilai Kelas</h2>
      </div>
      {reports ? (
        <div className="card-body">
          <div className="col">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">Nama Kelas</th>
                  <th className="text-center">Ledger Nilai</th>
                  <th className="text-center">Sikap</th>
                  <th className="text-center">Detail</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report, index) => (
                  <tr key={index}>
                    <td scope="row">{index + 1}</td>
                    <td>{report.classroom.name}</td>
                    <td className="text-center">
                      {report.academic.periods.map((period, index) => (
                        <button
                          key={index}
                          className="btn btn-warning mx-2 mb-3"
                          onClick={() =>
                            recapExport(
                              report.classroom.id,
                              report.academic_id,
                              period.id,
                              report.classroom.name,
                              report.academic.name
                            )
                          }
                        >
                          Ledger Nilai {period.name}
                        </button>
                      ))}
                    </td>
                    <td className="text-center">
                      <Link
                        to={
                          "/notes/attitude/" +
                          report.classroom.id +
                          "/" +
                          report.academic_id
                        }
                      >
                        <button className="btn btn-success">Sikap</button>
                      </Link>
                    </td>
                    <td className="text-center">
                      <Link
                        to={
                          "/reports/" +
                          report.classroom.id +
                          "/" +
                          report.academic_id +
                          "/detail"
                        }
                      >
                        <button className="btn btn-primary">
                          Detail Kelas
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default ReportClassroomListComponent;
