import React from "react";

const StudentArchiveListComponent = () => {
  return (
    <div className="card">
      <div className="card-header">
        <h2>List Siswa</h2>
      </div>
      <div className="card-body">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">NISN</th>
              <th className="text-center">NIS</th>
              <th className="text-center">NAMA</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default StudentArchiveListComponent;
