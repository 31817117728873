import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, waiting } from "../../store";
import SuratEditFormComponent from "./SuratEditFormComponent";

const SuratEditFormContainer = () => {
  const auth = useRecoilValue(authenticated);
  const setLoading = useSetRecoilState(waiting);
  const [errors, setErrors] = useState([]);
  const [records, setRecords] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const findData = async () => {
    try {
      setLoading(true);
      let response = await axios.get("/surats/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setRecords(response.data);
      setLoading(false);
      //   console.log(response.data.name);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
      // console.log(e.response.data);
    }
  };

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let response = await axios.put("/surats/" + id, records, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      }

      // console.log(response.status);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: e.response.data.message,
        showConfirmButton: true,
      });
      setErrors(e.response.data);
      setLoading(false);
    }
  };
  const handleInput = (input) => {
    const change = { ...records };
    change[input.name] = input.value;
    setRecords(change);
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    findData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Surat</h2>
      </div>
      <div className="card-body">
        <SuratEditFormComponent
          handleInput={(input) => handleInput(input)}
          formHandle={(e) => formHandle(e)}
          records={records}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default SuratEditFormContainer;
