import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authenticated, disablemenu } from "../store";

const NavbarTeacherComponent = ({ outAction, hideNav }) => {
  const auth = useRecoilValue(authenticated);
  const disable = useRecoilValue(disablemenu);
  return (
    <ul className="side-nav">
      <li className="nav-item mb-4">
        <div className="row muga-mobile-center justify-content-sm-center justify-content-md-center">
          <div className="muga-mobile col-sm-8 col-md-8 pt-3">
            <img
              src="/muga-logo-200.png"
              alt="SMA Muhammadiyah 3 Jakarta Logo"
              className="img-fluid"
            />
            {/* <img
              src="/sample-logo.png"
              alt="Sample Logo"
              className="img-fluid py-3"
            /> */}
          </div>
        </div>
      </li>

      {disable ? (
        <Accordion flush={true}>
          <Accordion.Item>
            <Accordion.Header>Dashboard</Accordion.Header>
            <Accordion.Header>Buku Nilai</Accordion.Header>
            <Accordion.Header>Arsip</Accordion.Header>
          </Accordion.Item>
        </Accordion>
      ) : (
        <Accordion flush={true}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Dashboard</Accordion.Header>
            <Accordion.Body className="bg-secondary p-0">
              <li className="nav-item" onClick={() => hideNav()}>
                <Link to="/dashboards" className="nav-link">
                  <i className="bi bi-activity"></i> Dashboard
                </Link>
              </li>
              <li className="nav-item" onClick={() => hideNav()}>
                <Link to="/classrooms/teacher" className="nav-link">
                  <i className="bi bi-stack"></i> Kelas
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Buku Nilai</Accordion.Header>
            <Accordion.Body className="bg-secondary p-0">
              <li className="nav-item" onClick={() => hideNav()}>
                <Link to="/competences" className="nav-link">
                  <i className="bi bi-book-half"></i> Buku Nilai Akademis
                </Link>
              </li>
              {auth.user.kemuh ? (
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to="/classrooms/kemuh" className="nav-link">
                    <i className="bi bi-stickies-fill"></i> Kemuhammadiyahan
                  </Link>
                </li>
              ) : (
                ""
              )}
              {auth.user.quran ? (
                <Fragment>
                  <li className="nav-item" onClick={() => hideNav()}>
                    <Link to="/classrooms/ismuba/reguler" className="nav-link">
                      <i className="bi bi-stack"></i> Qur'an Nilai Reguler
                    </Link>
                  </li>
                  <li className="nav-item" onClick={() => hideNav()}>
                    <Link to="/classrooms/ismuba/tahfidz" className="nav-link">
                      <i className="bi bi-stack"></i> Qur'an Nilai Tahfidz
                    </Link>
                  </li>
                </Fragment>
              ) : (
                ""
              )}
            </Accordion.Body>
          </Accordion.Item>
          {auth.user.homeroom ? (
            <Accordion.Item eventKey="2">
              <Accordion.Header>Wali Kelas</Accordion.Header>
              <Accordion.Body className="bg-secondary p-0">
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/attendances"} className="nav-link">
                    <i className="bi bi-pencil-square"></i> Input Absen
                  </Link>
                </li>
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/homerooms/spiritual"} className="nav-link">
                    <i className="bi bi-pencil-square"></i> Input Nilai Spritual
                  </Link>
                </li>
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/homerooms/social"} className="nav-link">
                    <i className="bi bi-pencil-square"></i> Input Nilai Sosial
                  </Link>
                </li>
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/homerooms/note"} className="nav-link">
                    <i className="bi bi-pencil-square"></i> Input Catatan Siswa
                  </Link>
                </li>
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/homerooms/extracurricular"} className="nav-link">
                    <i className="bi bi-pencil-square"></i> Input Extrakurikuler
                    Siswa
                  </Link>
                </li>
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/homerooms/achievement"} className="nav-link">
                    <i className="bi bi-pencil-square"></i> Input Prestasi Siswa
                  </Link>
                </li>
              </Accordion.Body>
            </Accordion.Item>
          ) : (
            ""
          )}
          {auth.user.homeroom ? (
            <Accordion.Item eventKey="3">
              <Accordion.Header>Rapot</Accordion.Header>
              <Accordion.Body className="bg-secondary p-0">
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/homerooms/status/score"} className="nav-link">
                    <i className="bi bi-list-check"></i> Status Nilai
                  </Link>
                </li>
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/homerooms/ledger"} className="nav-link">
                    <i className="bi bi-file-ruled-fill"></i> Ledger Akademis
                  </Link>
                </li>
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/homerooms/report"} className="nav-link">
                    <i className="bi bi-journal-bookmark-fill"></i> Rapot
                    Akademis
                  </Link>
                </li>

                <li className="nav-item" onClick={() => hideNav()}>
                  <Link
                    to={"/homerooms/ismuba/report/reguler"}
                    className="nav-link"
                  >
                    <i className="bi bi-journal-bookmark-fill"></i> Rapot Ismuba
                    Reguler
                  </Link>
                </li>
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link
                    to={"/homerooms/ismuba/report/tahfidz"}
                    className="nav-link"
                  >
                    <i className="bi bi-journal-bookmark-fill"></i> Rapot Ismuba
                    Tahfidz
                  </Link>
                </li>
                <li className="nav-item" onClick={() => hideNav()}>
                  <Link to={"/ledgers/reguler"} className="nav-link">
                    <i className="bi bi-file-ruled-fill"></i> Ledger Ismuba
                  </Link>
                </li>
              </Accordion.Body>
            </Accordion.Item>
          ) : (
            ""
          )}
          <Accordion.Item eventKey="4">
            <Accordion.Header>Arsip</Accordion.Header>
            <Accordion.Body className="bg-secondary p-0">
              <li className="nav-item">
                <Link
                  to="/archives"
                  onClick={() => hideNav()}
                  className="nav-link"
                >
                  <i className="bi bi-person-lines-fill"></i> Arsip
                </Link>
              </li>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      <li className="nav-item logout-nav" onClick={() => outAction()}>
        <span className="badge bg-danger ms-2 mt-5 pointer">
          <i className="bi bi-arrow-left-circle-fill"></i> Logout
        </span>
      </li>
    </ul>
  );
};

export default NavbarTeacherComponent;
