import React from "react";

const NoticeDetailComponent = (props) => {
  return (
    <div className="card">
      <div className="card-header">
        <h2>{props.notice.name}</h2>
      </div>
      <div className="card-body">
        <textarea className="form-control bg-white" disabled={true} rows={16}>
          {props.notice.description}
        </textarea>
      </div>
    </div>
  );
};

export default NoticeDetailComponent;
