import React from "react";
import { Link, useParams } from "react-router-dom";

const IsmubaReportClassroomComponent = ({ reports }) => {
  const { type } = useParams();
  return (
    <div className="col">
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">KELAS</th>
              <th className="text-center">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, index) => (
              <tr className="text-center" key={index}>
                <td scope="row">{index + 1}</td>
                <td>
                  <span className="badge bg-success fs-6">{report.name}</span>
                </td>
                <td>
                  <Link
                    to={
                      "/homerooms/ismuba/classroom/" +
                      report.id +
                      "/academic/" +
                      report.academic_id +
                      "/" +
                      type
                    }
                    className="btn btn-primary fw-bold text-uppercase"
                  >
                    CREATE RAPOT {type}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IsmubaReportClassroomComponent;
