import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated } from "../store";
import ExtracurricularClassroomListComponent from "../components/ExtracurricularClassroomListComponent";

const ExtracurricularClassroomListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [classrooms, setClassrooms] = useState([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/reports", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassrooms(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      {classrooms.length > 0 ? (
        <ExtracurricularClassroomListComponent classrooms={classrooms} />
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default ExtracurricularClassroomListContainer;
