import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated } from "../store";

const UserFormComponent = () => {
  const [nip_nis, setNip_nis] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [auth, setAuth] = useRecoilState(authenticated);
  const [errors, setErrors] = useState("");
  const redirect = useNavigate();

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post(
        "/classrooms",
        { name: name },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setName("");
      }

      console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col">
          <label htmlFor="nama" className="form-label">
            Nama
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col">
          <label htmlFor="nama" className="form-label">
            Nama
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <button type="submit" className="btn btn-primary float-end">
        SUBMIT
      </button>

      <Link to="/classrooms">
        <button className="btn btn-warning mx-4 float-end">BACK</button>
      </Link>
    </form>
  );
};

export default UserFormComponent;
