import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AlertComponent from "../components/AlertComponent";
import TeacherFormComponent from "../components/TeacherFormComponent";
import TeacherImportFormComponent from "../components/TeacherImportFormComponent";
import { authenticated } from "../store";
import DuplicateTeacher from "../teachers/DuplicateTeacher";
import { templateExport } from "../utils/GetFileFunct";

const TeacherFormContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const downloadFormat = async () => {
    await templateExport(
      "teachers/import/template",
      "",
      "template-import-guru"
    );
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Guru</h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <AlertComponent color={"danger"} message={errors.message} />
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col">
            <DuplicateTeacher duplicates={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row row-cols-2">
              <div className="col">
                <button
                  className="btn btn-warning"
                  onClick={() => downloadFormat()}
                >
                  Contoh Import Excel
                </button>
              </div>
              <div className="col">
                <TeacherImportFormComponent
                  setErrors={(e) => {
                    setErrors(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TeacherFormComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherFormContainer;
