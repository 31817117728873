import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import LoadingComponent from "../components/LoadingComponent";
import {
  adminattitudelist,
  authenticated,
  competencedetail,
  scorelist,
  studentlist,
} from "../store";
import CompetenceDetailComponent from "../components/CompetenceDetailComponent";
import CompetenceDetailHeaderComponent from "../components/CompetenceDetailHeaderComponent";
import CompetenceDetailImportComponent from "../components/CompetenceDetailImportComponent";
import CompetenceDetailExportComponent from "../components/CompetenceDetailExportComponent";
import AttitudeScoreComponent from "../components/AttitudeScoreComponent";

const AttitudeScoreContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [scores, setScores] = useRecoilState(scorelist);
  const [students, setStudents] = useRecoilState(studentlist);
  const [competence, setCompetence] = useRecoilState(competencedetail);
  const [adminattitudes, setAdminattitudes] = useRecoilState(adminattitudelist);
  const [errors, setErrors] = useState("");
  const { classroom_id, academic_id } = useParams();
  const params = classroom_id + "/" + academic_id;
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/attitudes/" + params + "/detail", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setScores(response.data);
        setStudents(response.data.students);
        setAdminattitudes(response.data.attitudes);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }

    setCompetence(false);
    getData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Nilai Sikap Kelas {scores?.classroom?.name}</h2>
      </div>
      {scores ? (
        <div className="card-body">
          <div className="row row-cols-2 mb-4">
            <div className="col">
              {/* <CompetenceDetailHeaderComponent /> */}
            </div>
            <div className="col">
              <div className="row row-cols-1">
                <div className="col mb-3">
                  {/* <CompetenceDetailExportComponent /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <AttitudeScoreComponent />
          </div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default AttitudeScoreContainer;
