import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, classroomshow } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const AttendanceFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [classroomdetail] = useRecoilState(classroomshow);
  const [errors, setErrors] = useState("");
  const [attendances, setAttendances] = useState(false);
  const [scroll, setScroll] = useState(false);
  const redirect = useNavigate();

  const attendanceFormat = () => {
    if (classroomdetail) {
      const format = classroomdetail.students.map((row) => ({
        ...row,
        attendances: {
          hadir: "",
          sakit: "",
          ijin: "",
          alpa: "",
          tanpa_keterangan: "",
          description: "",
        },
      }));

      setAttendances(format);
    }
  };

  const handleHadir = (value, index) => {
    const oldobject = [...attendances];
    const updatevalue = oldobject[index];
    updatevalue["attendances"] = {
      hadir: value,
      sakit: updatevalue.attendances.sakit,
      ijin: updatevalue.attendances.ijin,
      alpa: updatevalue.attendances.alpa,
      tanpa_keterangan: updatevalue.attendances.tanpa_keterangan,
      description: updatevalue.attendances.description,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];

    setAttendances(replacevalue);
  };

  const handleSakit = (value, index) => {
    const oldobject = [...attendances];
    const updatevalue = oldobject[index];
    updatevalue["attendances"] = {
      hadir: updatevalue.attendances.hadir,
      sakit: value,
      ijin: updatevalue.attendances.ijin,
      alpa: updatevalue.attendances.alpa,
      tanpa_keterangan: updatevalue.attendances.tanpa_keterangan,
      description: updatevalue.attendances.description,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];

    setAttendances(replacevalue);
  };

  const handleIjin = (value, index) => {
    const oldobject = [...attendances];
    const updatevalue = oldobject[index];
    updatevalue["attendances"] = {
      hadir: updatevalue.attendances.hadir,
      sakit: updatevalue.attendances.sakit,
      ijin: value,
      alpa: updatevalue.attendances.alpa,
      tanpa_keterangan: updatevalue.attendances.tanpa_keterangan,
      description: updatevalue.attendances.description,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];

    setAttendances(replacevalue);
  };

  const handleAlpa = (value, index) => {
    const oldobject = [...attendances];
    const updatevalue = oldobject[index];
    updatevalue["attendances"] = {
      hadir: updatevalue.attendances.hadir,
      sakit: updatevalue.attendances.sakit,
      ijin: updatevalue.attendances.ijin,
      alpa: value,
      tanpa_keterangan: updatevalue.attendances.tanpa_keterangan,
      description: updatevalue.attendances.description,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];

    setAttendances(replacevalue);
  };

  const handleTanpa = (value, index) => {
    const oldobject = [...attendances];
    const updatevalue = oldobject[index];
    updatevalue["attendances"] = {
      hadir: updatevalue.attendances.hadir,
      sakit: updatevalue.attendances.sakit,
      ijin: updatevalue.attendances.ijin,
      alpa: updatevalue.attendances.alpa,
      tanpa_keterangan: value,
      description: updatevalue.attendances.description,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];

    setAttendances(replacevalue);
  };

  const handleDescription = (value, index) => {
    const oldobject = [...attendances];
    const updatevalue = oldobject[index];
    updatevalue["attendances"] = {
      hadir: updatevalue.attendances.hadir,
      sakit: updatevalue.attendances.sakit,
      ijin: updatevalue.attendances.ijin,
      alpa: updatevalue.attendances.alpa,
      tanpa_keterangan: updatevalue.attendances.tanpa_keterangan,
      description: value,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];
    setAttendances(replacevalue);
  };

  const handleSubmit = async () => {
    const record = {
      classroom_id: classroomdetail.classroom.id,
      academic_id: classroomdetail.academic.id,
      period_id: classroomdetail.academic.period.id,
      group_students: attendances,
    };

    try {
      let response = await axios.post("/attendances", record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  const tableScrollLeft = () => {
    const scrollValue =
      document.getElementsByClassName("table-responsive")[0].scrollLeft;

    if (scrollValue > 200) {
      setScroll(true);
    } else if (scrollValue < 200) {
      setScroll(false);
    }
  };

  useEffect(() => {
    attendanceFormat();
  }, []);

  return (
    <div className="row table-responsive">
      {attendances ? (
        <>
          <div className="row mb-4">
            <div className="col-md-6">
              <table className="table">
                <thead>
                  <tr>
                    <td>Kelas</td>
                    <td>:</td>
                    <td>{classroomdetail.classroom.name}</td>
                  </tr>
                  <tr>
                    <td>Wali Kelas</td>
                    <td>:</td>
                    <td>{auth.user.name}</td>
                  </tr>
                  <tr>
                    <td>Tahun AJaran</td>
                    <td>:</td>
                    <td>{classroomdetail.academic.name}</td>
                  </tr>
                  <tr>
                    <td>Semester</td>
                    <td>:</td>
                    <td>{classroomdetail.academic.period.name}</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th
                      className="align-middle text-center"
                      rowSpan={2}
                      scope="col"
                    >
                      #
                    </th>
                    <th
                      className="align-middle text-center col-md-2"
                      rowSpan={2}
                      scope="col"
                    >
                      Nama
                    </th>
                    <th
                      className="align-middle text-center"
                      rowSpan={2}
                      scope="col"
                    >
                      NIS
                    </th>
                    <th
                      className="align-middle text-center"
                      colSpan={5}
                      scope="col"
                    >
                      Status
                    </th>
                    <th
                      className="align-middle text-center"
                      rowSpan={2}
                      scope="col"
                    >
                      Keterangan
                    </th>
                  </tr>
                  <tr>
                    <td className="text-center bg-success text-light fw-bold">
                      H
                    </td>
                    <td className="text-center bg-info text-light fw-bold">
                      S
                    </td>
                    <td className="text-center bg-warning text-light fw-bold">
                      I
                    </td>
                    <td className="text-center bg-danger text-light fw-bold">
                      A
                    </td>
                    <td className="text-center bg-dark text-light fw-bold">
                      T
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {attendances.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className={scroll ? "absolute-col" : null}>
                        {row.name}
                      </td>
                      <td>{row.nis}</td>
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          name="hadir"
                          onChange={(e) => handleHadir(e.target.value, index)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          name="sakit"
                          onChange={(e) => handleSakit(e.target.value, index)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          name="ijin"
                          onChange={(e) => handleIjin(e.target.value, index)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          name="alpa"
                          onChange={(e) => handleAlpa(e.target.value, index)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          name="tanpa_keterangan"
                          onChange={(e) => handleTanpa(e.target.value, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="keterangan"
                          id="keterangan"
                          onChange={(e) =>
                            handleDescription(e.target.value, index)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-success mt-5 mb-5 float-end"
                  onClick={() => handleSubmit()}
                >
                  INSERT
                </button>
                <button
                  type="button"
                  className="btn btn-warning mt-5 mb-5 float-end me-5"
                  onClick={() => redirect(-1)}
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AttendanceFormComponent;
