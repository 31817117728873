import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import StudentArchiveComboComponent from "../components/StudentArchiveComboComponent";
import { archivelist, authenticated } from "../store";

const StudentArchiveContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [archives, setArchives] = useRecoilState(archivelist);
  const [errors, setErrors] = useState("");
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/archives/academic/null", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setArchives(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <div>
      <StudentArchiveComboComponent />
    </div>
  );
};

export default StudentArchiveContainer;
