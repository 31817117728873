import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import { studentdetail } from "../store";

const StudentDetailComponent = () => {
  const [student] = useRecoilState(studentdetail);
  const [erorrs, setErrors] = useState([]);
  const redirect = useNavigate();

  const browserDetect = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }

    return browserName;
  };

  const exportPts = async (
    student_id,
    classroom_id,
    academic_id,
    period_id,
    classroom
  ) => {
    try {
      const params =
        student_id + "/" + classroom_id + "/" + academic_id + "/" + period_id;

      let response = await axios.get("exports/" + params + "/pts", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename =
          student.person.name +
          "-" +
          student.person.nis +
          "-" +
          classroom +
          "-PTS";
        const blob = new Blob([response.data], { type: "application/pdf" });

        if (browserDetect() === "firefox") {
          const fileUrl = URL.createObjectURL(blob);
          const w = window.open(fileUrl, "_blank");
          w && w.focus();
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${filename}.pdf`;
          link.click();
        }
        // IE
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(
        //     response.data,
        //     `${+new Date()}.pdf`
        //   );
        //   return;
        // }
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const exportPas = async (
    student_id,
    classroom_id,
    academic_id,
    period_id,
    classroom
  ) => {
    try {
      const params =
        student_id + "/" + classroom_id + "/" + academic_id + "/" + period_id;

      let response = await axios.get("exports/" + params + "/pas", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename =
          student.person.name +
          "-" +
          student.person.nis +
          "-" +
          classroom +
          "-PAS";
        const blob = new Blob([response.data], { type: "application/pdf" });

        if (browserDetect() === "firefox") {
          const fileUrl = URL.createObjectURL(blob);
          const w = window.open(fileUrl, "_blank");
          w && w.focus();
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${filename}.pdf`;
          link.click();
        }
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  return (
    <div className="row row-cols-1">
      <div className="col mb-5">
        <button
          className="btn btn-warning float-end"
          onClick={() => redirect(-1)}
        >
          BACK
        </button>
      </div>
      <div className="col-md-6 mb-5">
        <table className="table">
          <thead>
            <tr>
              <th>Nama :</th>
              <th>{student.person.name}</th>
            </tr>
            <tr>
              <th>NISN :</th>
              <th>{student.person.nisn}</th>
            </tr>
            <tr>
              <th>NIS :</th>
              <th>{student.person.nis}</th>
            </tr>
            <tr>
              <th>Gender :</th>
              <th>{student.person.gender}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="col table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">KELAS</th>
              <th className="text-center">TAHUN AJARAN</th>
              <th className="text-center">RAPOT</th>
            </tr>
          </thead>
          <tbody>
            {student.classrooms.map((room, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <span className="badge bg-success fs-6">
                    {room.classroom.name}
                  </span>
                </td>
                <td>
                  <span className="badge bg-primary fs-6">
                    {room.academic.name}
                  </span>
                </td>
                <td className="text-center">
                  {room.academic.periods.map((period, index) => (
                    <Fragment key={index}>
                      {room.group_name === period.group_name ? (
                        <div className="row row-cols-2">
                          <div className="col mb-3">
                            <button
                              type="button"
                              className="btn btn-dark"
                              onClick={() =>
                                exportPts(
                                  student.person.id,
                                  room.classroom.id,
                                  room.academic.id,
                                  period.id,
                                  room.classroom.name
                                )
                              }
                            >
                              RAPOT PTS Semester {period.name}
                            </button>
                          </div>
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={() =>
                                exportPas(
                                  student.person.id,
                                  room.classroom.id,
                                  room.academic.id,
                                  period.id,
                                  room.classroom.name
                                )
                              }
                            >
                              RAPOT PAS Semester {period.name}
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col mt-5">
        <button
          className="btn btn-warning float-end"
          onClick={() => redirect(-1)}
        >
          BACK
        </button>
      </div>
    </div>
  );
};

export default StudentDetailComponent;
