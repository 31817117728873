import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { templateExport } from "../utils/GetFileFunct";

const SksImportFormComponent = ({ classroomdetail, updateData, setErrors }) => {
  const [progress, setProgress] = useState(false);
  const [selected_file, setSelected_file] = useState(false);
  const { id } = useParams();
  const params = `${id}/${classroomdetail.classroom.academic_id}`;

  const handleOnchange = (event) => {
    setSelected_file(event.target.files[0]);
  };

  const onFileUpload = async () => {
    try {
      let formData = new FormData();
      formData.append("file_import", selected_file, selected_file.name);

      let response = await axios.post("sks/import/" + params, formData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(percentCompleted);
          setProgress(percentCompleted);
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });

        updateData();
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const downloadFormat = async () => {
    await templateExport("sks/import/template", "", "template-import-sks");
  };

  return (
    <div className="row row-cols-2 mb-4">
      <div className="col">
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => downloadFormat()}
        >
          FORMAT IMPORT SKS
        </button>
      </div>
      <div className="col">
        <label htmlFor="taks-file" className="form-label">
          Import SKS
        </label>
        <input
          className="form-control mb-3"
          type="file"
          id="taks-file"
          onChange={(event) => handleOnchange(event)}
        />
        {selected_file ? (
          <button
            type="button"
            className="btn btn-success float-end"
            onClick={() => onFileUpload()}
          >
            Upload
          </button>
        ) : null}
      </div>
      <div className="col mt-3 mb-4">
        {progress ? (
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SksImportFormComponent;
