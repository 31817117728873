import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getData, postData } from "../../utils/ClassroomFunct";
import ImportSuratClassroom from "../surat/ImportSuratClassroom";
import { useRecoilValue } from "recoil";
import { authenticated, classroomprops } from "../../store";
import ImportComponent from "../../components/ImportComponent";

const FormNilaiSiswaHafalTambahan = ({ setErrors }) => {
  const auth = useRecoilValue(authenticated);
  const classroomheader = useRecoilValue(classroomprops);
  const [students, setStudents] = useState([]);
  const { classroom_id, period_id } = useParams();
  const redirect = useNavigate();

  const getStudents = async () => {
    const students = await getData(
      `regulers/quran/hafalan/create?classroom_id=${classroom_id}&period_id=${period_id}&academic_id=${classroomheader.academic.id}`
    );

    if (students.status === 200) {
      setStudents(students.data);
    } else {
      setErrors(students.data);
    }
  };

  const handleTambahan = (e, index) => {
    const student = { ...students[index] };
    student.nilaiquran[e.target.name] = e.target.value;
    let update = [
      ...students.slice(0, index),
      student,
      ...students.slice(index + 1),
    ];
    setStudents(update);
  };

  const submitNilai = async () => {
    const academic_id = classroomheader.academic.id;
    const classroom = { classroom_id, period_id, academic_id };
    const record = { classroom, students };
    const send = await postData("regulers/quran/hafalan/update", record);
    if (send.status === 200) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: send.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: send.data.message,
      });
    }
  };

  const backButton = () => {
    Swal.fire({
      title: "Sudah UPDATE NILAI ?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "SUDAH",
      cancelButtonText: `BELUM`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        redirect(-1);
      }
    });
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/", { replace: true });
    }
    getStudents();
  }, [auth.login]);

  return (
    <div className="row row-cols-1">
      <div className="col-mb-4">
        <div className="row">
          <div className="col-3 offset-9">
            <label className="mb-2">IMPORT NILAI</label>
            <ImportComponent
              url={`import/template/nilai/hafalan`}
              setCollection={(data) => setStudents(data)}
            />
          </div>
        </div>
      </div>
      <div className="col mb-4 table-responsive">
        <table className="table table-bordered table-striped">
          <thead className="text-uppercase text-center">
            <tr>
              <th>#</th>
              <th>nama</th>
              <th>hafalan tambahan</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={student.id}>
                <td>{index + 1}</td>
                <td>{student.name}</td>
                <td>
                  <textarea
                    className="form-control"
                    id="hafalan_tambahan"
                    name="hafalan_tambahan"
                    value={student.nilaiquran.hafalan_tambahan}
                    onChange={(e) => handleTambahan(e, index)}
                  ></textarea>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col">
        <button className="btn btn-warning" onClick={() => backButton()}>
          BACK
        </button>
        <button
          className="btn btn-success float-end"
          onClick={() => submitNilai()}
        >
          UPDATE
        </button>
      </div>
    </div>
  );
};

export default FormNilaiSiswaHafalTambahan;
