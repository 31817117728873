import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AcademicEditFormComponent from "../components/AcademicEditFormComponent";
import AlertComponent from "../components/AlertComponent";
import PeriodListComponent from "../components/PeriodListComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated } from "../store";

const AcademicEditFormContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [academic, setAcademic] = useState("");
  const [periods, setPeriods] = useState([]);
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/academics/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAcademic(response.data.academic);
      setPeriods(response.data.periods);
      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }

    getData();
  }, [auth.login]);

  return (
    <div>
      <div className="card mb-5">
        <div className="card-header">
          <h2>Edit {academic?.name}</h2>
        </div>
        <div className="card-body">
          <div className="row">
            {errors.message ? (
              <AlertComponent color={"danger"} message={errors.message} />
            ) : (
              ""
            )}
          </div>
          <div className="row">
            {academic ? (
              <AcademicEditFormComponent
                academic={academic}
                setErrors={(error) => setErrors(error)}
                getData={() => getData()}
              />
            ) : (
              <SmallLoadingComponent />
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <PeriodListComponent
          periods={periods}
          setErrors={(error) => setErrors(error)}
          getData={() => getData()}
        />
      </div>
    </div>
  );
};

export default AcademicEditFormContainer;
