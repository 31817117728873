import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import { adminattitudelist, disablemenu } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const CompetenceDetailComponent = ({
  categorie,
  competence,
  students,
  setStudents,
  setErrors,
}) => {
  const adminattitudes = useRecoilValue(adminattitudelist);
  const [disable, setDisable] = useRecoilState(disablemenu);
  const [taskscoll, setTaskscoll] = useState([]);
  const [edit, setEdit] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [colheight, setColheight] = useState(54);
  const { id } = useParams();
  const redirect = useNavigate();

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post(
        "/guru/score/update",
        { competence_id: id, taskscoll, students },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        setDisable(false);
      }
    } catch (e) {
      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const addArrayTasks = (data) => {
    if (data === null) {
      setTaskscoll([1]);
      return [{ name: 1, score: 0 }];
    } else {
      let taksCount = taskscoll.length + 1;
      setTaskscoll([...taskscoll, taksCount]);
      return [...data, { name: 1, score: 0 }];
    }
  };

  const addTask = () => {
    const format = students.map((row) => ({
      ...row,
      competencestudent: {
        tasks: addArrayTasks(row.competencestudent.tasks),
        score: row.competencestudent.score,
        description: row.competencestudent.description,
      },
    }));

    // console.log(format);

    setStudents(format);
  };

  const removeArray = (data) => {
    if (data !== null) {
      const index = data.length - 1;
      return [...data.slice(0, index), ...data.slice(index + 1)];
    } else {
      return [...data];
    }
  };

  const average = (data) => {
    const number = data.map((row) => parseFloat(row.score));
    const sum = number.reduce((partialSum, a) => partialSum + a, 0);
    const avg = sum / number.length;
    const roundedString = Math.round(avg);
    // const roundedString = avg.toFixed(2);
    // const rounded = Number(roundedString);

    return roundedString;
  };

  const removeTask = () => {
    const format = students.map((row) => ({
      ...row,
      competencestudent: {
        tasks: removeArray(row.competencestudent.tasks),
        score: average(removeArray(row.competencestudent.tasks)),
        description: row.competencestudent.description,
      },
    }));

    setStudents(format);

    const counttasks = taskscoll.length - 1;
    setTaskscoll([
      ...taskscoll.slice(0, counttasks),
      ...taskscoll.slice(counttasks + 1),
    ]);
  };

  const handleNan = (value) => {
    if (isNaN(value)) {
      value = 0;
    } else {
      value = value;
    }

    return value;
  };

  const handleNull = (value) => {
    if (value === null) {
      value = "";
    } else {
      value = value;
    }

    return value;
  };

  const handleTask = (e, sindex, tindex) => {
    let value = e.target.value;
    // console.log(value);

    const fromdata = { ...students };
    let oldsiswa = JSON.parse(JSON.stringify(fromdata[sindex]));

    const onlyNumbers = value.replace(/[^\d.]/g, "");
    if (isNaN(onlyNumbers)) {
      onlyNumbers = 0;
    }

    oldsiswa.competencestudent.tasks[tindex] = {
      name: oldsiswa.competencestudent.tasks[tindex].name,
      score: onlyNumbers,
    };

    oldsiswa.competencestudent.score = average(
      oldsiswa.competencestudent.tasks
    );

    const record = [
      ...students.slice(0, sindex),
      oldsiswa,
      ...students.slice(sindex + 1),
    ];

    // console.log(record);

    setStudents(record);
  };

  const editTasksName = (data, value, index) => {
    if (value === null) {
      return [data];
    } else {
      const tasks = JSON.parse(JSON.stringify(data));
      tasks[index] = { name: value, score: tasks[index].score };
      return [...tasks];
    }
  };

  const handleTaskName = (e, tindex) => {
    if (e.target.value !== "") {
      const format = students.map((row, index) => ({
        ...row,
        competencestudent: {
          tasks: editTasksName(
            row.competencestudent.tasks,
            e.target.value,
            tindex
          ),
          score: row.competencestudent.score,
          description: row.competencestudent.description,
        },
      }));

      setStudents(format);

      const modtasks = [...taskscoll];
      modtasks[tindex] = e.target.value;
      setTaskscoll(modtasks);
    }
  };

  const handleNilaiOption = (e, index) => {
    const indexat = adminattitudes.findIndex(
      (row) => row.predicate === e.target.value
    );
    const attitude = adminattitudes[indexat];

    const newvalue = JSON.parse(JSON.stringify({ ...students[index] }));
    newvalue.competencestudent.score = attitude.predicate;
    newvalue.competencestudent.description = attitude.description;
    const record = [
      ...students.slice(0, index),
      newvalue,
      ...students.slice(index + 1),
    ];

    setStudents(record);
  };

  const handleKeterangan = (e, index) => {
    const newvalue = JSON.parse(JSON.stringify({ ...students[index] }));
    newvalue.competencestudent.description = e.target.value;
    const record = [
      ...students.slice(0, index),
      newvalue,
      ...students.slice(index + 1),
    ];

    // console.log(record);
    setStudents(record);
  };

  const trHeightAppend = () => {
    const tbody = document.getElementsByTagName("tbody")[1];
    if (tbody.childNodes.length > 0) {
      const trHeight = tbody.childNodes[0].cells[0].clientHeight;
      setColheight(trHeight);
    }
  };

  const tableScrollLeft = () => {
    trHeightAppend();
    const scrollValue =
      document.getElementsByClassName("table-responsive")[0].scrollLeft;

    if (scrollValue > 200) {
      setScroll(true);
    } else if (scrollValue < 200) {
      setScroll(false);
    }
  };

  useEffect(() => {
    setTaskscoll(competence.tasks);
  }, [competence.tasks]);

  return (
    <div className="col">
      <div className="row">
        <div className="col">
          {categorie?.score_type !== "predikat" ? (
            <button className="btn btn-success mb-4" onClick={() => addTask()}>
              Add Tugas
            </button>
          ) : null}
        </div>
      </div>
      <div className="row mb-4">
        {taskscoll.length > 0 ? (
          <Fragment>
            <div className="col">
              <button
                className="btn btn-primary"
                onClick={() => setEdit(!edit)}
              >
                Edit Tugas
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-danger float-end"
                onClick={() => removeTask()}
              >
                Remove Tugas
              </button>
            </div>
          </Fragment>
        ) : null}
      </div>
      {students ? (
        <div className="row row-cols-1">
          <div
            className="col table-responsive mb-2"
            onTouchMove={() => tableScrollLeft()}
          >
            <div className="col">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">NIS</th>
                    <th scope="col">Nama Siswa</th>
                    {taskscoll.length > 0
                      ? taskscoll.map((row, index) => (
                          <th key={index} className="text-center" scope="col">
                            {edit ? (
                              <input
                                className="form-control fix-width-input"
                                name="taskname"
                                defaultValue={row}
                                onChange={(e) => handleTaskName(e, index)}
                              />
                            ) : (
                              row
                            )}
                          </th>
                        ))
                      : null}
                    <th scope="col">
                      {categorie?.score_type === "predikat"
                        ? "Predikat"
                        : "Rata - Rata"}
                    </th>
                    <th scope="col">Keterangan</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((siswa, sindex) => (
                    <tr key={sindex}>
                      <th scope="row">{sindex + 1}</th>
                      <td>{siswa.nis}</td>
                      <td
                        className={scroll ? "absolute-col" : null}
                        style={{ height: colheight + "px" }}
                      >
                        {siswa.name}
                      </td>
                      {siswa.competencestudent.tasks !== null
                        ? siswa.competencestudent.tasks.map((task, tindex) => (
                            <td key={tindex}>
                              <input
                                className="form-control fix-width-input"
                                value={handleNan(
                                  students[sindex].competencestudent.tasks[
                                    tindex
                                  ].score
                                )}
                                onChange={(e) => handleTask(e, sindex, tindex)}
                              />
                            </td>
                          ))
                        : null}
                      <td className="text-center">
                        {categorie?.score_type === "predikat" ? (
                          <select
                            key={0}
                            className={`form-select`}
                            name={`nilai` + siswa.nis}
                            type="text"
                            id="minimum_score"
                            value={students[sindex].competencestudent.score}
                            onChange={(e) => handleNilaiOption(e, sindex)}
                          >
                            <option value="">==Pilih Predikat==</option>
                            {adminattitudes
                              ? adminattitudes.map((row, index) => (
                                  <option key={index} value={row.predicate}>
                                    {row.predicate}
                                  </option>
                                ))
                              : ""}
                          </select>
                        ) : (
                          siswa.competencestudent.score
                        )}
                      </td>
                      <td>
                        <textarea
                          className="form-control fix-width-textarea"
                          name="keterangan"
                          value={handleNull(
                            siswa.competencestudent.description
                          )}
                          onChange={(e) => handleKeterangan(e, sindex)}
                        ></textarea>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col">
            <button
              type="button"
              onClick={() => redirect(-1)}
              className="btn btn-warning float-left"
              disabled={disable}
            >
              BACK
            </button>
            <button
              className="btn btn-primary float-end"
              onClick={(e) => handleForm(e)}
            >
              UPDATE
            </button>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default CompetenceDetailComponent;
