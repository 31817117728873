import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilValue } from "recoil";
import { authenticated } from "../store";
import { newTabPdf } from "../utils/GetFileFunct";
import SmallLoadingComponent from "./SmallLoadingComponent";

const ReportClassroomDetailComponent = () => {
  const auth = useRecoilValue(authenticated);
  const [report, setReport] = useState(false);
  const [students, setStudents] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [colheight, setColheight] = useState(54);
  const { classroom_id, academic_id } = useParams();
  const params = classroom_id + "/" + academic_id + "/detail";
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/reports/" + params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setReport(response.data);
      setStudents(response.data.students);

      // console.log(response.data);
    } catch (e) {
      console.log(e.response.data);
    }
  };

  const newTabPts = async (student, period) => {
    const params = `exports/${student.id}/${report?.classroom.id}/${report?.academic.id}/${period}/pts`;
    newTabPdf(params);
  };

  const newTabPas = async (student, period) => {
    const params = `exports/${student.id}/${report?.classroom.id}/${report?.academic.id}/${period}/pas`;
    newTabPdf(params);
  };

  const trHeightAppend = () => {
    const tbody = document.getElementsByTagName("tbody")[0];
    if (tbody.childNodes.length > 0) {
      const trHeight = tbody.childNodes[0].cells[0].clientHeight;
      setColheight(trHeight);
    }
  };

  const tableScrollLeft = () => {
    trHeightAppend();
    const scrollValue =
      document.getElementsByClassName("table-responsive")[0].scrollLeft;

    if (scrollValue > 200) {
      setScroll(true);
    } else if (scrollValue < 200) {
      setScroll(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="card-header">
        <h2>
          List Siswa Kelas{" "}
          <span className="badge bg-success fs-5">
            {report?.classroom?.name}
          </span>
        </h2>
      </div>
      {report ? (
        <div className="card-body">
          <div className="col-md-5 mb-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Nama Wali Kelas</th>
                  <th>:</th>
                  <th>{auth?.user.name}</th>
                </tr>
                <tr>
                  <th>Kelas</th>
                  <th>:</th>
                  <th>{report?.classroom?.name}</th>
                </tr>
                <tr>
                  <th>Tahun Ajaran</th>
                  <th>:</th>
                  <th>{report?.academic?.nickname}</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="row">
            <div
              className="col table-responsive"
              onTouchMove={() => tableScrollLeft()}
            >
              <table className="table table-bordered table-striped">
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>KELAS</th>
                    <th>NIS</th>
                    <th>NAMA</th>
                    <th>Rapot</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, index) => (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{report.classroom.name}</td>
                      <td className="text-center">{student.nis}</td>
                      <td
                        className={scroll ? "absolute-col" : null}
                        style={{ height: colheight + "px" }}
                      >
                        <div className="fix-width">{student.name}</div>
                      </td>
                      <td key={index} className="text-center">
                        {report.academic.periods.map((row, index) => (
                          <Fragment key={index}>
                            {report.classroom.group_name === row.group_name ? (
                              <div className="row">
                                <div className="col mb-3">
                                  <button
                                    className="btn btn-dark"
                                    onClick={() => newTabPts(student, row.id)}
                                  >
                                    Open Rapot Semester {row.name} PTS
                                  </button>
                                </div>
                                <div className="col">
                                  <button
                                    className="btn btn-success"
                                    onClick={() => newTabPas(student, row.id)}
                                  >
                                    Open Rapot Semester {row.name} PAS
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                onClick={() => redirect(-1)}
                className="btn btn-warning float-end"
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default ReportClassroomDetailComponent;
