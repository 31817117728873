import React from "react";

const DuplicateStudent = ({ duplicates }) => {
  return (
    <div>
      {duplicates.duplicateNisnDatabase ? (
        <ul className="list-group mb-4">
          <li className="list-group-item bg-warning fw-bold">
            Duplicate NISN Pada Aplikasi
          </li>
          {duplicates.duplicateNisnDatabase.map((student, index) => (
            <li className="list-group-item" key={index}>
              <span className="badge bg-success fs-6">{student.name}</span> NISN{" "}
              <span className="badge bg-success fs-6">{student.nisn}</span> NIS{" "}
              <span className="badge bg-success fs-6">{student.nis}</span>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
      {duplicates.duplicateNisDatabase ? (
        <ul className="list-group mb-4">
          <li className="list-group-item bg-warning fw-bold">
            Duplicate NIS Pada Aplikasi
          </li>
          {duplicates.duplicateNisDatabase.map((student, index) => (
            <li className="list-group-item" key={index}>
              <span className="badge bg-success fs-6">{student.name}</span> NISN{" "}
              <span className="badge bg-success fs-6">{student.nisn}</span> NIS{" "}
              <span className="badge bg-success fs-6">{student.nis}</span>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
      {duplicates.duplicateNisnExcel ? (
        <ul className="list-group mb-4">
          <li className="list-group-item bg-warning fw-bold">
            Duplicate NISN Pada Excel
          </li>
          {duplicates.duplicateNisnExcel.map((nisn, index) => (
            <li className="list-group-item" key={index}>
              NISN <span className="badge bg-success fs-6">{nisn}</span>{" "}
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
      {duplicates.duplicateNisExcel ? (
        <ul className="list-group mb-4">
          <li className="list-group-item bg-warning fw-bold">
            Duplicate NIS Pada Excel
          </li>
          {duplicates.duplicateNisExcel.map((nis, index) => (
            <li className="list-group-item" key={index}>
              NIS <span className="badge bg-success fs-6">{nis}</span>{" "}
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

export default DuplicateStudent;
