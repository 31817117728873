import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CategorieEditFormComponent from "../components/CategorieEditFormComponent";
import { authenticated, categoriedetail } from "../store";
import { useRecoilState } from "recoil";
import axios from "axios";

const CategorieEditFormContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [categorie, setCategorie] = useRecoilState(categoriedetail);
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/categories/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setCategorie(response.data);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Edit <span className="badge bg-success fs-6">{categorie?.name}</span>
        </h2>
      </div>
      <div className="card-body">
        <CategorieEditFormComponent />
      </div>
    </div>
  );
};

export default CategorieEditFormContainer;
