import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ArchiveClassroomComponent = ({ data, classroomuser }) => {
  const nameColor = (type) => {
    if (type === "PTS") {
      return "bg-warning text-dark fs-6";
    } else if (type === "PAS") {
      return "bg-success fs-6";
    } else {
      return "bg-info text-dark fs-6";
    }
  };

  const periodColor = (period) => {
    const number = period.match(/\d+/)[0];
    if (parseInt(number) % 2 == 0) {
      return "bg-dark fs-6";
    } else {
      return "bg-brown fs-6";
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="card mb-5">
      <div className="card-header">
        <h2>
          {data.categorie.name} Kelas{" "}
          <span className="badge bg-success fs-5">
            {classroomuser.classroom.name}
          </span>{" "}
          <span className="badge bg-success fs-5">
            {classroomuser.lesson.name}{" "}
          </span>{" "}
          <span className="badge bg-success fs-5">
            {classroomuser.academic.nickname}
          </span>
        </h2>
      </div>
      <div className="card-body">
        {/* <div className="col mb-4 text-end">
          <Link
            to={
              "/competences/classroomuser/" +
              id +
              "/" +
              props.data.categorie.id +
              "/create"
            }
          >
            <button className="btn btn-success">TAMBAH KOMPETENSI</button>
          </Link>
        </div> */}
        <div className="col table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">NAMA KOMPETENSI</th>
                <th className="text-center">TYPE</th>
                <th className="text-center">SEMESTER</th>
                <th className="text-center">STATUS NILAI</th>
                <th colSpan={1} className="text-center">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {data.competences.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <span className={`badge ${nameColor(row.type)} text-wrap`}>
                      {row.name}
                    </span>
                  </td>
                  <td className="text-center ">
                    <span className={`badge ${nameColor(row.type)}`}>
                      {row.type}
                    </span>
                  </td>
                  <td className="text-center ">
                    <span className={`badge ${periodColor(row.period.name)}`}>
                      Semester {row.period.name}
                    </span>
                  </td>
                  <td className="text-center">
                    {row.competencestudent === null ? (
                      <span className="badge bg-success fs-6">
                        NILAI COMPLET
                      </span>
                    ) : (
                      <span className="badge bg-warning fs-6">NILAI MISS</span>
                    )}
                  </td>
                  {/* <td className="text-center">
                    <Link to={"/competences/" + row.id + "/edit"}>
                      <button className="btn btn-primary">EDIT</button>
                    </Link>
                  </td> */}
                  <td className="text-center">
                    <Link to={`/archives/competence/${row.id}/classroom`}>
                      <button className="btn btn-purple text-white">
                        NILAI
                      </button>
                    </Link>
                  </td>
                  {/* <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => delData(row.id)}
                    >
                      HAPUS
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ArchiveClassroomComponent;
