import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import LedgerClassroomListComponent from "../components/LedgerClassroomListComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated, reportlist } from "../store";

const LedgerClassroomListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [reports, setReports] = useRecoilState(reportlist);
  const [errors, setErrors] = useState("");

  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/reports", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setReports(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      {reports ? <LedgerClassroomListComponent /> : <SmallLoadingComponent />}
    </div>
  );
};

export default LedgerClassroomListContainer;
