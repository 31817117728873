import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, classroomshow, studentlist } from "../store";
import CopyDataComponent from "./CopyDataComponent";

const AddStudentClassroomFormComponent = (props) => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [students, setStudents] = useRecoilState(studentlist);
  const [classroomdetail] = useRecoilState(classroomshow);
  const [findstudent, setFindstudent] = useState("");
  const [name, setName] = useState("");
  const [nis, setNis] = useState("");
  const [errors, setErrors] = useState([]);
  const [show, setShow] = useState(false);
  const redirect = useNavigate();
  const { id } = useParams();

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/students?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setFindstudent(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const nameSearch = (name) => {
    setName(name);
    const src = "src=" + name;
    getData(src);
  };

  const nipSearch = (nis) => {
    setNis(nis);
    const src = "nis=" + nis;
    getData(src);
  };

  const addData = async (row) => {
    try {
      let response = await axios.post(
        "groups/student",
        {
          classroom_id: id,
          student_id: row.id,
          academic_id: classroomdetail.classroom.academic_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setName("");
        setNis("");
        props.updateData();

        return true;
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: e.response.data.message,
        showConfirmButton: true,
      });

      setErrors(e.response.data);

      return false;
      // console.log(e.response.data);
    }
  };

  const delData = async (index, row) => {
    try {
      let response = await axios.post(
        "groups/student/delete",
        {
          student_id: row.student_id,
          classroom_id: row.classroom_id,
          academic_id: classroomdetail.classroom.academic_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });

        props.updateData();
        return true;
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Your work not deleted",
        showConfirmButton: true,
      });

      setErrors(e.response.data);

      return false;
      // console.log(e.response.data);
    }
  };

  const clearClasshroom = () => {
    console.log("halo");
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <form>
      <div className="row mb-4">
        <div className="col-md-6 col-xs-12 mb-2">
          <label htmlFor="nama" className="form-label">
            Nama Siswa
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => nameSearch(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
          <ul className="list-group suggest">
            {name && findstudent
              ? findstudent.data.map((row, index) => (
                  <li
                    key={index}
                    className="list-group-item"
                    onClick={() => addData(row)}
                  >
                    {row.name}
                  </li>
                ))
              : ""}
          </ul>
        </div>
        <div className="col-md-6 col-xs-12 mb-2">
          <label htmlFor="nis" className="form-label">
            NIS
          </label>
          <input
            className={`form-control mb-1 ${errors.nis ? "is-invalid" : ""}`}
            type="text"
            placeholder="NIS"
            id="nis"
            value={nis}
            onChange={(e) => nipSearch(e.target.value)}
          />
          {errors.nis ? (
            <div className="invalid-feedback">{errors.nis[0]}</div>
          ) : (
            ""
          )}
          <ul className="list-group suggest">
            {nis && findstudent
              ? findstudent.data.map((row, index) => (
                  <li
                    key={index}
                    className="list-group-item"
                    onClick={() => addData(row)}
                  >
                    {row.name}
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
      <div className="row row-cols-1 mb-4">
        <div className="col">
          <CopyDataComponent
            show={show}
            type={"student"}
            actionModal={() => setShow(!show)}
            refreshData={() => props.updateData()}
          />
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => clearClasshroom()}
              >
                KOSONGKAN KELAS
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-success float-end"
                onClick={() => setShow(!show)}
              >
                COPY DATA
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col table-responsive">
          {students.students ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">NIS</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Kelas</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {students.students.map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{row.student.nis}</td>
                    <td>{row.student.name}</td>
                    <td>{row.classroom.name}</td>
                    <td>
                      <button
                        type="button"
                        onClick={() => delData(index, row)}
                        className="btn btn-danger"
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </div>

      <Link to="/classrooms">
        <button className="btn btn-warning mx-4 float-end mt-5">BACK</button>
      </Link>
    </form>
  );
};

export default AddStudentClassroomFormComponent;
