import React from "react";
import { useRecoilState } from "recoil";
import { dashboardlist } from "../store";

const ClassroomStatusComponent = () => {
  const [dashboards, setDashboards] = useRecoilState(dashboardlist);

  return (
    <div className="col mb-4">
      <div className="card">
        <div className="card-header">
          <h4>
            <i className="bi bi-stack"></i> Kelas
          </h4>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              {dashboards?.classrooms?.map((row, index) => (
                <tr key={index}>
                  <th>{row.name}</th>
                  <th>
                    <span className={`badge ${row.message.color}`}>
                      <i className="bi bi-people-fill"></i>{" "}
                      {row.message.description}
                    </span>
                  </th>
                </tr>
              ))}
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClassroomStatusComponent;
