import React from "react";

const InputComponent = ({ type, name, label, value, errors, handleInput }) => {
  return (
    <div>
      <label htmlFor="top" className="form-label">
        {label}
      </label>
      <input
        className={`form-control mb-1 ${errors.top ? "is-invalid" : ""}`}
        type={type}
        name={name}
        placeholder={label}
        id={name}
        value={value}
        onChange={(e) => handleInput(e)}
      />
      {errors.top ? (
        <div className="invalid-feedback">{errors.top[0]}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputComponent;
