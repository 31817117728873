import React, { Fragment } from "react";
import Swal from "sweetalert2";
import { updateStatusNilai } from "../../utils/ClassroomFunct";
import IsmubaPeriodButton from "./IsmubaPeriodButton";

const QuranClassroomComponent = ({ classrooms, type, refreshData }) => {
  const kumpulNilai = (id) => {
    Swal.fire({
      title: "Yakin kumpulkan nilai ?",
      showDenyButton: true,
      confirmButtonText: "OK",
      denyButtonText: `CANCEL`,
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatusNilai("qurans", id).then((result) => {
          if (result.status === 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: result.data.message,
              showConfirmButton: false,
              timer: 2000,
            });

            refreshData();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: result.data.message,
              showConfirmButton: true,
            });
          }
        });
      }
    });
  };
  return (
    <div className="col table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">NAMA KELAS</th>
            <th className="text-center">INPUT NILAI</th>
            <th className="text-center">KUMPULKAN NILAI</th>
          </tr>
        </thead>
        <tbody>
          {classrooms.map((item, index) => (
            <tr key={index}>
              <td className="text-center">{index + 1}</td>
              <td className="text-center">
                <span className="badge bg-success fs-6">
                  {item.classroom_name}
                </span>
              </td>
              <td className="text-center">
                {item.periods.map((period, index) => (
                  <Fragment key={index}>
                    {item.group_name === period.group_name ? (
                      <IsmubaPeriodButton
                        classroom_id={item.classroom_id}
                        period={period}
                        type={type}
                      />
                    ) : (
                      ""
                    )}
                  </Fragment>
                ))}
              </td>
              <td className="text-center">
                <button
                  className="btn btn-purple text-white"
                  disabled={item.score_status === "menunggu" ? false : true}
                  onClick={() => kumpulNilai(item.id)}
                >
                  KUMPULKAN
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuranClassroomComponent;
