import React from "react";
import AdministratorFormComponent from "../components/AdministratorFormComponent";

const AdministratorFormContainer = () => {
  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Administrator</h2>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <AdministratorFormComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdministratorFormContainer;
