import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const ArchiveCompetenceClassroomListComponent = ({ archives, setErrors }) => {
  const recapExport = async (id, classroom, academic) => {
    try {
      let response = await axios.get("lesson/" + id + "/recap", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename = "Kelas " + classroom + "-" + academic + "-recaps";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  return (
    <div className="card mb-5">
      <div className="card-header">
        <h2>List Kelas Ajar</h2>
      </div>
      <div className="card-body">
        <div className="col table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">Kelas</th>
                <th className="text-center">Pelajaran</th>
                <th className="text-center">Tahun Ajaran</th>
                <th className="text-center" colSpan={2}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {archives.classrooms.map((row, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">
                    <span className="badge bg-success fs-6">
                      {row.classroom.name}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="badge bg-secondary fs-6">
                      {row.lesson.name}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="badge bg-warning text-black fs-6">
                      {row.academic.nickname}
                    </span>
                  </td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() =>
                        recapExport(
                          row.id,
                          row.classroom.name,
                          row.academic.nickname
                        )
                      }
                    >
                      RECAP
                    </button>
                  </td>
                  <td className="text-center">
                    <Link to={`/archives/classroomuser/${row.id}/competence`}>
                      <button className="btn btn-primary">KOMPETENSI</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ArchiveCompetenceClassroomListComponent;
