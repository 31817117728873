import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AlertComponent from "../components/AlertComponent";
import ExportDataComponent from "../components/ExportDataComponent";
import PaginationComponent from "../components/PaginationComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";

const StudentListComponent = () => {
  const [students, setStudents] = useState([]);
  const [errors, setErrors] = useState("");

  const page = () => {
    let url = window.location.href;
    url = url.split("#page/", 2);
    if (url[1] !== undefined) {
      return url[1];
    } else {
      return null;
    }
  };

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/students?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setStudents(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const handleSearch = (e) => {
    const src = "src=" + e.target.value;
    getData(src);
  };

  const delData = async (id) => {
    try {
      let response = await axios.delete("/students/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });
        getData(page());
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const browserDetect = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }

    return browserName;
  };

  const exportCover = async (student_id, student_name, student_nis) => {
    try {
      const params = student_id;

      let response = await axios.get("exports/" + params + "/cover", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename = student_name + "-" + student_nis + "-Cover";
        const blob = new Blob([response.data], { type: "application/pdf" });

        if (browserDetect() === "firefox") {
          const fileUrl = URL.createObjectURL(blob);
          const w = window.open(fileUrl, "_blank");
          w && w.focus();
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${filename}.pdf`;
          link.click();
        }
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="card-header">
        <h2>List Siswa</h2>
      </div>
      {students.data ? (
        <div className="card-body">
          <div className="row">
            {errors.message ? (
              <AlertComponent color={"danger"} message={errors.message} />
            ) : (
              ""
            )}
          </div>
          <div className="row mb-4">
            <div className="col border p-3">
              <ExportDataComponent />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-xs-12 mb-4">
              <input
                type="text"
                className="form-control float-end"
                placeholder="Search..."
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <div className="col-md-8 col-xs-12 mb-2">
              <Link to="/students/create">
                <button className="btn btn-success mb-4 float-end">
                  CREATE
                </button>
              </Link>
              <Link to="/students/import/update">
                <button className="btn btn-primary mb-4 float-end mx-2">
                  UPDATE
                </button>
              </Link>
            </div>
          </div>
          <div className="col table-responsive mb-4">
            <table className="table table-bordered">
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>NISN</th>
                  <th>NIS</th>
                  <th>Nama</th>
                  <th>Gender</th>
                  <th>Kelas</th>
                  <th colSpan={4}>Action</th>
                </tr>
              </thead>
              <tbody>
                {students.data.map((student, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{student.nisn}</td>
                    <td>{student.nis}</td>
                    <td>{student.name}</td>
                    <td>{student.gender}</td>
                    <td>
                      {student.classroomstudents.map((row, index) => (
                        <span
                          key={index}
                          className="badge bg-warning text-black mx-2 fs-6"
                        >
                          {row.classroom.name}
                        </span>
                      ))}
                    </td>
                    <td className="text-center">
                      <Link to={"/students/" + student.id}>
                        <button className="btn btn-success">DETAIL</button>
                      </Link>
                    </td>
                    <td className="text-center">
                      <Link to={"/students/" + student.id + "/edit"}>
                        <button className="btn btn-primary">EDIT</button>
                      </Link>
                    </td>
                    <td className="text-center">
                      <button
                        type="button"
                        className="btn btn-purple text-white"
                        onClick={() =>
                          exportCover(student.id, student.name, student.nis)
                        }
                      >
                        COVER
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        onClick={() => delData(student.id)}
                        className="btn btn-danger"
                      >
                        HAPUS
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col">
            <PaginationComponent
              data={students.links}
              handlePaginate={(page) => getData(page)}
            />
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default StudentListComponent;
