import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AlertComponent from "../components/AlertComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import StatusClassroomListComponent from "../components/StatusClassroomListComponent";
import { authenticated, homeroomscorelist } from "../store";

const StatusClassroomListContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [classrooms, setClassrooms] = useState([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/reports", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setClassrooms(response.data);
        setErrors([]);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    getData();
  }, [auth.login]);

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h2>Status Nilai Kelas</h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <AlertComponent color={"danger"} message={errors.message} />
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <StatusClassroomListComponent
            classrooms={classrooms}
            setErrors={(error) => setErrors(error)}
          />
        </div>
      </div>
    </div>
  );
};

export default StatusClassroomListContainer;
