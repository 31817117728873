import React from "react";
import { useRecoilState } from "recoil";
import { teacherdetail } from "../store";

const TeacherDetailComponent = () => {
  const [teacher] = useRecoilState(teacherdetail);

  return (
    <div className="row row-cols-1">
      <div className="col-md-4 mb-4 table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>NBM</th>
              <th>:</th>
              <th>{teacher.nip}</th>
            </tr>
            <tr>
              <th>Nama</th>
              <th>:</th>
              <th>{teacher.name}</th>
            </tr>
            <tr>
              <th>E-Mail</th>
              <th>:</th>
              <th>{teacher.email}</th>
            </tr>
            <tr>
              <th>Gender</th>
              <th>:</th>
              <th>{teacher.gender}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="col mb-2">
        <h3>
          <span className="badge bg-warning text-black">GURU KELAS</span>
        </h3>
      </div>
      <div className="col mb-4 table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">KELAS</th>
              <th className="text-center">TAHUN AJARAN</th>
            </tr>
          </thead>
          <tbody>
            {teacher.classroomusers.map((row, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  <span className="badge bg-success fs-6">
                    {row.classroom.name}
                  </span>
                </td>
                <td className="text-center">
                  <span className="badge bg-primary fs-6">
                    {row.academic.name}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col mb-2">
        <h3>
          <span className="badge bg-warning text-black">WALI KELAS</span>
        </h3>
      </div>
      <div className="col table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">KELAS</th>
              <th className="text-center">TAHUN AJARAN</th>
            </tr>
          </thead>
          <tbody>
            {teacher.homerooms.map((row, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  <span className="badge bg-success fs-6">
                    {row.classroom.name}
                  </span>
                </td>
                <td className="text-center">
                  <span className="badge bg-primary fs-6">
                    {row.academic.name}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeacherDetailComponent;
