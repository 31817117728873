import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { lessonslist } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";
import PaginationComponent from "./PaginationComponent";
import Swal from "sweetalert2";

const LessonsListComponent = () => {
  const [lessons, setLessons] = useRecoilState(lessonslist);
  const [errors, setErrors] = useState("");

  const page = () => {
    let url = window.location.href;
    url = url.split("#page/", 2);
    if (url[1] !== undefined) {
      return url[1];
    } else {
      return null;
    }
  };

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/lessons?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setLessons(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const delData = async (id) => {
    try {
      let response = await axios.delete("/lessons/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });
        getData(page());
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const handleSearch = (e) => {
    const src = "src=" + e.target.value;
    getData(src);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="card-header">
        <h2>List Pelajaran</h2>
      </div>
      {lessons.data ? (
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <Link to="/lessons/create">
                <button className="btn btn-success mb-4">Create</button>
              </Link>
            </div>
            <div className="col-md-4 offset-md-2 col-xs-12">
              <input
                type="text"
                className="form-control float-end mb-4 col-2"
                placeholder="Search..."
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center" scope="col">
                      #
                    </th>
                    <th className="text-center" scope="col">
                      Nama Pelajaran
                    </th>
                    <th className="text-center" scope="col">
                      KKM
                    </th>
                    <th className="text-center" scope="col">
                      Kelompok
                    </th>
                    <th className="text-center" scope="col">
                      Nomor Urut
                    </th>
                    <th className="text-center" colSpan={2} scope="col">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lessons.data.map((row, index) => (
                    <tr key={index}>
                      <th className="text-center">{index + 1}</th>
                      <td>{row.name}</td>
                      <td className="text-center">{row.kkm}</td>
                      <td>{row.lcategorie.name}</td>
                      <td className="text-center">{row.lesson_order}</td>
                      <td className="text-center">
                        <Link to={"/lessons/" + row.id + "/edit"}>
                          <button className="btn btn-primary">Edit</button>
                        </Link>
                      </td>
                      <td className="text-center">
                        <button
                          type="button"
                          onClick={() => delData(row.id)}
                          className="btn btn-danger"
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <PaginationComponent
                data={lessons.links}
                handlePaginate={(page) => getData(page)}
              />
            </div>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default LessonsListComponent;
