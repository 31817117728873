import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import { waiting } from "../store";

const ForgotPasswordComponent = () => {
  const setLoading = useSetRecoilState(waiting);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const record = { email };

  const sendMail = async () => {
    try {
      setLoading(true);
      let response = await axios.post("password/forgot", record);

      if (response.status === 200) {
        setLoading(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: true,
        });
        setEmail("");
        setErrors("");
      }

      // console.log(response.data);
    } catch (e) {
      setLoading(false);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        showConfirmButton: true,
      });

      //   setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  return (
    <div className="row">
      {/* {errors.message ? (
        <div className="alert alert-danger mb-4" role="alert">
          <b>{errors?.message}</b>
        </div>
      ) : null} */}
      <div className="col-12">
        <label className="mb-2" htmlFor="email">
          E-mail
        </label>
        <input
          className={`form-control mt-1 mb-1 ${
            errors.email ? "is-invalid" : ""
          }`}
          id="e-mail"
          name="email"
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email ? (
          <div className="invalid-feedback">{errors.email[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-12 mt-4 ">
        <Link to={"/"}>
          <button className="btn btn-warning float-start">LOGIN</button>
        </Link>
        <button
          className="btn btn-success float-end"
          onClick={() => sendMail()}
        >
          SEND
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
