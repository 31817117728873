import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import InputComponent from "../components/InputComponent";

const MarginForm = ({ title, margin, updateConfig, errors }) => {
  const [records, setRecords] = useState({
    top: "",
    right: "",
    bottom: "",
    left: "",
  });

  const handleInput = (el) => {
    const change = { ...records };
    const input = el.target;
    change[input.name] = input.value;
    setRecords(change);
  };

  useEffect(() => {
    if (margin.setting?.top) {
      setRecords({
        top: margin.setting?.top,
        right: margin.setting?.right,
        bottom: margin.setting?.bottom,
        left: margin.setting?.left,
      });
    }
  }, [margin.setting]);

  return (
    <div className="row mb-4 px-4">
      <div className="col-12 mb-3 border-bottom">
        <h3 className="badge bg-success fs-4">{title}</h3>
      </div>
      <div className="col-md-3 col-xs-12 mb-2">
        <InputComponent
          type="number"
          name={"top"}
          label="Margin Atas"
          value={records.top}
          errors={errors}
          handleInput={(e) => handleInput(e)}
        />
      </div>
      <div className="col-md-3 col-xs-12 mb-2">
        <InputComponent
          type="number"
          name={"right"}
          label="Margin Kanan"
          value={records.right}
          errors={errors}
          handleInput={(e) => handleInput(e)}
        />
      </div>
      <div className="col-md-3 col-xs-12 mb-2">
        <InputComponent
          type="number"
          name={"bottom"}
          label="Margin Bawah"
          value={records.bottom}
          errors={errors}
          handleInput={(e) => handleInput(e)}
        />
      </div>
      <div className="col-md-3 col-xs-12 mb-2">
        <InputComponent
          type="number"
          name={"left"}
          label="Margin Kiri"
          value={records.left}
          errors={errors}
          handleInput={(e) => handleInput(e)}
        />
      </div>
      <div className="col-12 mt-4">
        <button
          className="btn btn-primary float-end"
          onClick={() => updateConfig(records)}
        >
          UPDATE
        </button>
      </div>
    </div>
  );
};

export default MarginForm;
