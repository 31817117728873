import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import ClassroomFormComponent from "../components/ClassroomFormComponent";
import ClassroomImportFormComponent from "../components/ClassroomImportFormComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated } from "../store";

const ClassroomFormContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [academics, setAcademics] = useState([]);
  const [errors, setErrors] = useState(false);
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/classrooms/create", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAcademics(response.data);

      // console.log(response.data);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Kelas Form</h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="alert alert-warning">{errors.message}</div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col">
            <ClassroomImportFormComponent />
            {academics.length > 0 ? (
              <ClassroomFormComponent academics={academics} />
            ) : (
              <SmallLoadingComponent />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassroomFormContainer;
