import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { competencelist } from "../store";

const CompetenceClassroomComponent = (props) => {
  const [errors, setErrors] = useState("");
  const { id } = useParams();

  const recapExport = async (
    classroom_id,
    academic_id,
    period_id,
    classname,
    academicname
  ) => {
    try {
      const params = classroom_id + "/" + academic_id + "/" + period_id;
      let response = await axios.get("classroom/recap/" + params + "/export", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename =
          "Ledger Kelas " + classname + "-" + academicname + "-recaps";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const delData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("/competences/" + id, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Your work has been deleted",
                showConfirmButton: false,
                timer: 1500,
              });
              props.updateData();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
                footer: "",
              });
            }
          });
      }
    });
  };

  const nameColor = (type) => {
    if (type === "PTS") {
      return "bg-warning text-dark fs-6";
    } else if (type === "PAS") {
      return "bg-success fs-6";
    } else {
      return "bg-info text-dark fs-6";
    }
  };

  const periodColor = (period) => {
    const number = period.match(/\d+/)[0];
    if (parseInt(number) % 2 == 0) {
      return "bg-dark fs-6";
    } else {
      return "bg-brown fs-6";
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="card mb-5">
      <div className="card-header">
        <h2>
          {props.data.categorie.name} Kelas{" "}
          <span className="badge bg-success fs-5">
            {props.classroomuser.classroom.name}
          </span>{" "}
          <span className="badge bg-success fs-5">
            {props.classroomuser.lesson.name}{" "}
          </span>{" "}
          <span className="badge bg-success fs-5">
            {props.classroomuser.academic.nickname}
          </span>
        </h2>
      </div>
      <div className="card-body">
        <div className="col mb-4 text-end">
          <Link
            to={
              "/competences/classroomuser/" +
              id +
              "/" +
              props.data.categorie.id +
              "/create"
            }
          >
            <button className="btn btn-success">TAMBAH KOMPETENSI</button>
          </Link>
        </div>
        <div className="col table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">NAMA KOMPETENSI</th>
                <th className="text-center">TYPE</th>
                <th className="text-center">SEMESTER</th>
                <th className="text-center">STATUS NILAI</th>
                <th colSpan={3} className="text-center">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data.competences.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <span className={`badge ${nameColor(row.type)} text-wrap`}>
                      {row.name}
                    </span>
                  </td>
                  <td className="text-center ">
                    <span className={`badge ${nameColor(row.type)}`}>
                      {row.type}
                    </span>
                  </td>
                  <td className="text-center ">
                    <span className={`badge ${periodColor(row.period.name)}`}>
                      Semester {row.period.name}
                    </span>
                  </td>
                  <td className="text-center">
                    {row.competencestudent === null ? (
                      <span className="badge bg-success fs-6">
                        NILAI COMPLET
                      </span>
                    ) : (
                      <span className="badge bg-warning fs-6">NILAI MISS</span>
                    )}
                  </td>
                  <td className="text-center">
                    <Link to={"/competences/" + row.id + "/edit"}>
                      <button className="btn btn-primary">EDIT</button>
                    </Link>
                  </td>
                  <td className="text-center">
                    <Link to={"/competences/" + row.id}>
                      <button className="btn btn-purple text-white">
                        NILAI
                      </button>
                    </Link>
                  </td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => delData(row.id)}
                    >
                      HAPUS
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompetenceClassroomComponent;
