import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import AdminStatusClassroomListComponent from "../components/AdminStatusClassroomListComponent";
import AlertComponent from "../components/AlertComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import StatusScoreAcademicCombo from "../components/StatusScoreAcademicCombo";
import { authenticated } from "../store";

const AdminStatusClassroomListContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [classrooms, setClassrooms] = useState([]);
  const [classroom_id, setClassroom_id] = useState(false);
  const [status, setStatus] = useState([]);
  const [comboAcademic, setComboAcademic] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/classrooms?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassrooms(response.data.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const getStatus = async (classroom_id) => {
    try {
      if (classroom_id !== "") {
        setClassroom_id(classroom_id);

        let response = await axios.get("/status/classroom/" + classroom_id, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });

        if (response.status === 200) {
          setStatus(response.data);
        }
      }
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const statusRefresh = () => {
    Swal.fire({
      title: "Atur ulang status pada kelas ini ?",
      showCancelButton: true,
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("/status/classroom/" + classroom_id + "/refresh", {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire(
                "Status nilai kelas ini telah di setel ulang !",
                "",
                "success"
              );

              getStatus(classroom_id);
            }
          });
      }
    });
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    getData();
  }, [auth.login]);

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h2>Status Input Nilai Kelas</h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <AlertComponent color={"danger"} message={errors.message} />
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <button
              className={`float-end btn ${
                comboAcademic ? "btn-danger" : "btn-warning"
              }`}
              onClick={() => setComboAcademic(!comboAcademic)}
            >
              {comboAcademic ? "CLOSE" : "SETEL ULANG STATUS TAHUN AJARAN INI"}
            </button>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            {comboAcademic ? <StatusScoreAcademicCombo /> : null}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {classrooms.length > 0 ? (
              <AdminStatusClassroomListComponent
                classrooms={classrooms}
                status={status}
                getStatus={(e) => getStatus(e)}
                statusRefresh={() => statusRefresh()}
              />
            ) : (
              <SmallLoadingComponent />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminStatusClassroomListContainer;
