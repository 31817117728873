import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";

import Swal from "sweetalert2";
import SmallLoadingComponent from "../components/SmallLoadingComponent";

const ReportArchiveClassroomListComponent = ({ archives, setErrors }) => {
  const recapExport = async (
    classroom_id,
    academic_id,
    period_id,
    classname,
    academicname
  ) => {
    try {
      const params = classroom_id + "/" + academic_id + "/" + period_id;
      let response = await axios.get("classroom/recap/" + params + "/export", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename =
          "Ledger Kelas " + classname + "-" + academicname + "-recaps";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  return (
    <div className="card mb-5">
      <div className="card-header">
        <h2>Laporan Nilai Wali Kelas</h2>
      </div>
      {archives ? (
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nama Kelas</th>
                <th colSpan={2} className="text-center" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {archives.homerooms.map((report, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{report.classroom.name}</td>
                  <td className="text-center">
                    {report.academic.periods.map((period, index) => (
                      <Fragment key={index}>
                        {report.classroom.group_name === period.group_name ? (
                          <button
                            className="btn btn-warning mx-2"
                            onClick={() =>
                              recapExport(
                                report.classroom.id,
                                report.academic_id,
                                period.id,
                                report.classroom.name,
                                report.academic.name
                              )
                            }
                          >
                            Ledger Nilai Semester {period.name}
                          </button>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ))}
                  </td>
                  <td className="text-center">
                    <Link
                      to={
                        "/reports/" +
                        report.classroom.id +
                        "/" +
                        report.academic_id +
                        "/detail"
                      }
                    >
                      <button className="btn btn-primary">DETAIL</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default ReportArchiveClassroomListComponent;
