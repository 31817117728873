import axios from "axios";
import React from "react";
import { useRecoilState } from "recoil";
import { authenticated } from "../store";
import NavbarAdministratorComponent from "./NavbarAdministratorComponent";
import NavbarTeacherComponent from "./NavbarTeacherComponent";

const SideNavbarComponent = ({ show, hideSideNav }) => {
  const [auth, setAuth] = useRecoilState(authenticated);

  const handleLogout = async () => {
    try {
      let response = await axios.get("/logout", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        sessionStorage.setItem("lastpath", "/dashboards");
        sessionStorage.removeItem("token");
        setAuth({ login: false, user: [] });
      }

      // console.log(response.data);
    } catch (e) {
      setAuth({ login: false, user: [] });

      // console.log(e.response.data);
    }
  };

  return (
    <div className={`side-navbar ${show ? "show" : ""}`}>
      {auth.user.role === "administrator" ? (
        <NavbarAdministratorComponent
          outAction={() => handleLogout()}
          hideNav={() => hideSideNav()}
        />
      ) : (
        <NavbarTeacherComponent
          outAction={() => handleLogout()}
          hideNav={() => hideSideNav()}
        />
      )}
    </div>
  );
};

export default SideNavbarComponent;
