import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import AlertComponent from "../../components/AlertComponent";
import SmallLoadingComponent from "../../components/SmallLoadingComponent";
import { authenticated } from "../../store";
import ImportSuratClassroom from "../surat/ImportSuratClassroom";
import KemuhInputNilaiComponent from "./KemuhInputNilaiComponent";

const KemuhInputNilaiContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [classroom, setClassroom] = useState(false);
  const [students, setStudents] = useState([]);
  const [errors, setErrors] = useState([]);
  const { classroom_id, period_id } = useParams();
  const navigate = useNavigate();
  const records = {
    classroom_id,
    academic_id: classroom.academic_id,
    period_id,
    classroom_name: classroom.name,
    students,
  };

  const getData = async () => {
    try {
      setLoading(true);
      let response = await axios.get(
        `kemuhs/classroom/${classroom_id}/period/${period_id}/create`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setClassroom(response.data.classroom);
      setStudents(response.data.students);
      setLoading(false);
      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);

      // console.log(e.response.data);
    }
  };

  const updateKemuh = async () => {
    try {
      let response = await axios.put("kemuhs/nilai/update", records, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });

        setErrors([]);
      }
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: e.response.data.message,
        showConfirmButton: true,
      });
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }

    var mounted = true;
    if (mounted) {
      if (students.length === 0) {
        getData();
      }
    }

    return () => {
      mounted = false;
    };
  }, [auth.login, refresh]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Input Nilai Kemuhammadiyahan Reguler Kelas{" "}
          <span className="badge bg-success fs-5 text-wrap">
            {classroom?.name}
          </span>
        </h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="alert alert-danger mb-5">{errors.message}</div>
          ) : (
            ""
          )}
        </div>
        <div className="row mb-5">
          <div className="col-4">
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Kelas:</strong>
                <br />
                <span className="badge bg-success fs-6 text-wrap">
                  {classroom?.name}
                </span>
              </li>
              <li className="list-group-item">
                <strong>Tahun Ajaran:</strong>
                <br />
                <span className="badge bg-success fs-6 text-wrap">
                  {classroom?.academic?.name}
                </span>
              </li>
              <li className="list-group-item">
                <span className="badge bg-success fs-6 text-wrap">
                  Semester {classroom?.academic?.period?.name}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-4 offset-8">
            <label className="mb-2">IMPORT NILAI</label>
            <ImportSuratClassroom
              url={`imports/kemuh/nilai`}
              setCollection={(data) => setStudents(data)}
            />
          </div>
        </div>
        <div className="row">
          {students.length > 0 ? (
            <KemuhInputNilaiComponent
              students={students}
              updateStudent={(update) => setStudents(update)}
              refresh={() => setRefresh(!refresh)}
              updateKemuh={() => updateKemuh()}
            />
          ) : (
            <Fragment>
              {loading ? (
                <SmallLoadingComponent />
              ) : (
                <AlertComponent color={"danger"} message="Tidak ada siswa" />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default KemuhInputNilaiContainer;
