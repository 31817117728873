import axios from "axios";
import Swal from "sweetalert2";

export const newTabPdf = async (params) => {
  try {
    let response = await axios.get(`${params}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        Accept: "application/octet-stream",
      },
      responseType: "blob",
    });
    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const fileUrl = URL.createObjectURL(blob);
      const w = window.open(fileUrl, "_blank");
      w && w.focus();
    }
    return;
    // console.log(response.data);
  } catch (e) {
    const message = e.response.data.text();
    message.then((value) => {
      const content = JSON.parse(value);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: content.message,
        showConfirmButton: true,
      });
    });
  }
};

export const templateExport = async (url, params, filename) => {
  try {
    let response = await axios.get(`${url}?${params}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        Accept: "application/octet-stream",
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}.xlsx`;
      link.click();
    }
  } catch (e) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: e.response.data.message,
      showConfirmButton: true,
    });

    // console.log(e.response.data);
  }
};
