import React from "react";
import LoginFormComponent from "../components/LoginFormComponent";

const LoginFormContainer = () => {
  return (
    <div className="container">
      <div className="row mb-2 justify-content-md-center">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 bg-light px-4">
          <LoginFormComponent />
        </div>
        <div
          className="col-xs-12 col-sm-6 col-md-6 col-lg-4 bg-light bg-login-cover"
          style={{
            backgroundImage: `url(/cover-tif-login-scale.jpg)`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default LoginFormContainer;
