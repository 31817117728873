import React from "react";
import { useRecoilValue } from "recoil";
import { authenticated } from "../store";

const NavbarComponent = ({ actionSideNav }) => {
  const auth = useRecoilValue(authenticated);

  return (
    <div className="container-fluid bg-navbar">
      <div className="row">
        <div className="col px-4">
          <span className="navbar-brand fs-6">TIF SCHOOL REPORT</span>
        </div>
        <div className="col text-center">
          {auth.login ? <b>{auth?.user.name}</b> : null}
        </div>
        <div className="col pe-4">
          <i
            className="bi bi-grid-3x3-gap-fill fs-3 pointer float-end"
            role="menu"
            aria-label="Menu"
            onClick={() => actionSideNav()}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default NavbarComponent;
