export const ismubaKeterangan = (nilai) => {
  const rules = [
    {
      rule: `${nilai} >= 90 && ${nilai} <= 100`,
      name: "Mumtaz",
    },
    {
      rule: `${nilai} >= 80 && ${nilai} <= 89`,
      name: "Jayyid Jiddan",
    },
    {
      rule: `${nilai} >= 70 && ${nilai} <= 79`,
      name: "Jayyid",
    },
    {
      rule: `${nilai} >= 60 && ${nilai} <= 69`,
      name: "Maqbul",
    },
    {
      rule: `${nilai} >= 0 && ${nilai} <= 59`,
      name: "Mardud",
    },
  ];

  const evaluate = (c) => Function(`return ${c}`)();
  let predicate = "";
  rules.map((row) => {
    if (evaluate(row.rule)) {
      predicate = row.name;
    }
  });

  return predicate;
};
