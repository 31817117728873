import React, { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

import { getData, postData } from "../../utils/ClassroomFunct";

const CopySuratModalComponent = ({ toClassrooms }) => {
  const [show, setShow] = useState(false);
  const [academics, setAcademics] = useState([]);
  const [records, setRecords] = useState({});
  const [fromClassrooms, setFromClassrooms] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [errors, setErrors] = useState([]);

  const showModal = () => {
    setShow(true);
  };

  const getAcademics = async () => {
    const academics = await getData("copies/surats");
    if (academics.status === 200) {
      setAcademics(academics.data);
    }
  };

  const handleCombo = (e) => {
    const change = { ...records };
    change[e.target.name] = e.target.value;
    setRecords(change);
  };

  const getFromClassrooms = async (e) => {
    const academic_id = e.target.value;
    const classrooms = await getData(
      `copies/surats?academic_id=${academic_id}`
    );
    if (classrooms.status === 200) {
      setFromClassrooms(classrooms.data);
      handleCombo(e);
    }
  };

  const getPeriods = async (e) => {
    const classroom_id = e.target.value;
    const periods = await getData(`copies/surats?classroom_id=${classroom_id}`);
    if (periods.status === 200) {
      setPeriods(periods.data);
      handleCombo(e);
    }
  };

  const handleCopy = async () => {
    const send = await postData("copies/surats", records);
    if (send.status === 200) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: send.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: send.data.message,
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    if (academics.length === 0) getAcademics();
  }, []);

  return (
    <>
      <Button
        variant="success"
        className="float-end"
        onClick={() => showModal()}
      >
        COPY SURAT
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        fullscreen={true}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            COPY SURAT
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {errors.message ? (
              <div className="alert alert-warning">{errors.message}</div>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <label className="mb-2" htmlFor="academic_id">
                Tahun Ajaran
              </label>
              <select
                className="form-select"
                name="academic_id"
                id="academic_id"
                onChange={(e) => getFromClassrooms(e)}
              >
                <option value="">===Pilih Tahun Ajaran===</option>
                {academics.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 mb-4">
              <label className="mb-2" htmlFor="fromclassroom_id">
                Kelas Asal
              </label>
              <select
                className="form-select"
                name="fromclassroom_id"
                id="fromclassroom_id"
                onChange={(e) => getPeriods(e)}
              >
                <option value="">===Pilih Kelas Asal===</option>
                {fromClassrooms.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 mb-4">
              <label className="mb-2" htmlFor="period_id">
                Semester
              </label>
              <select
                className="form-select"
                name="period_id"
                id="period_id"
                onChange={(e) => handleCombo(e)}
              >
                <option value="">===Pilih Semester===</option>
                {periods.map((row, index) => (
                  <option key={index} value={row.id}>
                    Semester {row.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 mb-4">
              <label className="mb-2" htmlFor="toclassroom_id">
                Kelas Tujuan
              </label>
              <select
                className="form-select"
                name="toclassroom_id"
                id="toclassroom_id"
                onChange={(e) => handleCombo(e)}
              >
                <option value="">===Pilih Kelas Tujuan===</option>
                {toClassrooms.map((classroom, index) => (
                  <option key={index} value={classroom.classroom_id}>
                    {classroom.classroom_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 mb-4">
              <button
                className="btn btn-success float-end"
                onClick={() => handleCopy()}
              >
                COPY
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CopySuratModalComponent;
