import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SmallLoadingComponent from "../../components/SmallLoadingComponent";

const AddKemuhTeacherFormComponent = ({ classroom, updateData }) => {
  const [teachers, setTeachers] = useState("");
  const [name, setName] = useState("");
  const [errors, setErrors] = useState([]);

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/teachers?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setTeachers(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const addTeachers = async (item) => {
    try {
      let response = await axios.post(
        "kemuhs",
        {
          user_id: item.id,
          classroom_id: classroom.id,
          academic_id: classroom.academic.id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        updateData();
        setName("");

        return true;
      }

      // console.log(response.data);
    } catch (e) {
      if (e.response.status === 422) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: e.response.data.message,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Your work not save",
          showConfirmButton: true,
        });
      }

      setErrors(e.response.data);

      return false;
      // console.log(e.response.data);
    }
  };

  const nameSearch = (name) => {
    setName(name);
    const src = "src=" + name;
    getData(src);
  };

  const delTeacher = async (id) => {
    try {
      let response = await axios.delete("kemuhs/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });

        updateData();
        return true;
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Your work not deleted",
        showConfirmButton: true,
      });

      setErrors(e.response.data);

      return false;
      // console.log(e.response.data);
    }
  };

  return (
    <form>
      <div className="row mb-4">
        <div className="col">
          <label htmlFor="nama" className="form-label">
            Nama Guru
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => nameSearch(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
          <ul className="list-group suggest">
            {name && teachers
              ? teachers.data.map((row, index) => (
                  <li
                    key={index}
                    className="list-group-item"
                    onClick={() => addTeachers(row)}
                  >
                    {row.name} - {row.nip}
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col table-responsive">
          {classroom ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">NBM</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {classroom.kemuh ? (
                <tbody>
                  <tr>
                    <th>1</th>
                    <td>{classroom?.kemuh?.user?.nip}</td>
                    <td>{classroom?.kemuh?.user?.name}</td>
                    <td className="text-center">
                      <button
                        type="button"
                        onClick={() => delTeacher(classroom?.kemuh?.id)}
                        className="btn btn-danger"
                      >
                        X
                      </button>
                    </td>
                  </tr>
                </tbody>
              ) : (
                ""
              )}
            </table>
          ) : (
            <SmallLoadingComponent />
          )}
        </div>
      </div>

      <Link to="/classrooms">
        <button className="btn btn-warning mx-4 float-end mt-5">BACK</button>
      </Link>
    </form>
  );
};

export default AddKemuhTeacherFormComponent;
