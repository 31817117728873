import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import StudentFormComponent from "../components/StudentFormComponent";
import StundentImportFormComponent from "../components/StundentImportFormComponent";
import { authenticated } from "../store";
import DuplicateStudent from "../students/DuplicateStudent";
import { templateExport } from "../utils/GetFileFunct";

const StudentFormContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();

  const downloadFormat = async () => {
    await templateExport(
      "students/import/template",
      "",
      "template-import-siswa"
    );
    return;
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/", { replace: true });
    }
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Input Siswa</h2>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <DuplicateStudent duplicates={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row row-cols-2">
              <div className="col">
                <button
                  className="btn btn-warning"
                  onClick={() => downloadFormat()}
                >
                  Contoh Import Excel
                </button>
              </div>
              <div className="col">
                <StundentImportFormComponent setErrors={(e) => setErrors(e)} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <StudentFormComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentFormContainer;
