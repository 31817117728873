import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import StudentOutNoteEditFormComponent from "../components/StudentOutNoteEditFormComponent";
import StudentOutNoteFormComponent from "../components/StudentOutNoteFormComponent";
import { authenticated } from "../store";

const StudentOutNoteEditFormContainter = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [student, setStudent] = useState(false);
  const [errors, setErrors] = useState([]);
  const { id } = useParams();
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/out/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setStudent(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    getData();
  }, []);

  return (
    <div className="row card mb-4">
      <div className="card-header">
        <h2>
          Edit Keterangan Keluar Siswa{" "}
          <span className="badge bg-success">{student?.name}</span>
        </h2>
      </div>
      <div className="card-body">
        {student ? (
          <StudentOutNoteEditFormComponent student={student} />
        ) : (
          <SmallLoadingComponent />
        )}
      </div>
    </div>
  );
};

export default StudentOutNoteEditFormContainter;
