import React from "react";

const AlertComponent = ({ color, message }) => {
  return (
    <div className="col">
      <div className={`alert alert-${color} text-center fw-bold`}>
        {message}
      </div>
    </div>
  );
};

export default AlertComponent;
