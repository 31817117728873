import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const StudentInNoteEditFormComponent = (props) => {
  const [nis, setNis] = useState("");
  const [school_name, setSchool_name] = useState("");
  const [in_school_date, setIn_school_date] = useState("");
  const [in_classroom, setIn_classroom] = useState("");
  const [academic_year, setAcademic_year] = useState("");
  const [document_date, setDocument_date] = useState("");
  const [headmaster_name, setHeadmaster_name] = useState("");
  const [headmaster_nip, setHeadmaster_nip] = useState("");
  const [errors, setErrors] = useState("");
  const redirect = useNavigate();
  const { id } = useParams();
  const record = {
    id,
    nis,
    school_name,
    in_school_date,
    in_classroom,
    academic_year,
    document_date,
    headmaster_name,
    headmaster_nip,
  };

  const updateData = async () => {
    try {
      let response = await axios.put("/in/" + id, record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (props.student) {
      setNis(props.student.nis);
      setSchool_name(props.student.school_name);
      setIn_school_date(props.student.in_school_date);
      setIn_classroom(props.student.in_classroom);
      setAcademic_year(props.student.academic_year);
      setDocument_date(props.student.document_date);
      setHeadmaster_name(props.student.headmaster_name);
      setHeadmaster_nip(props.student.headmaster_nip);
    }
  }, []);

  return (
    <div className="row">
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="nis" className="form-label">
          NIS
        </label>
        <input
          className={`form-control mb-1 ${errors.nis ? "is-invalid" : ""}`}
          type="text"
          placeholder="NIS"
          id="nis"
          value={nis}
          onChange={(e) => setNis(e.target.value)}
        />
        {errors.nis ? (
          <div className="invalid-feedback">{errors.nis[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="school_name" className="form-label">
          Nama Sekolah
        </label>
        <input
          className={`form-control mb-1 ${
            errors.school_name ? "is-invalid" : ""
          }`}
          type="text"
          placeholder="Nama Sekolah"
          id="school_name"
          value={school_name}
          onChange={(e) => setSchool_name(e.target.value)}
        />
        {errors.school_name ? (
          <div className="invalid-feedback">{errors.school_name[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="in_school_date" className="form-label">
          Tanggal
        </label>
        <input
          className={`form-control mb-1 ${
            errors.in_school_date ? "is-invalid" : ""
          }`}
          type="date"
          placeholder="Tanggal"
          id="in_school_date"
          value={in_school_date}
          onChange={(e) => setIn_school_date(e.target.value)}
        />
        {errors.in_school_date ? (
          <div className="invalid-feedback">{errors.in_school_date[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="in_classroom" className="form-label">
          Di Kelas
        </label>
        <input
          className={`form-control mb-1 ${
            errors.in_classroom ? "is-invalid" : ""
          }`}
          type="text"
          placeholder="Di Kelas"
          id="in_classroom"
          value={in_classroom}
          onChange={(e) => setIn_classroom(e.target.value)}
        />
        {errors.in_classroom ? (
          <div className="invalid-feedback">{errors.in_classroom[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="academic_year" className="form-label">
          Tahun Pelajaran
        </label>
        <input
          className={`form-control mb-1 ${
            errors.academic_year ? "is-invalid" : ""
          }`}
          type="text"
          placeholder="Tahun Pelajaran"
          id="academic_year"
          value={academic_year}
          onChange={(e) => setAcademic_year(e.target.value)}
        />
        {errors.academic_year ? (
          <div className="invalid-feedback">{errors.academic_year[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="document_date" className="form-label">
          Tanggal TTD Kepala Sekolah
        </label>
        <input
          className={`form-control mb-1 ${
            errors.document_date ? "is-invalid" : ""
          }`}
          type="date"
          placeholder="Tanggal TTD Kepala Sekolah"
          id="document_date"
          value={document_date}
          onChange={(e) => setDocument_date(e.target.value)}
        />
        {errors.document_date ? (
          <div className="invalid-feedback">{errors.document_date[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="headmaster_name" className="form-label">
          Nama Kepala Sekolah
        </label>
        <input
          className={`form-control mb-1 ${
            errors.headmaster_name ? "is-invalid" : ""
          }`}
          type="text"
          placeholder="Nama Kepala Sekolah"
          id="headmaster_name"
          value={headmaster_name}
          onChange={(e) => setHeadmaster_name(e.target.value)}
        />
        {errors.headmaster_name ? (
          <div className="invalid-feedback">{errors.headmaster_name[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="headmaster_nip" className="form-label">
          NIP / NBM
        </label>
        <input
          className={`form-control mb-1 ${
            errors.headmaster_nip ? "is-invalid" : ""
          }`}
          type="text"
          placeholder="Nama Kepala Sekolah"
          id="headmaster_nip"
          value={headmaster_nip}
          onChange={(e) => setHeadmaster_nip(e.target.value)}
        />
        {errors.headmaster_nip ? (
          <div className="invalid-feedback">{errors.headmaster_nip[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-12 mt-4">
        <button
          type="button"
          className="btn btn-primary float-end ms-5"
          onClick={() => updateData()}
        >
          UPDATE
        </button>
        <button
          type="button"
          className="btn btn-warning float-end"
          onClick={() => redirect(-1)}
        >
          BACK
        </button>
      </div>
    </div>
  );
};

export default StudentInNoteEditFormComponent;
