import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authenticated, dashboardlist, statusadminlist } from "../store";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../components/LoadingComponent";
import axios from "axios";
import AcademicStatusComponent from "../components/AcademicStatusComponent";
import ClassroomStatusComponent from "../components/ClassroomStatusComponent";
// import CompetenceStatusComponent from "../components/CompetenceStatusComponent";
import { Fragment } from "react/cjs/react.production.min";
import AdminAcademicStatusComponent from "../components/AdminAcademicStatusComponent";
import AdminClassroomStatusComponent from "../components/AdminClassroomStatusComponent";
import AdminTeacherStatusComponent from "../components/AdminTeacherStatusComponent";
import AdminStudentStatusComponent from "../components/AdminStudentStatusComponent";
import AdminLessonStatusComponent from "../components/AdminLessonStatusComponent";
import NoticeStatusComponent from "../components/NoticeStatusComponent";
import AlertComponent from "../components/AlertComponent";

const DashboardContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [dashboards, setDashboards] = useRecoilState(dashboardlist);
  const setStatusadmins = useSetRecoilState(statusadminlist);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("dashboards", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setDashboards(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const getAdminData = async () => {
    try {
      let response = await axios.get("admin/dashsboard", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setStatusadmins(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    var mounted = true;
    if (mounted) {
      if (auth.user.role === "guru") {
        getData();
      } else {
        getAdminData();
      }
    }
    return () => {
      mounted = false;
    };
  }, [auth.login]);

  return (
    <div>
      {dashboards ? (
        <div className="row row-cols-1">
          {auth.user.role === "guru" ? (
            <Fragment>
              <NoticeStatusComponent />
              <AcademicStatusComponent />
              <ClassroomStatusComponent />
              {/* <div className="row">
              <CompetenceStatusComponent />
            </div> */}
            </Fragment>
          ) : (
            <Fragment>
              <AdminAcademicStatusComponent />
              <AdminClassroomStatusComponent />
              <div className="col">
                <div className="row row-cols-2">
                  <AdminTeacherStatusComponent />
                  <AdminStudentStatusComponent />
                  <AdminLessonStatusComponent />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      ) : (
        <Fragment>
          {errors.message ? (
            <AlertComponent color={"danger"} message={errors.message} />
          ) : (
            <LoadingComponent />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default DashboardContainer;
