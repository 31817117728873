import React from "react";
import { useRecoilState } from "recoil";
import { dashboardlist } from "../store";

const AcademicStatusComponent = () => {
  const [dashboards] = useRecoilState(dashboardlist);

  return (
    <div className="col mb-4">
      <div className="card">
        <div className="card-header">
          <h4>
            <i className="bi bi-calendar-check-fill"></i> Tahun AJaran
          </h4>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              {dashboards?.academics?.map((row, index) => (
                <tr key={index}>
                  <th>{row.name}</th>
                  <th>
                    {row.periods.map((row, index) => (
                      <span key={index} className="badge bg-success me-2">
                        Semester {row.name}
                      </span>
                    ))}
                  </th>
                </tr>
              ))}
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AcademicStatusComponent;
