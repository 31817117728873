import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import AdministratorListComponent from "../components/AdministratorListComponent";
import {
  administratordetail,
  administratorlist,
  authenticated,
} from "../store";

const AdministratorListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [administrators, setAdministrators] = useRecoilState(administratorlist);
  const [administrator, setAdministrator] = useRecoilState(administratordetail);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/administrators?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAdministrators(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    setAdministrator(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    }
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header mb-4">
        <h2>List Administrator</h2>
      </div>
      <div className="card-body">
        <AdministratorListComponent updateData={(option) => getData(option)} />
      </div>
    </div>
  );
};

export default AdministratorListContainer;
