import axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import AdminLedgerClassroomListComponent from "../components/AdminLedgerClassroomListComponent";
import AlertComponent from "../components/AlertComponent";
import { authenticated, waiting } from "../store";

const AdminLedgerClassroomListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const setLoading = useSetRecoilState(waiting);
  const [academics, setAcademics] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/academics?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAcademics(response.data.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const getClassrooms = async (academic_id) => {
    try {
      setLoading(true);
      let response = await axios.get("/ledger/classrooms/" + academic_id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setClassrooms(response.data);
        setLoading(false);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    if (academics.length === 0) getData();
  }, [auth.login]);

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h2>Ledger Kelas</h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.mesage ? (
            <AlertComponent color={"danger"} message={errors.mesage} />
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="row">
            <div className="col-12 mb-5">
              <label className="mb-2" htmlFor="academic_id">
                Tahun Ajaran
              </label>
              <select
                name="academic_id"
                id="academic_id"
                className="form-select"
                onChange={(e) => getClassrooms(e.target.value)}
              >
                <option value="">===Pilih Tahun Ajaran===</option>
                {academics.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {classrooms.length > 0 ? (
          <div className="row">
            <AdminLedgerClassroomListComponent
              academics={academics}
              classrooms={classrooms}
              setErrors={(mesage) => setErrors(mesage)}
            />
          </div>
        ) : (
          <AlertComponent color="warning" message={"Pilih Tahun Ajaran"} />
        )}
      </div>
    </div>
  );
};

export default AdminLedgerClassroomListContainer;
