import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import { disablemenu } from "../store";

const CompetenceDetailImportComponent = ({ setCompetence, setStudents }) => {
  const [progress, setProgress] = useState(false);
  const setDisable = useSetRecoilState(disablemenu);
  const [selected_file, setSelected_file] = useState(false);
  const { id } = useParams();

  const handleOnchange = (event) => {
    setSelected_file(event.target.files[0]);
  };

  const onFileUpload = async () => {
    try {
      let formData = new FormData();
      formData.append("file_import", selected_file, selected_file.name);

      let response = await axios.post(
        "competences/tasks/import?id=" + id,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // console.log(percentCompleted);
            setProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "File upload success, Please push the UPDATE button!",
          showConfirmButton: true,
          // timer: 1500,
        });
        setCompetence(response.data.competence);
        setStudents(response.data.nilais);
        setDisable(true);
      }
    } catch (e) {
      // setErrors(e.response.data);
      Swal.fire({
        position: "top",
        icon: "error",
        title: e.response.data.message,
        showConfirmButton: true,
      });
      // console.log(e.response.data);
    }
  };

  return (
    <div className="row row-cols-1">
      <div className="col">
        <label htmlFor="taks-file" className="form-label">
          Import Tugas
        </label>
        <input
          className="form-control mb-3"
          type="file"
          id="taks-file"
          onChange={(event) => handleOnchange(event)}
        />
        {selected_file ? (
          <button
            className="btn btn-primary float-end"
            onClick={() => onFileUpload()}
          >
            Upload
          </button>
        ) : null}
      </div>
      <div className="col mt-3 mb-4">
        {progress ? (
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CompetenceDetailImportComponent;
