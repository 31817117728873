import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import SmallLoadingComponent from "../../components/SmallLoadingComponent";
import { authenticated } from "../../store";
import KemuhClassroomListComponent from "./KemuhClassroomListComponent";

const KemuhClassroomListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [classrooms, setClassroms] = useState([]);
  const [classfilter, setClassfilter] = useState([]);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/kemuhs", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassroms(response.data);
      setClassfilter(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const classroomFilter = (e) => {
    const src = e.target.value;
    const filteredClassrooms = classrooms.filter((item) => {
      return (
        `${item.classroom_name}`.toLowerCase().search(src.toLowerCase()) !== -1
      );
    });

    setClassfilter(filteredClassrooms);
    // console.log(filteredClassrooms);
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    var mounted = true;
    if (mounted) getData();

    return () => {
      mounted = false;
    };
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Kemuhammadiyahan Kelas Reguler</h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="alert alert-warning">{errors.message}</div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-md-4 col-xs-12">
            <input
              type="text"
              className="form-control float-end mb-4 col-2"
              placeholder="Cari Nama Kelas..."
              onChange={(e) => classroomFilter(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {classfilter?.length > 0 ? (
              <KemuhClassroomListComponent
                classrooms={classfilter}
                refreshData={() => getData()}
              />
            ) : (
              <SmallLoadingComponent />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KemuhClassroomListContainer;
