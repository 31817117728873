import axios from "axios";
import React, { Fragment } from "react";
import Swal from "sweetalert2";

const IsmubaLedgerClassroomComponent = ({ classrooms }) => {
  const recapExport = async (
    classroom_id,
    academic_id,
    period_id,
    classname,
    academicname
  ) => {
    try {
      const params = `classroom=${classroom_id}&academic=${academic_id}&period=${period_id}`;
      let response = await axios.get(`exports/reguler/ledger?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename = "Ledger Reguler " + classname + "-" + academicname;
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Tidak dapat export",
        footer: "",
      });

      // console.log(e.response.data);
    }
  };

  return (
    <div className="col">
      <table className="table table-bordered">
        <thead>
          <tr className="text-center">
            <th>#</th>
            <th>NAMA KELAS</th>
            <th>SEMESTER</th>
          </tr>
        </thead>
        <tbody>
          {classrooms.map((row, index) => (
            <tr className="text-center" key={index}>
              <td>{index + 1}</td>
              <td>
                <span className="badge bg-success fs-6">{row.name}</span>
              </td>
              <td>
                {row.academic.periods.map((period, index) => (
                  <Fragment key={index}>
                    {row.group_name === period.group_name ? (
                      <button
                        className="btn btn-primary mx-2"
                        onClick={() =>
                          recapExport(
                            row.id,
                            row.academic_id,
                            period.id,
                            row.name,
                            row.academic.nickname
                          )
                        }
                      >
                        Semester {period.name}
                      </button>
                    ) : null}
                  </Fragment>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IsmubaLedgerClassroomComponent;
