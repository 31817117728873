import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import AttendanceListComponent from "../components/AttendanceListComponent";
import LoadingComponent from "../components/LoadingComponent";
import {
  attendancehow,
  attendancelist,
  authenticated,
  studentlist,
  waiting,
} from "../store";

const AttendanceListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [loading, setLoading] = useRecoilState(waiting);
  const [students, setStudents] = useRecoilState(studentlist);
  const [attendances, setAttendances] = useRecoilState(attendancelist);
  const [attendance, setAttendance] = useRecoilState(attendancehow);
  const navigate = useNavigate();

  useEffect(() => {
    setStudents(false);
    setAttendance(false);
    setAttendances(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }
  }, [auth.login]);

  return (
    <div className="card">
      {loading ? <LoadingComponent /> : <AttendanceListComponent />}
    </div>
  );
};

export default AttendanceListContainer;
