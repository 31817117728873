import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AlertComponent from "../components/AlertComponent";
import { authenticated } from "../store";
import StudentImportComponent from "./StudentImportComponent";

const StudentImportUpdate = () => {
  const auth = useRecoilValue(authenticated);
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();

  useEffect(() => {
    if (!auth.login) {
      redirect("/", { replace: true });
    }
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>UPDATE SISWA EXCEL</h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <AlertComponent color={"danger"} message={errors.message} />
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <StudentImportComponent
            title={"UPDATE NISN"}
            uploadUrl="students/import/update"
            setErrors={(e) => setErrors(e)}
            errors={errors}
          />
        </div>
        <div className="row">
          <div className="col">
            <button
              type="button"
              className="btn btn-warning float-end"
              onClick={() => redirect(-1)}
            >
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentImportUpdate;
