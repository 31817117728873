import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import SmallLoadingComponent from "../components/SmallLoadingComponent";

const AttendanceArchiveListComponent = ({ archives }) => {
  return (
    <div className="card mb-5">
      <div className="card-header">
        <h2>Absensi Kelas</h2>
      </div>
      {archives ? (
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nama Kelas</th>
                <th colSpan={1} className="text-center" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {archives.attendances.map((row, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{row.classroom.name}</td>
                  <td className="text-center">
                    {row.academic.periods.map((period, index) => (
                      <Fragment key={index}>
                        {row.classroom.group_name === period.group_name ? (
                          <Link
                            to={
                              "/attendances/" +
                              row.id +
                              "/" +
                              period.id +
                              "/detail"
                            }
                          >
                            <button
                              key={index}
                              className="btn btn-warning mx-2"
                            >
                              Semester {period.name}
                            </button>
                          </Link>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AttendanceArchiveListComponent;
