import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const StudentOutNoteFormComponent = (props) => {
  const [out_date, setOut_date] = useState("");
  const [out_classroom, setOut_classroom] = useState("");
  const [out_description, setOut_description] = useState("");
  const [document_date, setDocument_date] = useState("");
  const [headmaster_name, setHeadmaster_name] = useState("");
  const [headmaster_nip, setHeadmaster_nip] = useState("");
  const [errors, setErrors] = useState("");
  const redirect = useNavigate();
  const { id } = useParams();
  const record = {
    id,
    out_date,
    out_classroom,
    out_description,
    document_date,
    headmaster_name,
    headmaster_nip,
  };

  const createData = async () => {
    try {
      let response = await axios.post("/out", record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setOut_date("");
        setOut_classroom("");
        setOut_description("");
        setDocument_date("");
        setHeadmaster_name("");
        setHeadmaster_nip("");
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
    }
  };

  return (
    <div className="row">
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="out_date" className="form-label">
          Tanggal
        </label>
        <input
          className={`form-control mb-1 ${errors.out_date ? "is-invalid" : ""}`}
          type="date"
          placeholder="Tanggal"
          id="out_date"
          value={out_date}
          onChange={(e) => setOut_date(e.target.value)}
        />
        {errors.out_date ? (
          <div className="invalid-feedback">{errors.out_date[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="out_classroom" className="form-label">
          Kelas yang ditinggalkan
        </label>
        <input
          className={`form-control mb-1 ${
            errors.out_classroom ? "is-invalid" : ""
          }`}
          type="text"
          placeholder="Kelas yang ditinggalkan"
          id="out_classroom"
          value={out_classroom}
          onChange={(e) => setOut_classroom(e.target.value)}
        />
        {errors.out_classroom ? (
          <div className="invalid-feedback">{errors.out_classroom[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-12 col-xs-12 mb-2">
        <label htmlFor="out_description" className="form-label">
          Sebab-sebab Keluar atau Atas Permintaan (Tertulis)
        </label>
        <textarea
          className={`form-control mb-1 ${
            errors.out_description ? "is-invalid" : ""
          }`}
          type="date"
          placeholder="Sebab-sebab Keluar atau Atas Permintaan (Tertulis)"
          id="out_description"
          value={out_description}
          onChange={(e) => setOut_description(e.target.value)}
          rows="5"
        ></textarea>
        {errors.out_description ? (
          <div className="invalid-feedback">{errors.out_description[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="document_date" className="form-label">
          Tanggal TTD Kepala Sekolah
        </label>
        <input
          className={`form-control mb-1 ${
            errors.document_date ? "is-invalid" : ""
          }`}
          type="date"
          placeholder="Tanggal TTD Kepala Sekolah"
          id="document_date"
          value={document_date}
          onChange={(e) => setDocument_date(e.target.value)}
        />
        {errors.document_date ? (
          <div className="invalid-feedback">{errors.document_date[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="headmaster_name" className="form-label">
          Nama Kepala Sekolah
        </label>
        <input
          className={`form-control mb-1 ${
            errors.headmaster_name ? "is-invalid" : ""
          }`}
          type="text"
          placeholder="Nama Kepala Sekolah"
          id="headmaster_name"
          value={headmaster_name}
          onChange={(e) => setHeadmaster_name(e.target.value)}
        />
        {errors.headmaster_name ? (
          <div className="invalid-feedback">{errors.headmaster_name[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-6 col-xs-12 mb-2">
        <label htmlFor="headmaster_nip" className="form-label">
          NIP / NBM
        </label>
        <input
          className={`form-control mb-1 ${
            errors.headmaster_nip ? "is-invalid" : ""
          }`}
          type="text"
          placeholder="Nama Kepala Sekolah"
          id="headmaster_nip"
          value={headmaster_nip}
          onChange={(e) => setHeadmaster_nip(e.target.value)}
        />
        {errors.headmaster_nip ? (
          <div className="invalid-feedback">{errors.headmaster_nip[0]}</div>
        ) : (
          ""
        )}
      </div>
      <div className="col-12 mt-4">
        <button
          type="button"
          className="btn btn-primary float-end ms-5"
          onClick={() => createData()}
        >
          SUBMIT
        </button>
        <button
          type="button"
          className="btn btn-warning float-end"
          onClick={() => redirect(-1)}
        >
          BACK
        </button>
      </div>
    </div>
  );
};

export default StudentOutNoteFormComponent;
