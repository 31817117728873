import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { homeroomscorelist } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const NoteClassroomListComponent = () => {
  const [homeroomscores, setHomeroomscores] = useRecoilState(homeroomscorelist);
  const [errors, setErrors] = useState("");

  const recapExport = async (
    classroom_id,
    academic_id,
    period_id,
    classname,
    academicname
  ) => {
    try {
      const params = classroom_id + "/" + academic_id + "/" + period_id;
      let response = await axios.get("classroom/recap/" + params + "/export", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename =
          "Ledger Kelas " + classname + "-" + academicname + "-recaps";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {}, []);

  return (
    <div>
      <div className="card-header">
        <h2>Input Catatan Siswa</h2>
      </div>
      {homeroomscores ? (
        <div className="card-body">
          <div className="col table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">KELAS</th>
                  <th colSpan={2} className="text-center">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {homeroomscores.map((report, index) => (
                  <tr key={index}>
                    <td scope="row">{index + 1}</td>
                    <td>
                      <span className="badge bg-success fs-6">
                        {report.classroom.name}
                      </span>
                    </td>
                    <td className="text-center">
                      {report.academic.periods.map((period, index) => (
                        <Fragment key={index}>
                          {report.classroom.group_name === period.group_name ? (
                            <Link
                              to={
                                "/homerooms/note/" +
                                report.classroom.id +
                                "/" +
                                report.academic_id +
                                "/" +
                                period.id +
                                "/create"
                              }
                            >
                              <button className="btn btn-primary me-2 fw-bold">
                                Semester {period.name}
                              </button>
                            </Link>
                          ) : (
                            ""
                          )}
                        </Fragment>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default NoteClassroomListComponent;
