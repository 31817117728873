import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CopySKSComponent from "./CopySKSComponent";
import SmallLoadingComponent from "./SmallLoadingComponent";

const AddSksFormComponent = ({ classroomdetail, skss, updateData }) => {
  const [lessons, setLessons] = useState("");
  const [periods, setPeriods] = useState(false);
  const [period_id, setPeriod_id] = useState("");
  const [lesson_id, setLesson_id] = useState("");
  const [sks, setSks] = useState("");
  const [errors, setErrors] = useState([]);
  const [show, setShow] = useState(false);
  const { id } = useParams();

  const addSks = async () => {
    try {
      let response = await axios.post(
        "sks",
        {
          classroom_id: id,
          academic_id: classroomdetail.classroom.academic_id,
          period_id,
          lesson_id,
          sks,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setLesson_id("");
        setPeriod_id("");
        setSks("");
        setErrors([]);

        updateData();
        return true;
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: e.response.data.message,
        showConfirmButton: true,
      });

      setErrors(e.response.data);

      return false;
      // console.log(e.response.data);
    }
  };

  const delSks = async (id) => {
    try {
      let response = await axios.delete("sks/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });

        setPeriods(false);
        updateData();
        return true;
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Your work not deleted",
        showConfirmButton: true,
      });

      setErrors(e.response.data);

      return false;
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (classroomdetail) {
      setLessons(classroomdetail.lessons);
      setPeriods(classroomdetail.periods);
    }
  }, [classroomdetail]);

  return (
    <form>
      <div className="row mb-4">
        <div className="col-md-4 col-xs-12 mb-2">
          <label htmlFor="lesson_id" className="form-label">
            Pelajaran
          </label>
          <select
            className={`form-select mb-1 ${
              errors.lesson_id ? "is-invalid" : ""
            }`}
            type="text"
            id="lesson_id"
            value={lesson_id}
            onChange={(e) => setLesson_id(e.target.value)}
          >
            <option value="">Pilih Pelajaran</option>
            {lessons
              ? lessons.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))
              : ""}
          </select>
          {errors.lesson_id ? (
            <div className="invalid-feedback">{errors.lesson_id[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-4 col-xs-12 mb-2">
          <label htmlFor="academic" className="form-label">
            Semester
          </label>
          {periods ? (
            <select
              className={`form-select mb-1 ${
                errors.period_id ? "is-invalid" : ""
              }`}
              type="text"
              id="period_id"
              value={period_id}
              onChange={(e) => setPeriod_id(e.target.value)}
            >
              <option value="">Pilih Semester</option>
              {periods.map((row, index) => (
                <option key={index} value={row.id}>
                  {row.name}
                </option>
              ))}
            </select>
          ) : (
            ""
          )}
          {errors.period_id ? (
            <div className="invalid-feedback">{errors.period_id[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-4 col-xs-12 mb-2">
          <label htmlFor="sks" className="form-label">
            SKS
          </label>
          <input
            className={`form-control mb-1 ${errors.sks ? "is-invalid" : ""}`}
            type="number"
            placeholder="SKS"
            id="sks"
            value={sks}
            onChange={(e) => setSks(e.target.value)}
          />
          {errors.sks ? (
            <div className="invalid-feedback">{errors.sks[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row">
        <CopySKSComponent
          show={show}
          type={"sks"}
          period_id={period_id}
          actionModal={() => setShow(!show)}
          refreshData={() => updateData()}
        />
      </div>
      <div className="row mb-4">
        <div className="col">
          {period_id !== "" ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => setShow(!show)}
            >
              COPY SKS
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-primary float-end"
            onClick={() => addSks()}
          >
            SUBMIT
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col table-responsive">
          {skss ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Kelas</th>
                  <th>Tahun Ajaran</th>
                  <th>Pelajaran</th>
                  <th>Semester</th>
                  <th>SKS</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {skss.map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{row.classroom}</td>
                    <td>{row.academic}</td>
                    <td>{row.lesson}</td>
                    <td>{row.period}</td>
                    <td>{row.sks}</td>
                    <td className="text-center">
                      <button
                        type="button"
                        onClick={() => delSks(row.id)}
                        className="btn btn-danger"
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <SmallLoadingComponent />
          )}
        </div>
      </div>

      <Link to="/classrooms">
        <button className="btn btn-warning mx-4 float-end mt-5">BACK</button>
      </Link>
    </form>
  );
};

export default AddSksFormComponent;
