import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import AttitudeListComponent from "../components/AttitudeListComponent";
import LoadingComponent from "../components/LoadingComponent";
import {
  academicthis,
  adminattitudeshow,
  authenticated,
  categorielist,
  skslist,
  waiting,
} from "../store";

const AttitudeListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [loading, setLoading] = useRecoilState(waiting);
  const [categories, setCategories] = useRecoilState(categorielist);
  const [adminattitude, setAdminattitude] = useRecoilState(adminattitudeshow);
  const navigate = useNavigate();

  useEffect(() => {
    setAdminattitude(false);
    setCategories(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }
  }, [auth.login]);

  return (
    <div className="card">
      {loading ? <LoadingComponent /> : <AttitudeListComponent />}
    </div>
  );
};

export default AttitudeListContainer;
