import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import AttitudeComponent from "../components/AttitudeComponent";
import LoadingComponent from "../components/LoadingComponent";
import {
  adminattitudelist,
  attitudelist,
  authenticated,
  studentlist,
} from "../store";

const AttitudeContainer = () => {
  const [errors, setErrors] = useState("");
  const [auth, setAuth] = useRecoilState(authenticated);
  const [attitudes, setAttitudes] = useRecoilState(attitudelist);
  const [adminattitudes, setAdminattitudes] = useRecoilState(adminattitudelist);
  const [students, setStudents] = useRecoilState(studentlist);

  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/attitudes", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setAttitudes(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    setStudents(false);
    setAdminattitudes(false);
    if (!auth.login) {
      redirect("/");
    }
    getData();
  }, [auth.login]);

  return (
    <div>
      {attitudes ? (
        attitudes.map((row, index) => (
          <div key={index} className="card mb-5">
            <div className="card-header">
              <h2>{row.categorie.name}</h2>
            </div>
            <div className="card-body">
              <div className="row row-cols-2">
                {row.classrooms.map((classroom, index) => (
                  <AttitudeComponent
                    key={index}
                    data={classroom}
                    categorie_id={row.categorie.id}
                    updateData={() => getData()}
                  />
                ))}
              </div>
            </div>
          </div>
        ))
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default AttitudeContainer;
