import axios from "axios";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const StatusScoreAcademicCombo = () => {
  const [academics, setAcademics] = useState([]);
  const [academic_id, setAcademic_id] = useState("");
  const [errors, setErrors] = useState([]);

  const statusAllRefresh = () => {
    if (academic_id === "") {
      setErrors(["Pilih tahun ajaran"]);
      return false;
    }
    Swal.fire({
      title: "Atur ulang status pada Tahun Ajaran ini ?",
      showCancelButton: true,
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("/status/academic/" + academic_id + "/refresh", {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire(response.data.message, "", "success");

              setErrors([]);
              //   getStatus(classroom_id);
            }
          });
      }
    });
  };

  const getAcademic = async () => {
    try {
      let response = await axios.get("/list/academic/on", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAcademics(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (academics.length === 0) {
      getAcademic();
    }
  }, []);

  return (
    <div className="card animate__animated animate__shakeY">
      <div className="card-header">
        <h2>Tahun AJaran</h2>
      </div>
      <div className="card-body">
        <div className="col-12 mb-4">
          <select
            name="academic_id"
            id="academic_id"
            className={`form-select ${errors[0] ? "is-invalid" : ""}`}
            onChange={(e) => setAcademic_id(e.target.value)}
          >
            <option value="">===Pilih Tahun Ajaran===</option>
            {academics.map((row, index) => (
              <option key={index} value={row.id}>
                {row.name}
              </option>
            ))}
          </select>
          {errors[0] ? <div className="invalid-feedback">{errors[0]}</div> : ""}
        </div>
        <div className="col-12">
          <button
            className="btn btn-success float-end"
            onClick={() => statusAllRefresh()}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusScoreAcademicCombo;
