import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import AddStudentClassroomFormComponent from "../components/AddStudentClassroomFormComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import StundentToClassImportFormComponent from "../components/StundentToClassImportFormComponent";
import { classroomshow, studentlist } from "../store";

const AddStudentClassroomFormContainer = () => {
  const [classroomdetail, setClassroomdetail] = useRecoilState(classroomshow);
  const [students, setStudents] = useRecoilState(studentlist);
  const [errors, setErrors] = useState("");
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/groups/student/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassroomdetail(response.data);
      setStudents(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="card">
      {classroomdetail ? (
        <Fragment>
          <div className="card-header">
            <h2>
              Add Siswa Ke Kelas{" "}
              <span className="badge bg-success fs-4">
                {classroomdetail.classroom.name}
              </span>{" "}
              <span className="badge bg-success fs-4 text-wrap">
                {classroomdetail.classroom.academic.name}
              </span>
            </h2>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <StundentToClassImportFormComponent
                  updateData={() => getData()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <AddStudentClassroomFormComponent
                  updateData={() => getData()}
                />
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AddStudentClassroomFormContainer;
