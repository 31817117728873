import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import AlertComponent from "../components/AlertComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated } from "../store";
import CompetenceScoreComponent from "./CompetenceScoreComponent";

const CompetenceScoreContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [competence, setCompetence] = useState([]);
  const [students, setStudents] = useState([]);
  const [errors, setErrors] = useState([]);
  const { id } = useParams();
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get(`/competences/${id}/archive`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setCompetence(response.data.competence);
        setStudents(response.data.students);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Nilai {competence?.name} Kelas{" "}
          <span className="badge bg-success fs-4 text-wrap">
            {competence?.classroom?.name}
          </span>
        </h2>
      </div>

      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="col mb-4">
              <AlertComponent color={"danger"} message={errors.message} />
            </div>
          ) : (
            ""
          )}
        </div>
        {competence ? (
          <div className="row">
            <CompetenceScoreComponent
              competence={competence}
              students={students}
              setStudents={(data) => setStudents(data)}
              setErrors={(e) => setErrors(e)}
            />
          </div>
        ) : (
          <SmallLoadingComponent />
        )}
      </div>
    </div>
  );
};

export default CompetenceScoreContainer;
