import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated, studentdetail, waiting } from "../store";
import StudentListComponent from "./StudentListComponent";

const StudentListContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [loading, setLoading] = useRecoilState(waiting);
  const [student, setStudent] = useRecoilState(studentdetail);
  const navigate = useNavigate();

  useEffect(() => {
    setStudent(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }
  }, [auth.login]);

  return (
    <div className="card">
      {loading ? <SmallLoadingComponent /> : <StudentListComponent />}
    </div>
  );
};

export default StudentListContainer;
