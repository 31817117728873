import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const CompetenceClassroomListComponent = ({
  classroomsteach,
  refreshData,
  setErrors,
}) => {
  const recapExport = async (id, classroom, academic) => {
    try {
      let response = await axios.get("lesson/" + id + "/recap", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename = "Kelas " + classroom + "-" + academic + "-recaps";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const changeStatus = async (classroomuser_id) => {
    try {
      let response = await axios.get(
        `status/classroomuser/${classroomuser_id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: response.data.message,
          showConfirmButton: true,
          footer: "",
        });

        refreshData();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        showConfirmButton: true,
        footer: "",
      });

      // console.log(e.response.data);
    }
  };

  return (
    <div className="col table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Kelas</th>
            <th className="text-center">Pelajaran</th>
            <th className="text-center">Tahun Ajaran</th>
            <th className="text-center" colSpan={3}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {classroomsteach.classroomusers.map((row, index) => (
            <tr key={index}>
              <td className="text-center">{index + 1}</td>
              <td className="text-center">
                <span className="badge bg-success fs-6">{row.classroom}</span>
              </td>
              <td>
                <span className="badge bg-secondary fs-6">{row.lesson}</span>
              </td>
              <td className="text-center">
                <span className="badge bg-warning text-black fs-6">
                  {row.academic}
                </span>
              </td>
              <td className="text-center">
                <Link to={"/competences/classroomuser/" + row.id}>
                  <button className="btn btn-primary">KOMPETENSI</button>
                </Link>
              </td>
              <td className="text-center">
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={() =>
                    recapExport(row.id, row.classroom, row.academic)
                  }
                >
                  RECAP
                </button>
              </td>
              <td className="text-center">
                {row.score_status === "selesai" ? (
                  <span className="badge bg-success fs-6">
                    NILAI DIKUMPULKAN
                  </span>
                ) : (
                  <button
                    type="button"
                    className="btn bg-purple text-white"
                    onClick={() => changeStatus(row.id)}
                  >
                    KUMPULKAN NILAI
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompetenceClassroomListComponent;
