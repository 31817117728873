import React from "react";
import AcademicFormComponent from "../components/AcademicFormComponent";

const AcademicFormContainer = () => {
  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Tahun Ajaran</h2>
      </div>
      <div className="card-body">
        <AcademicFormComponent />
      </div>
    </div>
  );
};

export default AcademicFormContainer;
