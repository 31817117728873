import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import CompetenceEditFormComponent from "../components/CompetenceEditFormComponent";
import LoadingComponent from "../components/LoadingComponent";
import { authenticated, competencedetail } from "../store";

const CompetenceEditFormContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [competence, setCompetence] = useRecoilState(competencedetail);
  const [errors, setErrors] = useState(false);
  const [messages, setMessages] = useState(false);
  const redirect = useNavigate();
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("competences/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setCompetence(response.data);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }
    getData();
  }, [auth.login]);
  return (
    <div className="card">
      <div className="card-header">
        <h2>Edit Kompetensi {competence?.detail?.name}</h2>
      </div>
      <div className="card-body">
        {competence ? (
          <CompetenceEditFormComponent />
        ) : (
          [
            messages ? (
              <h2 key={0}>{messages.data}</h2>
            ) : (
              <LoadingComponent key={1} />
            ),
          ]
        )}
      </div>
    </div>
  );
};

export default CompetenceEditFormContainer;
