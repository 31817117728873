import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const JoinClassroomComponent = (props) => {
  const [lessons, setLessons] = useState([]);
  const [lesson_id, setLesson_id] = useState("");
  const [errors, setErrors] = useState([]);
  const params = props.classroom_id + "/" + lesson_id;

  const getLessons = async () => {
    try {
      let response = await axios.get("/lessons", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setLessons(response.data.data);

      // console.log(response.data.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const actionClassroom = async (id) => {
    try {
      let response = await axios.get(
        "teacher/classrooms/" + params + "/" + props.action,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: true,
        });
        props.refreshData();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        showConfirmButton: true,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };
  useEffect(() => {
    if (lessons.length === 0) getLessons();
  }, []);

  return (
    <div className="card copy-modal">
      <div className="card-header">
        <h2>
          <span className="text-capitalize">{props.action}</span> Kelas{" "}
          <span className="badge bg-success fs-5">{props.classroom_name}</span>
          <span
            className="badge bg-danger float-end fs-6 close"
            onClick={() => props.close()}
          >
            X
          </span>
        </h2>
      </div>
      <div className="card-body">
        <div className="row row-cols-1">
          <div className="col mb-4">
            <label htmlFor="lesson_id" className="mb-2">
              Pelajaran
            </label>
            <select
              name="lesson_id"
              id="lesson_id"
              className="form-select"
              value={lesson_id}
              onChange={(e) => setLesson_id(e.target.value)}
            >
              <option value="">===Pilih Pelajaran===</option>
              {lessons.map((row, index) => (
                <option key={index} value={row.id}>
                  {row.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <button
              className="btn btn-primary float-end"
              onClick={() => actionClassroom()}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinClassroomComponent;
