import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { authenticated } from "../store";
import { useRecoilState } from "recoil";

const CategorieFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [errors, setErrors] = useState([]);

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post(
        "/categories",
        { name, type },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setName("");
        setType("");
        setErrors([]);
      }

      // console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col-md-12 col-xs-12 mb-2">
          <label htmlFor="nama" className="form-label">
            Nama
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12 col-xs-12 mb-4">
          <label htmlFor="nama" className="form-label">
            Tipe Nilai
          </label>
          <select
            className={`form-select mb-1 ${errors.type ? "is-invalid" : ""}`}
            type="text"
            placeholder="Tipe Nilai"
            id="nama"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">===Pilih Tipe Nilai===</option>
            <option value="angka">Angka</option>
            <option value="predikat">Predikat</option>
          </select>
          {errors.type ? (
            <div className="invalid-feedback">{errors.type[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary float-end">
            SUBMIT
          </button>
          <Link to="/categories">
            <button className="btn btn-warning mx-4 float-end">BACK</button>
          </Link>
        </div>
      </div>
    </form>
  );
};

export default CategorieFormComponent;
