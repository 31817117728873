import React from "react";
import { Link, useParams } from "react-router-dom";

const QuranStudentComponent = ({ students }) => {
  const { classroom_id, period_id, type } = useParams();

  const statusNilai = (nilaiquran) => {
    if (nilaiquran !== null) {
      return <span className="badge bg-success fs-6">NILAI COMPLETE</span>;
    } else {
      return <span className="badge bg-secondary fs-6">BELUM PUNYA NILAI</span>;
    }
  };

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12 table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr className="text-center">
                <th>#</th>
                <th>NAMA</th>
                <th>NISN</th>
                <th>NIS</th>
                <th>STATUS NILAI</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{student.name}</td>
                  <td className="text-center">{student.nisn}</td>
                  <td className="text-center">{student.nis}</td>
                  <td className="text-center">
                    {statusNilai(student.nilaiquran)}
                  </td>
                  <td className="text-center">
                    <Link
                      to={`/qurans/classroom/${classroom_id}/period/${period_id}/student/${student.id}/${type}`}
                      className="btn btn-primary"
                    >
                      INPUT NILAI
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <Link
            to={`/classrooms/ismuba/${type}`}
            className="btn btn-warning float-start"
          >
            BACK
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuranStudentComponent;
