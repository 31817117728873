import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Swal from "sweetalert2";

const SuratModalComponent = () => {
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [surats, setSurats] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [errors, setErrors] = useState([]);

  const showModal = () => {
    if (surats.length === 0) getData();
    setShow(true);
  };

  const getData = async () => {
    try {
      let response = await axios.get(`surats/create`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setSurats(response.data);
      //   console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const checkSurat = (surat) => {
    const check = templates.find((row) => {
      return row.id === surat.id;
    });

    if (check === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const addSurat = (e, surat) => {
    if (checkSurat(surat)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Surat sudah ada !",
      });

      return;
    }

    e.target.parentElement.remove();

    templates.push(surat);
    setTemplates(templates);
    setRefresh(!refresh);
  };

  const delSurat = (index) => {
    templates.splice(index, 1);
    setTemplates(templates);
    setRefresh(!refresh);
  };

  const templateExport = async () => {
    try {
      let response = await axios.post(
        `exports/surat`,
        { surats: templates },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        console.log(response.data);
        const filename = "template-surat";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {}, [refresh]);

  return (
    <>
      <Button
        variant="primary"
        className="float-end"
        onClick={() => showModal()}
      >
        TEMPLATE SURAT
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        fullscreen={true}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            TEMPLATE IMPORT SURAT
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {errors.message ? (
              <div className="alert alert-warning">{errors.message}</div>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-6">
              <ListGroup>
                {surats.map((surat) => (
                  <ListGroup.Item key={surat.id}>
                    {surat.name}{" "}
                    <Button
                      variant="success"
                      className="float-end"
                      onClick={(e) => addSurat(e, surat)}
                    >
                      Add
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
            <div className="col-6">
              <div className="row mb-4">
                <div className="col-12">
                  <ListGroup>
                    {templates.map((template, index) => (
                      <ListGroup.Item key={index}>
                        {template.name}{" "}
                        <Button
                          variant="danger"
                          className="float-end"
                          onClick={() => delSurat(index)}
                        >
                          X
                        </Button>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {templates.length > 0 ? (
                    <Button
                      variant="success"
                      className="float-end"
                      onClick={() => templateExport()}
                    >
                      DOWNLOAD
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuratModalComponent;
