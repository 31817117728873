import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import SmallLoadingComponent from "../../components/SmallLoadingComponent";
import { authenticated } from "../../store";
import SuratListComponent from "./SuratListComponent";

const SuratListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [surats, setSurats] = useState([]);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/surats?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setSurats(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const delData = async (id) => {
    try {
      let response = await axios.delete("/surats/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      getData();

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const handleSearch = (e) => {
    const src = "src=" + e.target.value;
    getData(src);
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>List Surat</h2>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            {errors.message ? (
              <div className="alert alert-warning">{errors.message}</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-12 mb-4">
            <input
              type="text"
              className="form-control float-end mb-4 col-2"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <Link to="/surats/create">
              <button className="btn btn-success float-end mb-4">Create</button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {surats.length === 0 ? (
              <SmallLoadingComponent />
            ) : (
              <SuratListComponent
                surats={surats}
                delData={(id) => delData(id)}
                updateData={(page) => getData(page)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuratListContainer;
