import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AlertComponent from "../../components/AlertComponent";
import { authenticated } from "../../store";
import IsmubaClassroomHeader from "../components/IsmubaClassroomHeader";
import FormNilaiSiswaReguler from "./FormNilaiSiswaReguler";

const InputNilaiSiswaReguler = () => {
  const auth = useRecoilValue(authenticated);
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }
  }, []);

  return (
    <div className="card">
      <h2 className="card-header">Input nilai siswa reguler</h2>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="col mb-4">
              <AlertComponent color={"danger"} message={errors.message} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row mb-4">
          <div className="col-4">
            <IsmubaClassroomHeader />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <FormNilaiSiswaReguler setErrors={(data) => setErrors(data)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputNilaiSiswaReguler;
