import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { postData } from "../../utils/ClassroomFunct";
import ImportSuratClassroom from "../surat/ImportSuratClassroom";
import { ismubaKeterangan } from "../../utils/NilaiStudent";
import { useRecoilValue } from "recoil";
import { authenticated } from "../../store";

const FormNilaiSiswaReguler = ({ setErrors }) => {
  const auth = useRecoilValue(authenticated);
  const [students, setStudents] = useState([]);
  const { classroom_id, period_id, surat_id } = useParams();
  const redirect = useNavigate();

  const getStudents = async () => {
    try {
      let response = await axios.get(
        `/regulers/quran/nilai?classroom_id=${classroom_id}&period_id=${period_id}&surat_id=${surat_id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setStudents(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const avgSurat = (data) => {
    const number = [
      parseFloat(data.makhorijul_huruf),
      parseFloat(data.tajwid),
      parseFloat(data.nada_ummi),
      parseFloat(data.kelancaran),
    ];
    const sum = number.reduce((a, b) => a + b, 0);
    const avg = sum / number.length;
    const roundedString = Math.round(avg);
    // const roundedString = avg.toFixed(2);
    // const rounded = Number(roundedString);

    return roundedString;
  };

  const nilaiSuratInput = (e, item, index) => {
    const surat = { ...item.nilaisurat };
    let inputValue = e.target.value.replace(/[^\d.]/g, "");
    if (isNaN(inputValue)) inputValue = "";
    surat[e.target.name] = inputValue;
    surat.avg_surat = avgSurat(surat);
    surat.keterangan = ismubaKeterangan(avgSurat(surat));

    item.nilaisurat = { ...surat };

    setStudents([
      ...students.slice(0, index),
      item,
      ...students.slice(index + 1),
    ]);

    // students[index] = item;
    // setStudents([...students]);
  };

  const submitNilai = async () => {
    const classroom = { classroom_id, period_id, surat_id };
    const record = { classroom, students };
    const send = await postData("regulers/quran/nilai/update", record);
    if (send.status === 200) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: send.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: send.data.message,
      });
    }
  };

  const backButton = () => {
    Swal.fire({
      title: "Sudah UPDATE NILAI ?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "SUDAH",
      cancelButtonText: `BELUM`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        redirect(-1);
      }
    });
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/", { replace: true });
    }
    getStudents();
  }, [auth.login]);

  return (
    <div className="row row-cols-1">
      <div className="col-mb-4">
        <div className="row">
          <div className="col-3 offset-9">
            <label className="mb-2">IMPORT NILAI</label>
            <ImportSuratClassroom
              url={`imports/nilai/classroom/reguler?classroom_id=${classroom_id}&surat_id=${surat_id}`}
              setCollection={(data) => setStudents(data)}
            />
          </div>
        </div>
      </div>
      <div className="col mb-4 table-responsive">
        <table className="table table-bordered table-striped">
          <thead className="text-uppercase text-center">
            <tr>
              <th rowSpan={2}>#</th>
              <th rowSpan={2}>nama</th>
              <th colSpan={4}>aspek penilaian</th>
              <th rowSpan={2}>rata - rata</th>
              <th rowSpan={2}>keterangan</th>
            </tr>
            <tr>
              <th>makhorijul huruf</th>
              <th>tajwid</th>
              <th>nada ummi</th>
              <th>kelancaran</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={student.id}>
                <td>{index + 1}</td>
                <td>{student.name}</td>
                <td>
                  <input
                    className="form-control text-center"
                    type="text"
                    name="makhorijul_huruf"
                    value={student.nilaisurat.makhorijul_huruf}
                    onChange={(e) => nilaiSuratInput(e, student, index)}
                  />
                </td>
                <td>
                  <input
                    className="form-control text-center"
                    type="text"
                    name="tajwid"
                    value={student.nilaisurat.tajwid}
                    onChange={(e) => nilaiSuratInput(e, student, index)}
                  />
                </td>
                <td>
                  <input
                    className="form-control text-center"
                    type="text"
                    name="nada_ummi"
                    value={student.nilaisurat.nada_ummi}
                    onChange={(e) => nilaiSuratInput(e, student, index)}
                  />
                </td>
                <td>
                  <input
                    className="form-control text-center"
                    type="text"
                    name="kelancaran"
                    value={student.nilaisurat.kelancaran}
                    onChange={(e) => nilaiSuratInput(e, student, index)}
                  />
                </td>
                <td className="text-center">{student.nilaisurat.avg_surat}</td>
                <td className="text-center">{student.nilaisurat.keterangan}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col">
        <button className="btn btn-warning" onClick={() => backButton()}>
          BACK
        </button>
        <button
          className="btn btn-success float-end"
          onClick={() => submitNilai()}
        >
          UPDATE
        </button>
      </div>
    </div>
  );
};

export default FormNilaiSiswaReguler;
