import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import LoadingComponent from "../components/LoadingComponent";
import { authenticated, scoredetail } from "../store";
import CompetenceRecapComponent from "../components/CompetenceRecapComponent";

const CompetenceRecapContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [score, setScore] = useRecoilState(scoredetail);
  const [errors, setErrors] = useState("");
  const [messages, setMessages] = useState(false);
  const redirect = useNavigate();
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/recaps/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setScore(response.data);
      } else if (response.status === 204) {
        setMessages({ data: "Tidak ada siswa dikelas ini, hubungi admin" });
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }
    getData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Rekap Nilai Kelas {score?.classroom?.name}</h2>
      </div>
      <div className="card-body">
        {score ? (
          <CompetenceRecapComponent />
        ) : (
          [
            messages ? (
              <h2 key={0}>{messages.data}</h2>
            ) : (
              <LoadingComponent key={1} />
            ),
          ]
        )}
      </div>
    </div>
  );
};

export default CompetenceRecapContainer;
