import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import StudentDetailComponent from "../components/StudentDetailComponent";
import { authenticated, studentdetail } from "../store";

const StudentDetailContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [student, setStudent] = useRecoilState(studentdetail);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/students/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setStudent(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Detail {student?.person?.name}</h2>
      </div>
      <div className="card-body">
        {student ? <StudentDetailComponent /> : <SmallLoadingComponent />}
      </div>
    </div>
  );
};

export default StudentDetailContainer;
