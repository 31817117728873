import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, lcategorielist, lessonshow } from "../store";

const LessonsEditFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [lcategories] = useRecoilState(lcategorielist);
  const [lessondetail] = useRecoilState(lessonshow);
  const [lcategorie_id, setLcategorie_id] = useState("");
  const [lesson_order, setLesson_order] = useState("");
  const [name, setName] = useState("");
  const [kkm, setKkm] = useState("");
  const record = { lcategorie_id, lesson_order, name, kkm };
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const { id } = useParams();

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.put("/lessons/" + id, record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setErrors([]);
      }

      //   console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    if (lessondetail) {
      setLcategorie_id(lessondetail.lcategorie_id);
      setLesson_order(lessondetail.lesson_order);
      setName(lessondetail.name);
      setKkm(lessondetail.kkm);
    }
  }, [auth.login]);

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col-md-6 col-xs-12 mb-4">
          <label htmlFor="nama" className="form-label">
            Kelompok Pelajaran
          </label>
          <select
            className={`form-select mb-1 ${
              errors.lcategorie_id ? "is-invalid" : ""
            }`}
            type="text"
            id="lcategorie_id"
            value={lcategorie_id}
            onChange={(e) => setLcategorie_id(e.target.value)}
          >
            <option value={""}>===Pilih===</option>
            {lcategories
              ? lcategories.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))
              : ""}
          </select>
          {errors.lcategorie_id ? (
            <div className="invalid-feedback">{errors.lcategorie_id[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-xs-12 mb-4">
          <label htmlFor="lesson_order" className="form-label">
            Nomor Urut Pelajaran
          </label>
          <input
            className={`form-control mb-1 ${
              errors.lesson_order ? "is-invalid" : ""
            }`}
            type="number"
            placeholder="Nomor Urut Pelajaran"
            id="lesson_order"
            value={lesson_order}
            onChange={(e) => setLesson_order(parseInt(e.target.value))}
          />
          {errors.lesson_order ? (
            <div className="invalid-feedback">{errors.lesson_order[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-xs-12 mb-4">
          <label htmlFor="nama" className="form-label">
            Nama Mata Pelajaran
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-xs-12 mb-4">
          <label htmlFor="kkm" className="form-label">
            KKM
          </label>
          <input
            className={`form-control mb-1 ${errors.kkm ? "is-invalid" : ""}`}
            type="text"
            placeholder="KKM"
            id="kkm"
            value={kkm}
            onChange={(e) => setKkm(e.target.value)}
          />
          {errors.kkm ? (
            <div className="invalid-feedback">{errors.kkm[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <button type="submit" className="btn btn-success float-end">
        UPDATE
      </button>

      <Link to="/lessons">
        <button className="btn btn-warning mx-4 float-end">BACK</button>
      </Link>
    </form>
  );
};

export default LessonsEditFormComponent;
