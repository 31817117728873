import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SmallLoadingComponent from "./SmallLoadingComponent";
import PaginationComponent from "./PaginationComponent";
import Swal from "sweetalert2";

const AcademicListComponent = () => {
  const [academics, setAcademics] = useState("");
  const [errors, setErrors] = useState("");

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/academics?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAcademics(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const handleSearch = (e) => {
    const src = "src=" + e.target.value;
    getData(src);
  };

  const delData = async (id) => {
    try {
      let response = await axios.delete("/academics/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });
        getData();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    var mounted = true;
    if (mounted) {
      getData();
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div>
      <div className="card-header">
        <h2>List Tahun Ajaran</h2>
      </div>
      {academics.data ? (
        <div className="card-body">
          <div className="row">
            <div className="col">
              <Link to="/academics/create">
                <button className="btn btn-success mb-4">CREATE</button>
              </Link>
            </div>
            <div className="col">
              <Link to="/academics/classroom/set">
                <button className="btn btn-primary mb-4">
                  SET TAHUN AJARAN KELAS
                </button>
              </Link>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control float-end mb-4 col-2"
                placeholder="Search..."
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">NAMA</th>
                    <th className="text-center">NAMA SINGKAT</th>
                    <th className="text-center">TAHUN</th>
                    <th className="text-center">ACTIVE</th>
                    <th className="text-center" colSpan={2} scope="col">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {academics.data.map((row, index) => (
                    <tr key={index} className="text-center">
                      <th>{index + 1}</th>
                      <td>{row.name}</td>
                      <td>{row.nickname}</td>
                      <td>{row.year}</td>
                      <td>
                        {row.active === "true" ? (
                          <span className="badge bg-success fs-6">ON</span>
                        ) : (
                          <span className="badge bg-secondary fs-6">OFF</span>
                        )}
                      </td>
                      <td>
                        <Link to={"/academics/" + row.id + "/edit"}>
                          <button className="btn btn-primary">EDIT</button>
                        </Link>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => delData(row.id)}
                        >
                          HAPUS
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <PaginationComponent
            data={academics.links}
            handlePaginate={(page) => getData(page)}
          />
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AcademicListComponent;
