import React from "react";
import { useRecoilState } from "recoil";
import { statusadminlist } from "../store";

const AdminClassroomStatusComponent = () => {
  const [statusadmins] = useRecoilState(statusadminlist);

  return (
    <div className="col-md-12 mb-4">
      <div className="card">
        <div className="card-header">
          <h4>
            <i className="bi bi-stack"></i> Kelas
          </h4>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              {statusadmins?.classrooms?.map((row, index) => (
                <tr key={index}>
                  <th>{row.name}</th>
                  <th>
                    <span className="badge bg-success">
                      <i className="bi bi-people-fill"></i> {row.students_count}
                    </span>
                  </th>
                </tr>
              ))}
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminClassroomStatusComponent;
