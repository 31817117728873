import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, scorelist, studentlist } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const SocialCreateScoreComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [students, setStudents] = useRecoilState(studentlist);
  const [errors, setErrors] = useState("");
  const [scores] = useRecoilState(scorelist);
  const [socials, setSocials] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [colheight, setColheight] = useState(54);
  const { classroom_id, academic_id, period_id } = useParams();
  const redirect = useNavigate();

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.put(
        "/attitudes/social",
        { classroom_id, academic_id, period_id, students },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  const handleNull = (value) => {
    if (value === null) {
      value = "";
    } else {
      value = value;
    }

    return value;
  };

  const handleNilaiSocial = (e, index) => {
    const indexat = scores.socials.findIndex(
      (row) => row.predicate === e.target.value
    );

    const attitude = scores.socials[indexat];
    const newvalue = JSON.parse(JSON.stringify({ ...students[index] }));
    newvalue.studentnote.social_attitude_predicate = attitude.predicate;
    newvalue.studentnote.social_attitude_description = attitude.description;
    const record = [
      ...students.slice(0, index),
      newvalue,
      ...students.slice(index + 1),
    ];

    setStudents(record);
  };

  const handleKeteranganSocial = (e, index) => {
    const newvalue = JSON.parse(JSON.stringify({ ...students[index] }));
    newvalue.studentnote.social_attitude_description = e.target.value;
    const record = [
      ...students.slice(0, index),
      newvalue,
      ...students.slice(index + 1),
    ];

    // console.log(record);

    setStudents(record);
  };

  const trHeightAppend = () => {
    const tbody = document.getElementsByTagName("tbody")[0];
    if (tbody.childNodes.length > 0) {
      const trHeight = tbody.childNodes[0].cells[0].clientHeight;
      setColheight(trHeight);
    }
  };

  const tableScrollLeft = () => {
    trHeightAppend();
    const scrollValue =
      document.getElementsByClassName("table-responsive")[0].scrollLeft;

    if (scrollValue > 200) {
      setScroll(true);
    } else if (scrollValue < 200) {
      setScroll(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="col">
      <div className="row">
        <div className="col-7"></div>
        <div className="col-4 offset-2">
          {/* <input
            type="text"
            className="form-control float-end mb-4 col-2"
            placeholder="Search..."
          /> */}
        </div>
      </div>
      {students ? (
        <div className="row row-cols-1">
          <div
            className="col table-responsive mb-2"
            onTouchMove={() => tableScrollLeft()}
          >
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Kelas</th>
                  <th scope="col">NIS</th>
                  <th className="col-md-2" scope="col">
                    Nama Siswa
                  </th>
                  <th className="col-md-2" scope="col">
                    Predikat Nilai Sosial
                  </th>
                  <th scope="col">Keterangan Nilai Sosial</th>
                </tr>
              </thead>
              <tbody>
                {students.map((siswa, sindex) => (
                  <tr key={sindex}>
                    <th scope="row">{sindex + 1}</th>
                    <td>{scores.classroom.name}</td>
                    <td>{siswa.nis}</td>
                    <td
                      className={scroll ? "absolute-col" : null}
                      style={{ height: colheight + "px" }}
                    >
                      <div className="fix-width">{siswa.name}</div>
                    </td>
                    <td>
                      <select
                        key={0}
                        className={`form-select fix-width-select`}
                        name={`spiritual` + siswa.nis}
                        type="text"
                        value={handleNull(
                          students[sindex].studentnote.social_attitude_predicate
                        )}
                        onChange={(e) => handleNilaiSocial(e, sindex)}
                      >
                        <option value="">=Pilih Predikat=</option>
                        {scores?.socials?.map((row, index) => (
                          <option key={index} value={row.predicate}>
                            {row.predicate}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <textarea
                        className="form-control fix-width-textarea"
                        name="spiritual_keterangan"
                        rows={4}
                        value={handleNull(
                          students[sindex].studentnote
                            .social_attitude_description
                        )}
                        onChange={(e) => handleKeteranganSocial(e, sindex)}
                      ></textarea>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col">
            <button
              className="btn btn-primary float-end"
              onClick={(e) => handleForm(e)}
            >
              UPDATE
            </button>
            <button
              type="button"
              onClick={() => redirect(-1)}
              className="btn btn-warning mx-4 float-end"
            >
              BACK
            </button>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default SocialCreateScoreComponent;
