import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authenticated } from "../store";
import IsmubaReportClassroomDetailComponent from "./IsmubaReportClassroomDetailComponent";

const IsmubaReportClassroomDetailContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [report, setReport] = useState(false);
  const [students, setStudents] = useState([]);
  const [errors, setErrors] = useState([]);
  const { classroom_id, academic_id, type } = useParams();
  const navigate = useNavigate();
  const params = classroom_id + "/" + academic_id + "/detail";

  const getData = async () => {
    try {
      let response = await axios.get("/reports/" + params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setReport(response.data);
      setStudents(response.data.students);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    var mounted = true;
    if (mounted) getData();
    return () => {
      mounted = false;
    };
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header text-capitalize">
        <h2>
          Ismuba Kelas{" "}
          <span className="badge bg-success fs-5">
            {report?.classroom?.name}
          </span>{" "}
          {type}
        </h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="col">
              <div className="alert alert-danger">{errors.message}</div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-md-5 mb-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Nama Wali Kelas</th>
                  <th>:</th>
                  <th>{auth?.user.name}</th>
                </tr>
                <tr>
                  <th>Kelas</th>
                  <th>:</th>
                  <th>{report?.classroom?.name}</th>
                </tr>
                <tr>
                  <th>Tahun Ajaran</th>
                  <th>:</th>
                  <th>{report?.academic?.nickname}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div className="row">
          <IsmubaReportClassroomDetailComponent
            report={report}
            students={students}
            upadateErrors={(errors) => setErrors(errors)}
          />
        </div>
      </div>
    </div>
  );
};

export default IsmubaReportClassroomDetailContainer;
