import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const ClassroomFormComponent = ({ classroom, academics }) => {
  const [group_names, setGroup_names] = useState([]);
  const [name, setName] = useState("");
  const [group_name, setGroup_name] = useState("");
  const [academic_id, setAcademic_id] = useState("");
  const [type, setType] = useState("");
  const [errors, setErrors] = useState([]);
  const { id } = useParams();
  const records = { academic_id, name, group_name, type };

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.put("/classrooms/" + id, records, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });

        setErrors([]);
      }

      // console.log(response.status);
    } catch (e) {
      setErrors(e.response.data);
    }
  };

  const handleAcademic = (academic_id) => {
    setAcademic_id(academic_id);
    if (group_names.length === 0) {
      const groupnames = academics.find(
        (academic) => academic.id === academic_id
      );

      setGroup_names(groupnames.periods);
    }
  };

  useEffect(() => {
    if (academics.length > 0) {
      const group_names = academics.find(
        (academic) => academic.id === classroom.academic_id
      );

      setAcademic_id(classroom.academic_id);
      setName(classroom.name);
      setGroup_name(classroom.group_name);
      if (group_names !== undefined) {
        setGroup_names(group_names.periods);
      }

      setType(classroom.type);
    }
  }, []);

  return (
    <div>
      <div className="row">
        <form onSubmit={(e) => formHandle(e)}>
          <div className="row mb-4">
            <div className="col-md-3 col-xs-12 mb-2">
              <label htmlFor="nama" className="form-label">
                Nama Kelas
              </label>
              <input
                className={`form-control mb-1 ${
                  errors.name ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Nama"
                id="nama"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name ? (
                <div className="invalid-feedback">{errors.name[0]}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-3 col-xs-12 mb-2">
              <label htmlFor="academic_id" className="form-label">
                Tahun Ajaran
              </label>
              <select
                className={`form-select mb-1 ${
                  errors.academic_id ? "is-invalid" : ""
                }`}
                type="text"
                id="academic_id"
                value={academic_id}
                onChange={(e) => handleAcademic(e.target.value)}
              >
                <option value={""}>===Pilih Tahun Ajaran===</option>
                {academics.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))}
              </select>
              {errors.academic_id ? (
                <div className="invalid-feedback">{errors.academic_id[0]}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-3 col-xs-12 mb-2">
              <label htmlFor="group_name" className="form-label">
                Group Kelas
              </label>

              <select
                className={`form-select mb-1 ${
                  errors.group_name ? "is-invalid" : ""
                }`}
                type="text"
                id="group_name"
                value={group_name}
                onChange={(e) => setGroup_name(e.target.value)}
              >
                <option value={""}>===Pilih Group Kelas===</option>
                {group_names.map((row, index) => (
                  <option key={index} value={row.group_name}>
                    {row.group_name}
                  </option>
                ))}
              </select>

              {errors.group_name ? (
                <div className="invalid-feedback">{errors.group_name[0]}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-3 col-xs-12 mb-2">
              <label htmlFor="type" className="form-label">
                Type Kelas
              </label>

              <select
                className={`form-select mb-1 ${
                  errors.type ? "is-invalid" : ""
                }`}
                type="text"
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">===Pilih Type Kelas===</option>
                <option value="reguler">Reguler</option>
                <option value="tahfidz">Tahfidz</option>
              </select>

              {errors.type ? (
                <div className="invalid-feedback">{errors.type[0]}</div>
              ) : (
                ""
              )}
            </div>
          </div>

          <button type="submit" className="btn btn-success float-end">
            UPDATE
          </button>

          <Link to="/classrooms">
            <button className="btn btn-warning mx-4 float-end">BACK</button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default ClassroomFormComponent;
