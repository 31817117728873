import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const SuratSuggestComponent = ({ handleSurat }) => {
  const [surats, setSurats] = useState([]);
  const [form, setForm] = useState("");

  const findSurat = async (input) => {
    if (input === "") {
      setForm("");
      return setSurats([]);
    }
    setForm(input);

    try {
      let response = await axios.get("/surats?src=" + input, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setSurats(response.data.data);
    } catch (e) {
      //   setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const clickSurat = (surat) => {
    handleSurat(surat);
    setSurats([]);
    setForm("");
  };

  return (
    <div className="row">
      <div className="col-3">
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Ketik Surat"
            aria-label="Ketik Surat"
            aria-describedby="basic-addon2"
            value={form}
            onChange={(e) => findSurat(e.target.value)}
          />
        </InputGroup>
        <ul className="list-group suggest">
          {surats.map((row, index) => (
            <li
              key={index}
              className="list-group-item"
              onClick={() => clickSurat(row)}
            >
              {row.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SuratSuggestComponent;
