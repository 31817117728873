import React from "react";
import UserFormComponent from "../components/UserFormComponent";

const UserFormContainer = () => {
  return (
    <div className="card">
      <div className="card-header">
        <h2>Create User Form</h2>
      </div>
      <div className="card-body">
        <UserFormComponent />
      </div>
    </div>
  );
};

export default UserFormContainer;
