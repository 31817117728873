import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import LessonsListComponent from "../components/LessonsListComponent";
import LoadingComponent from "../components/LoadingComponent";
import { authenticated, lcategorielist, lessonshow, waiting } from "../store";

const LessonsListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [loading, setLoading] = useRecoilState(waiting);
  const [lcategories, setLcategories] = useRecoilState(lcategorielist);
  const [lessondetail, setLessondetail] = useRecoilState(lessonshow);
  const navigate = useNavigate();

  useEffect(() => {
    setLessondetail(false);
    setLcategories(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }
  }, [auth.login]);

  return (
    <div className="card">
      {loading ? <LoadingComponent /> : <LessonsListComponent />}
    </div>
  );
};

export default LessonsListContainer;
