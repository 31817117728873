import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import AcademicComboComponent from "../components/AcademicComboComponent";
import AlertComponent from "../components/AlertComponent";
import ArchiveCompetenceClassroomListComponent from "./ArchiveCompetenceClassroomListComponent";
import LoadingComponent from "../components/LoadingComponent";

import { authenticated } from "../store";
import AttendanceArchiveListComponent from "./AttendanceArchiveListComponent";
import ReportArchiveClassroomListComponent from "./ReportArchiveClassroomListComponent";

const ArchiveContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [archives, setArchives] = useState(false);
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/archives/academic/null", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setArchives(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }
    if (archives === false) {
      getData();
    }
  }, [auth.login]);

  return (
    <div>
      {errors.message ? (
        <AlertComponent color={"danger"} message={errors.message} />
      ) : (
        ""
      )}
      {archives ? (
        <Fragment>
          <AcademicComboComponent
            academics={archives.academics}
            setArchives={(data) => setArchives(data)}
            setErrors={(data) => setErrors(data)}
          />
          <ArchiveCompetenceClassroomListComponent
            archives={archives}
            setErrors={(data) => setErrors(data)}
          />

          {auth.user.homeroom ? (
            <Fragment>
              <AttendanceArchiveListComponent archives={archives} />
              <ReportArchiveClassroomListComponent
                archives={archives}
                setErrors={(data) => setErrors(data)}
              />
            </Fragment>
          ) : (
            ""
          )}
        </Fragment>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default ArchiveContainer;
