import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import AlertComponent from "../components/AlertComponent";
import ClassroomEditFormComponent from "../components/ClassroomEditFormComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated } from "../store";

const ClassroomEditFormContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [classroom, setClassroom] = useState(false);
  const [academics, setAcademics] = useState([]);
  const [errors, setErrors] = useState([]);
  const { id } = useParams();
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/classrooms/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassroom(response.data.classroom);
      setAcademics(response.data.academics);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
    let mounted = true;
    if (mounted) getData();

    return () => {
      mounted = false;
    };
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Edit Kelas {classroom?.name}</h2>
      </div>
      <div className="card-body">
        <div className="row px-2">
          {errors.message ? (
            <div className="alert alert-danger">{errors.message}</div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col">
            {classroom && academics.length > 0 ? (
              <ClassroomEditFormComponent
                classroom={classroom}
                academics={academics}
              />
            ) : (
              <Fragment>
                {classroom && academics.length === 0 ? (
                  <AlertComponent
                    color="warning"
                    message={"Tidak ada tahun ajaran aktif"}
                  />
                ) : (
                  <SmallLoadingComponent />
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassroomEditFormContainer;
