import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, scorelist, studentlist } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const AttitudeScoreComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [students, setStudents] = useRecoilState(studentlist);
  const [errors, setErrors] = useState("");
  const [scores] = useRecoilState(scorelist);
  const [socials, setSocials] = useState([]);
  const { classroom_id, academic_id } = useParams();
  const redirect = useNavigate();

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post(
        "/attitudes/update",
        { classroom_id, academic_id, students },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  const removeArray = (data) => {
    if (data !== null) {
      const index = data.length - 1;
      return [...data.slice(0, index), ...data.slice(index + 1)];
    } else {
      return [...data];
    }
  };

  const average = (data) => {
    const number = data.map((row, index) => parseFloat(row.score));
    const sum = number.reduce((partialSum, a) => partialSum + a, 0);
    const avg = sum / number.length;
    const roundedString = Math.round(avg);

    // const rounded = Number(roundedString);
    return roundedString;
  };

  const handleNull = (value) => {
    if (value === null) {
      value = "";
    } else {
      value = value;
    }

    return value;
  };

  const handleNilaiSocial = (e, index) => {
    const indexat = scores.socials.findIndex(
      (row) => row.predicate === e.target.value
    );
    const attitude = scores.socials[indexat];
    const newvalue = JSON.parse(JSON.stringify({ ...students[index] }));
    newvalue.studentnote.social_attitude_predicate = attitude.predicate;
    newvalue.studentnote.social_attitude_description = attitude.description;
    const record = [
      ...students.slice(0, index),
      newvalue,
      ...students.slice(index + 1),
    ];

    setStudents(record);
  };

  const handleKeteranganSocial = (e, index) => {
    const newvalue = JSON.parse(JSON.stringify({ ...students[index] }));
    newvalue.studentnote.social_attitude_description = e.target.value;
    const record = [
      ...students.slice(0, index),
      newvalue,
      ...students.slice(index + 1),
    ];

    // console.log(record);

    setStudents(record);
  };

  const handleNilaiSpiritual = (e, index) => {
    const indexat = scores.spirituals.findIndex(
      (row) => row.predicate === e.target.value
    );
    const attitude = scores.spirituals[indexat];
    const newvalue = JSON.parse(JSON.stringify({ ...students[index] }));
    newvalue.studentnote.spiritual_attitude_predicate = attitude.predicate;
    newvalue.studentnote.spiritual_attitude_description = attitude.description;
    const record = [
      ...students.slice(0, index),
      newvalue,
      ...students.slice(index + 1),
    ];

    setStudents(record);
  };

  const handleKeteranganSpiritual = (e, index) => {
    const newvalue = JSON.parse(JSON.stringify({ ...students[index] }));
    newvalue.studentnote.spiritual_attitude_description = e.target.value;
    const record = [
      ...students.slice(0, index),
      newvalue,
      ...students.slice(index + 1),
    ];

    // console.log(record);

    setStudents(record);
  };

  const issetIndex = (data, index) => {
    if (typeof data[index] !== "undefined") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="col">
      <div className="row">
        <div className="col-7"></div>
        <div className="col-4 offset-2">
          {/* <input
            type="text"
            className="form-control float-end mb-4 col-2"
            placeholder="Search..."
          /> */}
        </div>
      </div>
      {students ? (
        <div className="row row-cols-1">
          <div className="col table-responsive mb-2">
            <div className="col">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">NIS</th>
                    <th scope="col">Kelas</th>
                    <th className="col-md-2" scope="col">
                      Nama Siswa
                    </th>
                    <th scope="col">Predikat Nilai Sosial</th>
                    <th scope="col">Keterangan Nilai Sosial</th>
                    <th scope="col">Predikat Nilai Spiritual</th>
                    <th scope="col">Keterangan Nilai Spiritual</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((siswa, sindex) => (
                    <tr key={sindex}>
                      <th scope="row">{sindex + 1}</th>
                      <td>{siswa.nis}</td>
                      <td>{scores.classroom.name}</td>
                      <td>{siswa.name}</td>
                      <td>
                        <select
                          key={0}
                          className={`form-select`}
                          name={`nilai` + siswa.nis}
                          type="text"
                          value={handleNull(
                            students[sindex].studentnote
                              .social_attitude_predicate
                          )}
                          onChange={(e) => handleNilaiSocial(e, sindex)}
                        >
                          <option value="">=Pilih Predikat=</option>
                          {scores?.socials?.map((row, index) => (
                            <option value={row.predicate}>
                              {row.predicate}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <textarea
                          className="form-control"
                          name="keterangan"
                          rows={4}
                          value={handleNull(
                            students[sindex].studentnote
                              .social_attitude_description
                          )}
                          onChange={(e) => handleKeteranganSocial(e, sindex)}
                        ></textarea>
                      </td>
                      <td>
                        <select
                          key={0}
                          className={`form-select`}
                          name={`spiritual` + siswa.nis}
                          type="text"
                          value={handleNull(
                            students[sindex].studentnote
                              .spiritual_attitude_predicate
                          )}
                          onChange={(e) => handleNilaiSpiritual(e, sindex)}
                        >
                          <option value="">=Pilih Predikat=</option>
                          {scores?.spirituals?.map((row, index) => (
                            <option value={row.predicate}>
                              {row.predicate}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <textarea
                          className="form-control"
                          name="spiritual_keterangan"
                          rows={4}
                          value={handleNull(
                            students[sindex].studentnote
                              .spiritual_attitude_description
                          )}
                          onChange={(e) => handleKeteranganSpiritual(e, sindex)}
                        ></textarea>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col">
            <button
              className="btn btn-primary float-end"
              onClick={(e) => handleForm(e)}
            >
              UPDATE
            </button>
            <button
              type="button"
              onClick={() => redirect(-1)}
              className="btn btn-warning mx-4 float-end"
            >
              BACK
            </button>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AttitudeScoreComponent;
