import axios from "axios";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated } from "../store";

const ClassroomImportFormComponent = (props) => {
  const [errors, setErrors] = useState(false);
  const [progress, setProgress] = useState(false);
  const [auth, setAuth] = useRecoilState(authenticated);
  const [selected_file, setSelected_file] = useState(false);

  const handleOnchange = (event) => {
    setSelected_file(event.target.files[0]);
  };

  const onFileUpload = async () => {
    try {
      let formData = new FormData();
      formData.append("file_import", selected_file, selected_file.name);

      let response = await axios.post("classrooms/import", formData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(percentCompleted);
          setProgress(percentCompleted);
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });

        // props.updateData();
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const downloadFormat = async () => {
    try {
      let response = await axios.get("/classrooms/import/format", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename = "classrooms-import-format";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  return (
    <div className="row row-cols-2 mb-4">
      <div className="col">
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => downloadFormat()}
        >
          FORMAT IMPORT KELAS
        </button>
      </div>
      <div className="col">
        <label htmlFor="taks-file" className="form-label">
          Import Kelas
        </label>
        <input
          className="form-control mb-3"
          type="file"
          id="taks-file"
          onChange={(event) => handleOnchange(event)}
        />
        {selected_file ? (
          <button
            type="button"
            className="btn btn-success float-end"
            onClick={() => onFileUpload()}
          >
            Upload
          </button>
        ) : null}
      </div>
      <div className="col mt-3 mb-4">
        {progress ? (
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ClassroomImportFormComponent;
