import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import AcademicClassroomFormComponent from "../components/AcademicClassroomFormComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { classroomshow } from "../store";

const AcademicClassroomFormContainer = () => {
  const [classroomdetail, setClassroomdetail] = useRecoilState(classroomshow);
  const [errors, setErrors] = useState(false);

  const getData = async () => {
    try {
      let response = await axios.get("academics/classroom/set", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassroomdetail(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Set Tahun Ajaran Untuk Kelas</h2>
      </div>
      <div className="card-body">
        {classroomdetail ? (
          <AcademicClassroomFormComponent />
        ) : (
          <SmallLoadingComponent />
        )}
      </div>
    </div>
  );
};

export default AcademicClassroomFormContainer;
