import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import AlertComponent from "../../components/AlertComponent";
import LoadingComponent from "../../components/LoadingComponent";
import { authenticated, resurats } from "../../store";
import QuranStudentComponent from "./QuranStudentComponent";

const QuranStudentContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [loading, setLoading] = useState(true);
  const [classroom, setClassroom] = useState(false);
  const setSurats = useSetRecoilState(resurats);
  const [students, setStudents] = useState([]);
  const [errors, setErrors] = useState([]);
  const { classroom_id, period_id } = useParams();
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get(
        `/classrooms/ismuba/${classroom_id}/period/${period_id}/create`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setClassroom(response.data.classroom);
      setStudents(response.data.students);
      setLoading(false);

      //   console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    var mounted = true;
    if (mounted) {
      getData();
      setSurats([]);
    }

    return () => {
      mounted = false;
    };
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Qur'an Siswa Kelas{" "}
          <span className="badge bg-success fs-5 text-wrap">
            {classroom?.name}
          </span>
          <span className="badge bg-success fs-5 text-wrap text-uppercase mx-2">
            {classroom?.type}
          </span>
        </h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="alert alert-warning mb-5">{errors.message}</div>
          ) : (
            ""
          )}
        </div>
        <div className="row mb-5">
          <div className="col-4">
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Kelas:</strong>
                <br />
                <span className="badge bg-success fs-6 text-wrap">
                  {classroom?.name}
                </span>
                <span className="badge bg-success fs-6 text-wrap text-uppercase mx-2">
                  {classroom?.type}
                </span>
              </li>
              <li className="list-group-item">
                <span className="badge bg-success fs-6 text-wrap">
                  {classroom?.academic?.name}
                </span>
              </li>
              <li className="list-group-item">
                <span className="badge bg-success fs-6 text-wrap">
                  Semester {classroom?.academic?.period?.name}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          {students.length > 0 ? (
            <QuranStudentComponent students={students} />
          ) : (
            <Fragment>
              {loading ? (
                <LoadingComponent />
              ) : (
                <AlertComponent color="warning" message="Tidak ada siswa" />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuranStudentContainer;
