import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AlertComponent from "../../components/AlertComponent";
import { authenticated, classroomprops } from "../../store";
import { templateExport } from "../../utils/GetFileFunct";
import IsmubaClassroomHeader from "../components/IsmubaClassroomHeader";
import ImportSuratClassroom from "../surat/ImportSuratClassroom";
import ClassroomSuratComponent from "./ClassroomSuratComponent";

const ClassroomSuratContainer = () => {
  const auth = useRecoilValue(authenticated);
  const classroomheader = useRecoilValue(classroomprops);
  const [surats, setSurats] = useState([]);
  const [errors, setErrors] = useState([]);
  const { classroom_id, period_id } = useParams();
  const redirect = useNavigate();

  useEffect(() => {
    if (!auth.login) {
      redirect("/", { replace: true });
    }
  }, [auth.login]);

  return (
    <div className="card">
      <h2 className="card-header">Surat Kelas</h2>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <AlertComponent color={"danger"} message={errors.message} />
          ) : (
            ""
          )}
        </div>
        <div className="row mb-4">
          <div className="col-4">
            <IsmubaClassroomHeader setErrors={(data) => setErrors(data)} />
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-5 offset-7">
                <label className="mb-2">Import Surat</label>
                <ImportSuratClassroom
                  url={`imports/surat/classroom?classroom_id=${classroom_id}&period_id=${period_id}`}
                  setCollection={(data) => {
                    setSurats(data);
                  }}
                />
              </div>
              <div className="col-12 mb-4">
                <button
                  className="btn btn-success float-end"
                  onClick={() =>
                    templateExport(
                      "exports/template/nilai/surat",
                      `classroom_id=${classroom_id}&period_id=${period_id}`,
                      "template-nilai-surat-reguler"
                    )
                  }
                >
                  TEMPLATE NILAI SURAT
                </button>
                <button
                  className="btn btn-success float-end me-3"
                  onClick={() =>
                    templateExport(
                      "exports/template/nilai/hafalan",
                      `classroom_id=${classroom_id}&period_id=${period_id}&academic_id=${classroomheader.academic.id}`,
                      `template-hafalan-kelas-${classroomheader.name}`
                    )
                  }
                >
                  TEMPLATE NILAI TAMBAHAN
                </button>
              </div>
              <div className="col-12">
                <Link
                  to={`/qurans/hafalan/classroom/${classroom_id}/period/${period_id}`}
                >
                  <button className="btn btn-primary float-end">
                    INPUT NILAI TAMBAHAN
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <ClassroomSuratComponent
            surats={surats}
            setSurats={(data) => setSurats(data)}
            setErrors={(data) => setErrors(data)}
          />
        </div>
        <div className="row">
          <div className="col">
            <button
              className="btn btn-warning float-end"
              onClick={() => redirect("/classrooms/ismuba/reguler")}
            >
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassroomSuratContainer;
