import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import AchievementDetailComponent from "../components/AchievementDetailComponent";
import LoadingComponent from "../components/LoadingComponent";
import { authenticated } from "../store";

const AchievementDetailContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [achievements, setAchievements] = useState(false);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const { student_id, classroom_id, academic_id } = useParams();
  const params = student_id + "/" + classroom_id + "/" + academic_id;

  const getData = async () => {
    try {
      let response = await axios.get("/achievements/" + params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAchievements(response.data);
      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Prestasi Siswa{" "}
          <span className="badge bg-success fs-5">
            {achievements?.student?.name}
          </span>
        </h2>
      </div>
      {achievements ? (
        <AchievementDetailComponent
          achievements={achievements}
          updateData={() => getData()}
        />
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default AchievementDetailContainer;
