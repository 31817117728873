import axios from "axios";
import React, { useState } from "react";
import { Fragment } from "react/cjs/react.production.min";
import Swal from "sweetalert2";
const AdminLedgerClassroomListComponent = ({ classrooms, setErrors }) => {
  const recapExport = async (
    classroom_id,
    academic_id,
    period_id,
    classname,
    academicname
  ) => {
    try {
      const params = classroom_id + "/" + academic_id + "/" + period_id;
      let response = await axios.get("classroom/recap/" + params + "/export", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename =
          "Ledger Kelas " + classname + "-" + academicname + "-recaps";
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  return (
    <div className="col-12 table-responsive">
      <table className="table table-bordered table-striped">
        <thead>
          <tr className="text-center">
            <th scope="col">#</th>
            <th scope="col">NAMA KELAS</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {classrooms.map((row, index) => (
            <tr className="text-center" key={index}>
              <td>{index + 1}</td>
              <td>
                <span className="badge bg-success fs-6">
                  {row.classroom.name}
                </span>
              </td>
              <td>
                {row.academic.periods.map((period, index) => (
                  <Fragment key={index}>
                    {row.classroom.group_name === period.group_name ? (
                      <button
                        className="btn btn-primary mx-2"
                        onClick={() =>
                          recapExport(
                            row.classroom_id,
                            row.academic_id,
                            period.id,
                            row.classroom.name,
                            row.academic.name
                          )
                        }
                      >
                        Semester {period.name}
                      </button>
                    ) : (
                      ""
                    )}
                  </Fragment>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminLedgerClassroomListComponent;
