import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import AlertComponent from "../components/AlertComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated } from "../store";
import IsmubaReportClassroomComponent from "./IsmubaReportClassroomComponent";

const IsmubaReportClassroomContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { type } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get(`classrooms/ismuba/homeroom/${type}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setReports(response.data);
      setErrors([]);
      setLoading(false);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    let mounted = true;
    if (mounted) {
      setLoading(true);
      getData();
    }

    return () => {
      mounted = false;
    };
  }, [auth.login, type]);

  return (
    <div className="card">
      <div className="card-header text-capitalize">
        <h2>Rapot Kelas Ismuba {type}</h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="col">
              <div className="alert alert-danger">{errors.message}</div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          {reports.length > 0 ? (
            <IsmubaReportClassroomComponent reports={reports} />
          ) : (
            <Fragment>
              {loading ? (
                <SmallLoadingComponent />
              ) : (
                <AlertComponent color={"warning"} message={"Tidak ada kelas"} />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default IsmubaReportClassroomContainer;
