import React from "react";
import ForgotPasswordComponent from "../components/ForgotPasswordComponent";

const ForgotPasswordContainer = () => {
  return (
    <div className="row justify-content-lg-center">
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header">
            <h2>Lupa Password</h2>
          </div>
          <div className="card-body">
            <ForgotPasswordComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordContainer;
