import React from "react";
import { useRecoilValue } from "recoil";
import { waiting } from "../store";

const OverBoxComponent = () => {
  const loading = useRecoilValue(waiting);

  return (
    <div>
      {loading ? (
        <div className="over-box p-5">
          <h2
            className="mb-4 text-white text-center"
            style={{ margin: "25vh 0" }}
          >
            Loading...
          </h2>
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-white" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OverBoxComponent;
