import React from "react";
import { Link } from "react-router-dom";
import SmallLoadingComponent from "./SmallLoadingComponent";

const AchievementClassroomListComponent = (props) => {
  return (
    <div>
      <div className="card-header">
        <h2>Input Prestasi Siswa</h2>
      </div>
      {props.classrooms.length > 0 ? (
        <div className="card-body">
          <div className="col table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">KELAS</th>
                  <th className="text-center">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {props.classrooms.map((row, index) => (
                  <tr className="text-center" key={index}>
                    <td scope="row">{index + 1}</td>
                    <td>
                      <span className="badge bg-success fs-6">
                        {row.classroom.name}
                      </span>
                    </td>
                    <td key={index} className="text-center">
                      <Link
                        to={
                          "/homerooms/achievement/" +
                          row.classroom_id +
                          "/" +
                          row.academic_id +
                          "/detail"
                        }
                      >
                        <button className="btn btn-primary">
                          CREATE PRESTASI
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AchievementClassroomListComponent;
