import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { attendancehow, authenticated, classroomshow } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const AttendanceEditFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [attendance] = useRecoilState(attendancehow);
  const [errors, setErrors] = useState("");
  const [attendances, setAttendances] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [colheight, setColheight] = useState(54);
  const redirect = useNavigate();

  const handleSakit = (value, index) => {
    const oldobject = [...attendances];
    let updatevalue = JSON.parse(JSON.stringify(oldobject[index]));
    updatevalue["attendance"] = {
      hadir: updatevalue.attendance.hadir,
      sakit: value,
      ijin: updatevalue.attendance.ijin,
      alpa: updatevalue.attendance.alpa,
      description: updatevalue.attendance.description,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];

    setAttendances(replacevalue);
  };

  const handleIjin = (value, index) => {
    const oldobject = [...attendances];
    let updatevalue = JSON.parse(JSON.stringify(oldobject[index]));
    updatevalue["attendance"] = {
      hadir: updatevalue.attendance.hadir,
      sakit: updatevalue.attendance.sakit,
      ijin: value,
      alpa: updatevalue.attendance.alpa,
      description: updatevalue.attendance.description,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];

    setAttendances(replacevalue);
  };

  const handleAlpa = (value, index) => {
    const oldobject = [...attendances];
    let updatevalue = JSON.parse(JSON.stringify(oldobject[index]));
    updatevalue["attendance"] = {
      hadir: updatevalue.attendance.hadir,
      sakit: updatevalue.attendance.sakit,
      ijin: updatevalue.attendance.ijin,
      alpa: value,
      tanpa_keterangan: updatevalue.attendance.tanpa_keterangan,
      description: updatevalue.attendance.description,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];

    setAttendances(replacevalue);
  };

  const handleDescription = (value, index) => {
    const oldobject = [...attendances];
    let updatevalue = JSON.parse(JSON.stringify(oldobject[index]));
    updatevalue["attendance"] = {
      hadir: updatevalue.attendance.hadir,
      sakit: updatevalue.attendance.sakit,
      ijin: updatevalue.attendance.ijin,
      alpa: updatevalue.attendance.alpa,
      description: value,
    };

    const replacevalue = [
      ...attendances.slice(0, index),
      updatevalue,
      ...attendances.slice(index + 1),
    ];
    setAttendances(replacevalue);
  };

  const handleSubmit = async () => {
    const record = {
      students: attendances,
      classroom_id: attendance.classroom.id,
      academic_id: attendance.academic.id,
      period_id: attendance.academic.period.id,
    };

    try {
      let response = await axios.put("/attendances/students/update", record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  const issetIndex = (data, index) => {
    if (typeof data[index] !== "undefined") {
      return true;
    } else {
      return false;
    }
  };

  const trHeightAppend = () => {
    const tbody = document.getElementsByTagName("tbody")[0];
    if (tbody.childNodes.length > 0) {
      const trHeight = tbody.childNodes[0].cells[0].clientHeight;
      setColheight(trHeight);
    }
  };

  const tableScrollLeft = () => {
    trHeightAppend();
    const scrollValue =
      document.getElementsByClassName("table-responsive")[0].scrollLeft;

    if (scrollValue > 200) {
      setScroll(true);
    } else if (scrollValue < 200) {
      setScroll(false);
    }
  };

  const handleNull = (value) => {
    if (value === null) {
      return "";
    } else {
      return value;
    }
  };

  useEffect(() => {
    setAttendances(attendance.students);
  }, []);

  return (
    <div>
      {attendances ? (
        <>
          <div className="row mb-4">
            <div className="col-md-6">
              <table className="table">
                <thead>
                  <tr>
                    <td>Kelas</td>
                    <td>:</td>
                    <td>
                      <span className="badge bg-success fs-6">
                        {attendance.classroom.name}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Wali Kelas</td>
                    <td>:</td>
                    <td>{auth.user.name}</td>
                  </tr>
                  <tr>
                    <td>Tahun AJaran</td>
                    <td>:</td>
                    <td>{attendance.academic.nickname}</td>
                  </tr>
                  <tr>
                    <td>Semester</td>
                    <td>:</td>
                    <td>
                      <span className="badge bg-success fs-6 text-wrap">
                        {attendance.academic.period.name}
                      </span>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 table-responsive"
              onTouchMove={() => tableScrollLeft()}
            >
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th
                      className="align-middle text-center"
                      rowSpan={2}
                      scope="col"
                    >
                      #
                    </th>
                    <th
                      className="align-middle text-center"
                      rowSpan={2}
                      scope="col"
                    >
                      NIS
                    </th>
                    <th
                      className="align-middle text-center col-md-2"
                      rowSpan={2}
                      scope="col"
                    >
                      Nama
                    </th>
                    <th
                      className="align-middle text-center"
                      colSpan={3}
                      scope="col"
                    >
                      Status
                    </th>
                    <th
                      className="align-middle text-center"
                      rowSpan={2}
                      scope="col"
                    >
                      Keterangan
                    </th>
                  </tr>
                  <tr>
                    <td className="text-center bg-info text-light fw-bold">
                      S
                    </td>
                    <td className="text-center bg-warning text-light fw-bold">
                      I
                    </td>
                    <td className="text-center bg-danger text-light fw-bold">
                      A
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {attendances.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{row.nis}</td>
                      <td
                        className={scroll ? "absolute-col" : null}
                        style={{ height: colheight + "px" }}
                      >
                        {row.name}
                      </td>
                      <td>
                        <input
                          className="form-control fix-width-input"
                          type="number"
                          name="sakit"
                          value={row.attendance.sakit}
                          onChange={(e) => handleSakit(e.target.value, index)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control fix-width-input"
                          type="number"
                          name="ijin"
                          value={row.attendance.ijin}
                          onChange={(e) => handleIjin(e.target.value, index)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control fix-width-input"
                          type="number"
                          name="alpa"
                          value={row.attendance.alpa}
                          onChange={(e) => handleAlpa(e.target.value, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="keterangan"
                          id="keterangan"
                          value={handleNull(row.attendance.description)}
                          onChange={(e) =>
                            handleDescription(e.target.value, index)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-12">
              <button
                type="button"
                className="btn btn-success mt-5 mb-5 float-end"
                onClick={() => handleSubmit()}
              >
                UPDATE
              </button>
              <button
                type="button"
                className="btn btn-warning mt-5 mb-5 float-end me-5"
                onClick={() => redirect(-1)}
              >
                BACK
              </button>
            </div>
          </div>
        </>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AttendanceEditFormComponent;
