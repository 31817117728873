import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import ReportClassroomDetailComponent from "../components/ReportClassroomDetailComponent";
import { authenticated } from "../store";

const ReportClassroomDetailContainer = () => {
  const [auth] = useRecoilState(authenticated);

  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
  }, [auth.login]);

  return (
    <div className="card">
      <ReportClassroomDetailComponent />
    </div>
  );
};

export default ReportClassroomDetailContainer;
