import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, lcategoriedetail } from "../store";

const LessonGroupEditFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [lcategorie, setLcategorie] = useRecoilState(lcategoriedetail);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const { id } = useParams();

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.put(
        "/clessons/" + id,
        { name: name },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    if (lcategorie) {
      setName(lcategorie.name);
    }
  }, [auth.login]);

  return (
    <div>
      <div className="row">
        <form onSubmit={(e) => formHandle(e)}>
          <div className="row mb-4">
            <div className="col">
              <label htmlFor="nama" className="form-label">
                Nama Kelompok
              </label>
              <input
                className={`form-control mb-1 ${
                  errors.name ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Nama"
                id="nama"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name ? (
                <div className="invalid-feedback">{errors.name[0]}</div>
              ) : (
                ""
              )}
            </div>
          </div>

          <button type="submit" className="btn btn-success float-end">
            UPDATE
          </button>

          <Link to="/clessons">
            <button className="btn btn-warning mx-4 float-end">BACK</button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default LessonGroupEditFormComponent;
