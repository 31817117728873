import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import LoadingComponent from "../../components/LoadingComponent";
import { authenticated } from "../../store";
import TahfidzNilaiStudentComponent from "./TahfidzNilaiStudentComponent";

const TahfidzNilaiStudentContainer = () => {
  const auth = useRecoilValue(authenticated);
  const { classroom_id, period_id, student_id } = useParams();
  const [classroom, setClassroom] = useState(false);
  const [student, setStudent] = useState([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get(
        "/tahfidz/quran/classroom/" +
          classroom_id +
          "/period/" +
          period_id +
          "/student/" +
          student_id,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setClassroom(response.data.classroom);
      setStudent(response.data.student);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const inputNilai = async (records, tambahan, ketquran, juz, avg_quran) => {
    try {
      let response = await axios.post(
        "tahfidz",
        {
          classroom_id,
          period_id,
          academic_id: classroom.academic_id,
          student_id: student.id,
          surats: records,
          hafalan_tambahan: tambahan,
          keterangan: ketquran,
          juz,
          avg_quran,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: e.response.data.message,
        showConfirmButton: true,
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    var mounted = true;
    if (mounted) {
      getData();
    }
    return () => {
      mounted = false;
    };
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Qur'an Nilai Tahfidz Siswa{" "}
          <span className="badge bg-success fs-5 text-wrap">
            {student?.name}
          </span>{" "}
        </h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="col">
              <div className="alert alert-warning mb-5">{errors.message}</div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row mb-5">
          <div className="col-4">
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Nama:</strong>
                <br />
                <span className="badge bg-success fs-6 text-wrap">
                  {student?.name}
                </span>
              </li>
              <li className="list-group-item">
                <strong>Kelas:</strong>
                <br />
                <span className="badge bg-success fs-6 text-wrap">
                  {classroom?.name}
                </span>
              </li>
              <li className="list-group-item">
                <strong>Tahun Ajaran:</strong>
                <br />
                <span className="badge bg-success fs-6 text-wrap">
                  {classroom?.academic?.name}
                </span>
              </li>
              <li className="list-group-item">
                <span className="badge bg-success fs-6 text-wrap">
                  Semester {classroom?.academic?.period?.name}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          {student.name ? (
            <TahfidzNilaiStudentComponent
              kkm={classroom?.academic?.period?.kkm}
              nilaiquran={student.nilaiquran}
              nilaisurats={student.nilaisurats}
              insertNilai={(records, tambahan, ketquran, juz, avg_quran) =>
                inputNilai(records, tambahan, ketquran, juz, avg_quran)
              }
            />
          ) : (
            <LoadingComponent />
          )}
        </div>
      </div>
    </div>
  );
};

export default TahfidzNilaiStudentContainer;
