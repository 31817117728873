import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ImportComponent from "../../components/ImportComponent";
import { ismubaKeterangan } from "../../utils/NilaiStudent";
import SuratSuggestComponent from "../surat/SuratSuggestComponent";

const TahfidzNilaiStudentComponent = ({
  kkm,
  nilaiquran,
  nilaisurats,
  insertNilai,
}) => {
  const [refresh, setRefresh] = useState(false);
  const [surats, setSurats] = useState([]);
  const [tambahan, setTambahan] = useState("");
  const [juz, setJuz] = useState("");
  const [avg_quran, setAvg_quran] = useState(0);
  const [ketquran, setKetquran] = useState("");
  const { classroom_id, period_id } = useParams();

  const checkSurat = (surat) => {
    const check = surats.find((row) => {
      return row.surat_id === surat.id;
    });

    if (check === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const addSurat = (surat) => {
    if (checkSurat(surat)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Surat sudah ada !",
      });

      return false;
    }

    let add = {
      surat_id: surat.id,
      name: surat.name,
      makhorijul_huruf: "",
      tajwid: "",
      nada_ummi: "",
      kelancaran: "",
      avg_surat: "",
      keterangan: "",
    };

    surats.push(add);
    setSurats(surats);
    setRefresh(!refresh);
  };

  const delSurat = (index) => {
    surats.splice(index, 1);
    setSurats(surats);
    avgSurat(surats);
    setRefresh(!refresh);
  };

  const inputHandle = (input, index) => {
    const inputName = input.name;
    let inputValue = input.value.replace(/[^\d.]/g, "");
    if (isNaN(inputValue)) inputValue = "";

    const update = { ...surats[index] };
    update[inputName] = inputValue;

    const avg = average(update);
    update.avg_surat = avg;
    update.keterangan = ismubaKeterangan(avg);

    surats[index] = update;

    setSurats(surats);
    avgSurat(surats);
    setRefresh(!refresh);
  };

  const average = (data) => {
    const number = [
      parseFloat(data.makhorijul_huruf),
      parseFloat(data.tajwid),
      parseFloat(data.nada_ummi),
      parseFloat(data.kelancaran),
    ];
    const sum = number.reduce((a, b) => a + b, 0);
    const avg = sum / number.length;
    const roundedString = Math.round(avg);
    // const roundedString = avg.toFixed(2);
    // const rounded = Number(roundedString);

    return roundedString;
  };

  const avgSurat = (data) => {
    const number = data.map((row) => parseFloat(row.avg_surat));
    const sum = number.reduce((a, b) => a + b, 0);
    const avg = sum / number.length;
    let roundedString = Math.round(avg);
    if (isNaN(roundedString)) roundedString = 0;
    setAvg_quran(roundedString);
    setKetquran(ismubaKeterangan(roundedString));
    // const roundedString = avg.toFixed(2);
    // const rounded = Number(roundedString);

    return roundedString;
  };

  const handleImportSurat = (newsurats) => {
    setSurats(newsurats);

    const avgquran = avgSurat(newsurats);
    setAvg_quran(avgquran);
  };

  useEffect(() => {
    if (nilaiquran !== null) {
      setSurats(nilaisurats);
      avgSurat(nilaisurats);
      setTambahan(nilaiquran.hafalan_tambahan);
      setJuz(nilaiquran.juz);
    }
  }, []);

  return (
    <div className="col">
      <div className="row">
        <div className="col-4 offset-8">
          <label className="mb-2">Import Nilai</label>
          <ImportComponent
            url="imports/nilai/surat"
            setCollection={(update) => handleImportSurat(update)}
          />
        </div>
      </div>
      <div className="row">
        <SuratSuggestComponent handleSurat={(surat) => addSurat(surat)} />
      </div>
      <div className="row">
        <div className="col table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="text-center text-uppercase">
                <th>#</th>
                <th>Nama Surat</th>
                <th>KKM</th>
                <th>Makhorijul Huruf</th>
                <th>Tajwid</th>
                <th>Nada Ummi</th>
                <th>Kelancaran</th>
                <th>Nilai / Rata - Rata</th>
                <th>Keterangan</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {surats.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td className="text-center">{kkm}</td>
                  <td>
                    <input
                      type="text"
                      maxLength="5"
                      className="form-control text-center"
                      value={item.makhorijul_huruf}
                      name="makhorijul_huruf"
                      onChange={(e) => inputHandle(e.target, index)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="text"
                      maxLength="5"
                      className="form-control text-center"
                      value={item.tajwid}
                      name="tajwid"
                      onChange={(e) => inputHandle(e.target, index)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="text"
                      maxLength="5"
                      className="form-control text-center"
                      value={item.nada_ummi}
                      name="nada_ummi"
                      onChange={(e) => inputHandle(e.target, index)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="text"
                      maxLength="5"
                      className="form-control text-center"
                      value={item.kelancaran}
                      name="kelancaran"
                      onChange={(e) => inputHandle(e.target, index)}
                    />
                  </td>
                  <td className="text-center">
                    {isNaN(item.avg_surat) ? 0 : item.avg_surat}
                  </td>
                  <td>{item.keterangan}</td>
                  <td className="text-center">
                    <button
                      className="btn btn-danger"
                      onClick={() => delSurat(index)}
                    >
                      X
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="bg-secondary text-white text-center fw-bold">
                <td colSpan={7}>Rata - Rata</td>
                <td>{avg_quran}</td>
                <td>{ketquran}</td>
                <td colSpan={2}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-1">
          <label htmlFor="juz" className="mb-2">
            Juz
          </label>
          <input
            type="text"
            name="juz"
            id="juz"
            className="form-control"
            placeholder="Juz"
            maxLength={4}
            value={juz}
            onChange={(e) => setJuz(e.target.value.replace(/[^\d.]/g, ""))}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col">
          <label htmlFor="hafalan_tambahan" className="mb-2">
            Hafalan Tambahan
          </label>
          <textarea
            id="hafalan_tambahan"
            type="text"
            className="form-control"
            name="hafalan_tambahan"
            value={tambahan}
            onChange={(e) => setTambahan(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <Link
            to={`/qurans/classroom/${classroom_id}/period/${period_id}/tahfidz`}
            className="btn btn-warning float-start"
          >
            BACK
          </Link>

          <button
            className="btn btn-success float-end"
            onClick={() =>
              insertNilai(surats, tambahan, ketquran, juz, avg_quran)
            }
          >
            UPDATE
          </button>
        </div>
      </div>
    </div>
  );
};

export default TahfidzNilaiStudentComponent;
