import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import LessonsEditFormComponent from "../components/LessonsEditFormComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { authenticated, lcategorielist, lessonshow } from "../store";
const LessonsEditFormContainer = () => {
  const { id } = useParams();
  const [lcategories, setLcategories] = useRecoilState(lcategorielist);
  const [lessondetail, setLessondetail] = useRecoilState(lessonshow);
  const [auth, setAuth] = useRecoilState(authenticated);
  const [errors, setErrors] = useState(false);
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/lessons/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setLessondetail(response.data.lesson);
        setLcategories(response.data.lcategories);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Edit Pelajaran{" "}
          <span className="badge bg-success fs-6">{lessondetail?.name}</span>
        </h2>
      </div>
      <div className="card-body">
        {lessondetail ? (
          <LessonsEditFormComponent />
        ) : (
          <SmallLoadingComponent />
        )}
      </div>
    </div>
  );
};

export default LessonsEditFormContainer;
