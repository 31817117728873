import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SmallLoadingComponent from "./SmallLoadingComponent";
import PaginationComponent from "./PaginationComponent";
import Swal from "sweetalert2";
import { teacherlist } from "../store";
import { useRecoilState } from "recoil";
import { Fragment } from "react/cjs/react.production.min";

const TeacherListComponent = (props) => {
  const [teachers, setTeachers] = useRecoilState(teacherlist);
  const [errors, setErrors] = useState("");

  const page = () => {
    let url = window.location.href;
    url = url.split("#page/", 2);
    if (url[1] !== undefined) {
      return url[1];
    } else {
      return null;
    }
  };

  const delData = async (id) => {
    try {
      let response = await axios.delete("/teachers/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });

        props.updateData(page());
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const handleSearch = (e) => {
    const src = "src=" + e.target.value;
    props.updateData(src);
  };

  return (
    <div>
      {teachers.data ? (
        <div className="row">
          <div className="row mb-4">
            <div className="col-md-4 col-xs-12 mb-2">
              <input
                type="text"
                className="form-control float-end mb-4 col-2"
                placeholder="Search..."
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <div className="col-md-8 col-xs-12 mb-2">
              <Link to="/teachers/create">
                <button className="btn btn-success float-end">Create</button>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col table-responsive mb-4">
              <table className="table table-bordered">
                <thead>
                  <tr className="text-center">
                    <th className="text-center">#</th>
                    <th className="text-center">NBM</th>
                    <th className="text-center">NAMA</th>
                    {/* <th className="text-center">KELAS</th> */}
                    <th colSpan={3} scope="col" className="text-center">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {teachers.data.map((row, index) => (
                    <tr key={index}>
                      <th className="text-center">{index + 1}</th>
                      <td>{row.nip}</td>
                      <td>{row.name}</td>
                      {/* <td>
                        {row.classroomusers.map((classroomuser, index) => (
                          <Fragment key={index}>
                            <span className="badge bg-warning text-black mx-1">
                              {classroomuser.classroom.name}
                            </span>
                          </Fragment>
                        ))}
                      </td> */}
                      <td className="text-center">
                        <Link to={"/teachers/" + row.id}>
                          <button className="btn btn-success">
                            Detail Guru
                          </button>
                        </Link>
                      </td>
                      <td className="text-center">
                        <Link to={"/teachers/" + row.id + "/edit"}>
                          <button className="btn btn-primary">Edit Guru</button>
                        </Link>
                      </td>
                      <td className="text-center">
                        <button
                          onClick={() => delData(row.id)}
                          className="btn btn-danger"
                        >
                          Hapus Guru
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-12">
              <PaginationComponent
                data={teachers.links}
                handlePaginate={(page) => props.updateData(page)}
              />
            </div>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default TeacherListComponent;
