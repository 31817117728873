import React, { Fragment, useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { authenticated } from "./store";
import { useRecoilState } from "recoil";
import "./App.css";
import DashboardContainer from "./containers/DashboardContainer";
import NavbarComponent from "./components/NavbarComponent";
import AttendanceFormContainer from "./containers/AttendanceFormContainer";
import UserListContainer from "./containers/UserListContainer";
import UserFormContainer from "./containers/UserFormContainer";
import ClassroomListContainer from "./containers/ClassroomListContainer";
import ClassroomFormContainer from "./containers/ClassroomFormContainer";
import LessonsListContainer from "./containers/LessonsListContainer";
import LessonsFormContainer from "./containers/LessonsFormContainer";
import CompetenceFormContainer from "./containers/CompetenceFormContainer";
import LoginFormContainer from "./containers/LoginFormContainer";
import StudentFormContainer from "./containers/StudentFormContainer";
import TeacherListContainer from "./containers/TeacherListContainer";
import TeacherFormContainer from "./containers/TeacherFormContainer";
import ClassroomEditFormContainer from "./containers/ClassroomEditFormContainer";
import AcademicListContainer from "./containers/AcademicListContainer";
import AcademicFormContainer from "./containers/AcademicFormContainer";
import AcademicEditFormContainer from "./containers/AcademicEditFormContainer";
import PeriodFormContainer from "./containers/PeriodFormContainer";
import AddTeacherFormContainer from "./containers/AddTeacherFormContainer";
import AddStudentClassroomFormContainer from "./containers/AddStudentClassroomFormContainer";
import AttendanceEditFormContainer from "./containers/AttendanceEditFormContainer";
import PeriodEditFormContainer from "./containers/PeriodEditFormContainer";
import LessonsEditFormContainer from "./containers/LessonsEditFormContainer";
import TeacherEditFormContainer from "./containers/TeacherEditFormContainer";
import StudentEditFormContainer from "./containers/StudentEditFormContainer";
import CategorieListContainer from "./containers/CategorieListContainer";
import CategorieFormContainer from "./containers/CategorieFormContainer";
import CategorieEditFormContainer from "./containers/CategorieEditFormContainer";
import AttitudeContainer from "./containers/AttitudeContainer";
import CompetenceDetailContainer from "./containers/CompetenceDetailContainer";
import CompetenceRecapContainer from "./containers/CompetenceRecapContainer";
import ReportClassroomListContainer from "./containers/ReportClassroomListContainer";
import ReportClassroomDetailContainer from "./containers/ReportClassroomDetailContainer";
import LessonGroupListContainer from "./containers/LessonGroupListContainer";
import LessonGroupFormContainer from "./containers/LessonGroupFormContainer";
import LessonGroupEditFormContainer from "./containers/LessonGroupEditFormContainer";
import AchievementDetailContainer from "./containers/AchievementDetailContainer";
import ExtracurricularDetailContainer from "./containers/ExtracurricularDetailContainer";
import ExtracurricularFormContainer from "./containers/ExtracurricularFormContainer";
import AchievementFormContainer from "./containers/AchievementFormContainer";
import CompetenceEditFormContainer from "./containers/CompetenceEditFormContainer";
import AdministratorListContainer from "./containers/AdministratorListContainer";
import AdministratorFormContainer from "./containers/AdministratorFormContainer";
import AdministratorEditFormContainer from "./containers/AdministratorEditFormContainer";
import AttendanceListContainer from "./containers/AttendanceListContainer";
import ExtracurricularEditFormContainer from "./containers/ExtracurricularEditFormContainer";
import AchievementEditFormContainer from "./containers/AchievementEditFormContainer";
import AddSksFormContainer from "./containers/AddSksFormContainer";
import SideNavbarComponent from "./components/SideNavbarComponent";
import AttitudeListContainer from "./containers/AttitudeListContainer";
import AttitudeFormContainer from "./containers/AttitudeFormContainer";
import AttitudeEditFormContainer from "./containers/AttitudeEditFormContainer";
import AddHomeroomTeacherFormContainer from "./containers/AddHomeroomTeacherFormContainer";
import AttitudeScoreContainer from "./containers/AttitudeScoreContainer";
import CompetenceClassroomListContainer from "./containers/CompetenceClassroomListContainer";
import CompetenceClassroomContainer from "./containers/CompetenceClassroomContainer";
import SpiritualScoreContainer from "./containers/SpiritualScoreContainer";
import SpiritualClassroomListContainer from "./containers/SpiritualClassroomListContainer";
import SocialClassroomListContainer from "./containers/SocialClassroomListContainer";
import SocialCreateScoreContainer from "./containers/SocialCreateScoreContainer";
import LedgerClassroomListContainer from "./containers/LedgerClassroomListContainer";
import ReportOnlyClassroomListContainer from "./containers/ReportOnlyClassroomListContainer";
import AcademicClassroomFormContainer from "./containers/AcademicClassroomFormContainer";
import StudentArchiveContainer from "./containers/StudentArchiveContainer";
import StudentDetailContainer from "./containers/StudentDetailContainer";
import TeacherDetailContainer from "./containers/TeacherDetailContainer";
import NoteClassroomListContainer from "./containers/NoteClassroomListContainer";
import NoteCreateScoreContainer from "./containers/NoteCreateScoreContainer";
import ExtracurricularClassroomListContainer from "./containers/ExtracurricularClassroomListContainer";
import ExtracurricularClassroomDetailContainer from "./containers/ExtracurricularClassroomDetailContainer";
import TeacherClassroomListContainer from "./containers/TeacherClassroomListContainer";
import StatusClassroomListContainer from "./containers/StatusClassroomListContainer";
import AdminStatusClassroomListContainer from "./containers/AdminStatusClassroomListContainer";
import AdminLedgerClassroomListContainer from "./containers/AdminLedgerClassroomListContainer";
import StudentOutNoteFormContainter from "./containers/StudentOutNoteFormContainter";
import StudentOutNoteEditFormContainter from "./containers/StudentOutNoteEditFormContainter";
import StudentInNoteFormContainter from "./containers/StudentInNoteFormContainter";
import StudentInNoteEditFormContainter from "./containers/StudentInNoteEditFormContainter";
import OverBoxComponent from "./components/OverBoxComponent";
import AchievementClassroomListContainer from "./containers/AchievementClassroomListContainer";
import AchievementClassroomDetailContainer from "./containers/AchievementClassroomDetailContainer";
import NoticeListContainer from "./containers/NoticeListContainer";
import NoticeFormContainer from "./containers/NoticeFormContainer";
import NoticeEditFormContainer from "./containers/NoticeEditFormContainer";
import NoticeDetailContainer from "./containers/NoticeDetailContainer";
import ResetPasswordContainer from "./containers/ResetPasswordContainer";
import ForgotPasswordContainer from "./containers/ForgotPasswordContainer";
import ReportClassroomAdminContainer from "./containers/ReportClassroomAdminContainer";
import SuratListContainer from "./ismuba/surat/SuratListContainer";
import SuratFormContainer from "./ismuba/surat/SuratFormContainer";
import SuratEditFormContainer from "./ismuba/surat/SuratEditFormContainer";
import AddKemuhTeacherFormContainer from "./ismuba/kemuh/AddKemuhTeacherFormContainer";
import KemuhClassroomListContainer from "./ismuba/kemuh/KemuhClassroomListContainer";
import KemuhInputNilaiContainer from "./ismuba/kemuh/KemuhInputNilaiContainer";
import AddQuranTeacherFormContainer from "./ismuba/reguler/AddQuranTeacherFormContainer";
import QuranClassroomContainer from "./ismuba/components/QuranClassroomContainer";
import QuranStudentContainer from "./ismuba/components/QuranStudentContainer";
import QuranNilaiStudentContainer from "./ismuba/reguler/QuranNilaiStudentContainer";
import TahfidzNilaiStudentContainer from "./ismuba/tahfidz/TahfidzNilaiStudentContainer";
import IsmubaReportClassroomContainer from "./reports/IsmubaReportClassroomContainer";
import IsmubaReportClassroomDetailContainer from "./reports/IsmubaReportClassroomDetailContainer";
import IsmubaLedgerClassroomContainer from "./ismuba/reguler/IsmubaLedgerClassroomContainer";
import MarginContainer from "./configs/MarginContainer";
import ArchiveClassroomContainer from "./archives/ArchiveClassroomContainer";
import CompetenceScoreContainer from "./archives/CompetenceScoreContainer";
import ArchiveContainer from "./archives/ArchiveContainer";
import StudentListContainer from "./students/StudentListContainer";
import StudentImportUpdate from "./students/StudentImportUpdate";
import ClassroomSuratContainer from "./ismuba/reguler/ClassroomSuratContainer";
import InputNilaiSiswaReguler from "./ismuba/reguler/InputNilaiSiswaReguler";
import InputNilaiSiswaHafalTambahan from "./ismuba/reguler/InputNilaiSiswaHafalTambahan";

function App() {
  const [auth] = useRecoilState(authenticated);
  const [navbar, setNavbar] = useState(true);
  const location = useLocation();
  const viewWidth = window.innerWidth;

  const changeContainer = () => {
    if (viewWidth < 960) {
      return "container-fluid";
    } else if (auth.login === false) {
      return "container-fluid";
    } else if (navbar) {
      return "mycontainer";
    } else {
      return "container-fluid";
    }
  };

  const hideSideNavHandle = () => {
    if (viewWidth < 960) {
      setNavbar(!navbar);
    }
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      sessionStorage.setItem("lastpath", location.pathname);
    }
    // console.log(sessionStorage.getItem("lastpath"));
  }, [location]);

  return (
    <div>
      {auth.login ? (
        <Fragment>
          <NavbarComponent actionSideNav={() => setNavbar(!navbar)} />
          <SideNavbarComponent
            show={navbar}
            hideSideNav={() => hideSideNavHandle()}
          />
        </Fragment>
      ) : (
        ""
      )}
      <div className={changeContainer()}>
        <div className="row mt-5">
          <div className={`col ${auth.login ? "pt-5" : null}`}>
            <Routes>
              <Route path="/" exact element={<LoginFormContainer />} />
              <Route path="/forgot" element={<ForgotPasswordContainer />} />
              <Route
                path="/reset/:token"
                element={<ResetPasswordContainer />}
              />
              <Route path="/dashboards" element={<DashboardContainer />} />
              <Route
                path="/administrators"
                element={<AdministratorListContainer />}
              />
              <Route
                path="/administrators/create"
                element={<AdministratorFormContainer />}
              />
              <Route
                path="/administrators/:id/edit"
                element={<AdministratorEditFormContainer />}
              />
              <Route path="/academics" element={<AcademicListContainer />} />
              <Route
                path="/academics/create"
                element={<AcademicFormContainer />}
              />
              <Route
                path="/academics/:id/edit"
                element={<AcademicEditFormContainer />}
              />
              <Route
                path="/academics/classroom/set"
                element={<AcademicClassroomFormContainer />}
              />
              <Route
                path="/periods/:id/create"
                element={<PeriodFormContainer />}
              />
              <Route
                path="/periods/:id/edit"
                element={<PeriodEditFormContainer />}
              />
              <Route path="/categories" element={<CategorieListContainer />} />
              <Route
                path="/categories/create"
                element={<CategorieFormContainer />}
              />
              <Route
                path="/categories/:id/edit"
                element={<CategorieEditFormContainer />}
              />
              <Route
                path="/competences"
                element={<CompetenceClassroomListContainer />}
              />
              <Route
                path="/competences/classroomuser/:id"
                element={<CompetenceClassroomContainer />}
              />
              <Route
                path="/competences/classroomuser/:id/:categorie_id/create"
                element={<CompetenceFormContainer />}
              />
              <Route
                path="/competences/:id/:categorie_id/create"
                element={<CompetenceFormContainer />}
              />
              <Route
                path="/competences/:id"
                element={<CompetenceDetailContainer />}
              />
              <Route
                path="/competences/:id/edit"
                element={<CompetenceEditFormContainer />}
              />
              <Route
                path="/admin/attitudes"
                element={<AttitudeListContainer />}
              />
              <Route
                path="/admin/attitudes/create"
                element={<AttitudeFormContainer />}
              />
              <Route
                path="/admin/attitudes/:id/edit"
                element={<AttitudeEditFormContainer />}
              />
              <Route path="/attitudes" element={<AttitudeContainer />} />
              <Route
                path="/notes/attitude/:classroom_id/:academic_id"
                element={<AttitudeScoreContainer />}
              />
              <Route
                path="/competences/:id/recap"
                element={<CompetenceRecapContainer />}
              />
              <Route
                path="/attendances"
                element={<AttendanceListContainer />}
              />
              <Route
                path="/attendances/classroom/:id/create"
                element={<AttendanceFormContainer />}
              />
              <Route
                path="/attendances/:id/:period_id/detail"
                element={<AttendanceEditFormContainer />}
              />
              <Route
                path="/reports"
                element={<ReportClassroomListContainer />}
              />
              <Route
                path="/reports/:classroom_id/:academic_id/detail"
                element={<ReportClassroomDetailContainer />}
              />
              <Route
                path="/homerooms/spiritual"
                element={<SpiritualClassroomListContainer />}
              />
              <Route
                path="/homerooms/spiritual/:classroom_id/:academic_id/:period_id/create"
                element={<SpiritualScoreContainer />}
              />
              <Route
                path="/homerooms/social"
                element={<SocialClassroomListContainer />}
              />
              <Route
                path="/homerooms/social/:classroom_id/:academic_id/:period_id/create"
                element={<SocialCreateScoreContainer />}
              />
              <Route
                path="/homerooms/note"
                element={<NoteClassroomListContainer />}
              />
              <Route
                path="/homerooms/note/:classroom_id/:academic_id/:period_id/create"
                element={<NoteCreateScoreContainer />}
              />
              <Route
                path="/homerooms/ledger"
                element={<LedgerClassroomListContainer />}
              />
              <Route
                path="/homerooms/report"
                element={<ReportOnlyClassroomListContainer />}
              />
              <Route
                path="/homerooms/ismuba/report/:type"
                element={<IsmubaReportClassroomContainer />}
              />
              <Route
                path="/homerooms/ismuba/classroom/:classroom_id/academic/:academic_id/:type"
                element={<IsmubaReportClassroomDetailContainer />}
              />
              <Route
                path="/homerooms/extracurricular"
                element={<ExtracurricularClassroomListContainer />}
              />
              <Route
                path="/homerooms/extracurricular/:classroom_id/:academic_id/detail"
                element={<ExtracurricularClassroomDetailContainer />}
              />
              <Route
                path="/extracurriculars/:student_id/:classroom_id/:academic_id/detail"
                element={<ExtracurricularDetailContainer />}
              />
              <Route
                path="/extracurriculars/:student_id/:classroom_id/:academic_id/:period_id/create"
                element={<ExtracurricularFormContainer />}
              />
              <Route
                path="/extracurriculars/:id/edit"
                element={<ExtracurricularEditFormContainer />}
              />
              <Route
                path="/homerooms/achievement"
                element={<AchievementClassroomListContainer />}
              />
              <Route
                path="/homerooms/achievement/:classroom_id/:academic_id/detail"
                element={<AchievementClassroomDetailContainer />}
              />
              <Route
                path="/achievements/:student_id/:classroom_id/:academic_id/detail"
                element={<AchievementDetailContainer />}
              />
              <Route
                path="/achievements/:student_id/:classroom_id/:academic_id/:period_id/create"
                element={<AchievementFormContainer />}
              />
              <Route
                path="/achievements/:id/edit"
                element={<AchievementEditFormContainer />}
              />
              <Route
                path="/homerooms/status/score"
                element={<StatusClassroomListContainer />}
              />
              <Route
                path="/status/score"
                element={<AdminStatusClassroomListContainer />}
              />
              <Route path="/notices" element={<NoticeListContainer />} />
              <Route path="/notices/create" element={<NoticeFormContainer />} />
              <Route
                path="/notices/:id/edit"
                element={<NoticeEditFormContainer />}
              />
              <Route path="/notices/:id" element={<NoticeDetailContainer />} />
              <Route path="/users" element={<UserListContainer />} />
              <Route path="/users/create" element={<UserFormContainer />} />
              <Route path="/classrooms" element={<ClassroomListContainer />} />
              <Route
                path="/classrooms/create"
                element={<ClassroomFormContainer />}
              />
              <Route
                path="/classrooms/:id/edit"
                element={<ClassroomEditFormContainer />}
              />
              <Route
                path="/classrooms/:id/sks"
                element={<AddSksFormContainer />}
              />
              <Route
                path="/classrooms/:id/teacher"
                element={<AddTeacherFormContainer />}
              />
              <Route
                path="/classrooms/:id/homeroom"
                element={<AddHomeroomTeacherFormContainer />}
              />
              <Route
                path="/classrooms/:id/student"
                element={<AddStudentClassroomFormContainer />}
              />

              <Route
                path="/classrooms/teacher"
                element={<TeacherClassroomListContainer />}
              />
              <Route
                path="/classrooms/ledger"
                element={<AdminLedgerClassroomListContainer />}
              />
              <Route
                path="/classrooms/report"
                element={<ReportClassroomAdminContainer />}
              />
              <Route
                path="/classrooms/:id/kemuh"
                element={<AddKemuhTeacherFormContainer />}
              />
              <Route
                path="/classrooms/:id/quranteacher"
                element={<AddQuranTeacherFormContainer />}
              />
              <Route
                path="/classrooms/kemuh"
                element={<KemuhClassroomListContainer />}
              />
              <Route
                path="/regulers/classroom/:classroom_id/period/:period_id"
                element={<KemuhInputNilaiContainer />}
              />
              <Route
                path="/ledgers/:type"
                element={<IsmubaLedgerClassroomContainer />}
              />
              <Route
                path="/classrooms/ismuba/:type"
                element={<QuranClassroomContainer />}
              />
              <Route
                path="/qurans/classroom/:classroom_id/period/:period_id/surat"
                element={<ClassroomSuratContainer />}
              />
              <Route
                path="/qurans/classroom/:classroom_id/period/:period_id/surat/:surat_id"
                element={<InputNilaiSiswaReguler />}
              />
              <Route
                path="/qurans/hafalan/classroom/:classroom_id/period/:period_id"
                element={<InputNilaiSiswaHafalTambahan />}
              />
              <Route
                path="/qurans/classroom/:classroom_id/period/:period_id/:type"
                element={<QuranStudentContainer />}
              />
              <Route
                path="/qurans/classroom/:classroom_id/period/:period_id/student/:student_id/reguler"
                element={<QuranNilaiStudentContainer />}
              />

              <Route
                path="/qurans/classroom/:classroom_id/period/:period_id/student/:student_id/tahfidz"
                element={<TahfidzNilaiStudentContainer />}
              />
              <Route path="/surats" element={<SuratListContainer />} />
              <Route path="/surats/create" element={<SuratFormContainer />} />
              <Route
                path="/surats/:id/edit"
                element={<SuratEditFormContainer />}
              />
              <Route path="/clessons" element={<LessonGroupListContainer />} />
              <Route
                path="/clessons/create"
                element={<LessonGroupFormContainer />}
              />
              <Route
                path="/clessons/:id/edit"
                element={<LessonGroupEditFormContainer />}
              />
              <Route path="/lessons" element={<LessonsListContainer />} />
              <Route
                path="/lessons/create"
                element={<LessonsFormContainer />}
              />
              <Route
                path="/lessons/:id/edit"
                element={<LessonsEditFormContainer />}
              />
              <Route path="/students" element={<StudentListContainer />} />
              <Route
                path="/students/create"
                element={<StudentFormContainer />}
              />
              <Route
                path="/students/:id"
                element={<StudentDetailContainer />}
              />
              <Route
                path="/students/:id/edit"
                element={<StudentEditFormContainer />}
              />
              <Route
                path="/students/:id/out/create"
                element={<StudentOutNoteFormContainter />}
              />
              <Route
                path="/students/out/:id/edit"
                element={<StudentOutNoteEditFormContainter />}
              />
              <Route
                path="/students/:id/in/create"
                element={<StudentInNoteFormContainter />}
              />
              <Route
                path="/students/in/:id/edit"
                element={<StudentInNoteEditFormContainter />}
              />
              <Route
                path="/students/import/update"
                element={<StudentImportUpdate />}
              />
              <Route path="/teachers" element={<TeacherListContainer />} />
              <Route
                path="/teachers/create"
                element={<TeacherFormContainer />}
              />
              <Route
                path="/teachers/:id"
                element={<TeacherDetailContainer />}
              />
              <Route
                path="/teachers/:id/edit"
                element={<TeacherEditFormContainer />}
              />
              <Route path="/archives" element={<ArchiveContainer />} />
              <Route
                path="/archives/classroomuser/:id/competence"
                element={<ArchiveClassroomContainer />}
              />
              <Route
                path="/archives/competence/:id/classroom"
                element={<CompetenceScoreContainer />}
              />
              <Route
                path="/students/archive"
                element={<StudentArchiveContainer />}
              />
              <Route path="/margins" element={<MarginContainer />} />
            </Routes>
          </div>
        </div>
      </div>
      <div className="col mt-5">
        <div className="text-center footer text-light fw-bold">
          &copy; TIF SCHOOL {new Date().getFullYear()}
        </div>
      </div>
      <OverBoxComponent />
    </div>
  );
}

export default App;
