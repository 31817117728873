import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilValue } from "recoil";
import SmallLoadingComponent from "../../components/SmallLoadingComponent";
import { authenticated } from "../../store";
import AddQuranTeacherFormComponent from "./AddQuranTeacherFormComponent";

const AddQuranTeacherFormContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [classroom, setClassroom] = useState(false);
  const [errors, setErrors] = useState("");
  const { id } = useParams();
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get(
        "/regulers/classroom/" + id + "/quranteacher",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setClassroom(response.data.classroom);

      //   console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      {classroom ? (
        <Fragment>
          <div className="card-header">
            <h2>
              Guru Qur'an Kelas{" "}
              <span className="badge bg-success fs-4">{classroom.name}</span>{" "}
              <span className="badge bg-success fs-4 text-wrap">
                {classroom.academic.name}
              </span>
            </h2>
          </div>
          <div className="card-body">
            <div className="row">
              {errors.message ? (
                <div className="col">
                  <div className="alert alert-warning">{errors.message}</div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col">
                <AddQuranTeacherFormComponent
                  classroom={classroom}
                  updateData={() => getData()}
                />
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AddQuranTeacherFormContainer;
