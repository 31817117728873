import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import LoadingComponent from "../components/LoadingComponent";
import NoticeDetailComponent from "../components/NoticeDetailComponent";
import { authenticated } from "../store";

const NoticeDetailContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const redirect = useNavigate();
  const { id } = useParams();
  const [notice, setNotice] = useState(false);
  const [errors, setErrors] = useState([]);

  const findNoticeData = async () => {
    try {
      let response = await axios.get("notices/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setNotice(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    findNoticeData();
  }, [auth.login]);

  return (
    <div className="row">
      <div className="col-12 mb-4">
        {notice ? (
          <NoticeDetailComponent notice={notice} />
        ) : (
          <LoadingComponent />
        )}
      </div>
      <div className="col-12">
        <button
          className="btn btn-warning float-end"
          onClick={() => redirect(-1)}
        >
          BACK
        </button>
      </div>
    </div>
  );
};

export default NoticeDetailContainer;
