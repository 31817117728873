import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import NoticeEditFormComponent from "../components/NoticeEditFormComponent";
import { authenticated } from "../store";

const NoticeEditFormContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const redirect = useNavigate();

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Edit Pengumuman</h2>
      </div>
      <div className="card-body">
        <NoticeEditFormComponent />
      </div>
    </div>
  );
};

export default NoticeEditFormContainer;
