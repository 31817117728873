import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import PeriodFormComponent from "../components/PeriodFormComponent";
import { academicdetail, authenticated, perioddetail } from "../store";

const PeriodFormContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [academic] = useRecoilState(academicdetail);
  const [period, setPeriod] = useRecoilState(perioddetail);
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/academics/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setPeriod(response.data);
      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }
    setPeriod(false);
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Semester untuk {academic.name}</h2>
      </div>
      <div className="card-body">
        <PeriodFormComponent />
      </div>
    </div>
  );
};

export default PeriodFormContainer;
