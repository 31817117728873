import { atom } from "recoil";

export const authenticated = atom({
  key: "auth",
  default: { login: false, user: [] },
});

export const waiting = atom({
  key: "loading",
  default: false,
});

export const statusadminlist = atom({
  key: "statusadmins",
  default: true,
});

export const dashboardlist = atom({
  key: "dashboards",
  default: true,
});

export const skslist = atom({
  key: "skss",
  default: true,
});

export const administratorlist = atom({
  key: "administrators",
  default: false,
});

export const administratordetail = atom({
  key: "administrator",
  default: false,
});

export const academiclist = atom({
  key: "academics",
  default: false,
});

export const academicdetail = atom({
  key: "academic",
  default: false,
});

export const academicthis = atom({
  key: "academic_id",
  default: false,
});

export const perioddetail = atom({
  key: "period",
  default: false,
});

export const categorielist = atom({
  key: "categories",
  default: false,
});

export const categoriedetail = atom({
  key: "categorie",
  default: false,
});

export const comboboxteacher = atom({
  key: "optionsteacher",
  default: false,
});

export const teacherlist = atom({
  key: "teachers",
  default: false,
});

export const teachersinclasslist = atom({
  key: "teachersinclass",
  default: false,
});

export const teacherdetail = atom({
  key: "teacher",
  default: false,
});

export const userslist = atom({
  key: "users",
  default: false,
});

export const classroomslist = atom({
  key: "classrooms",
  default: false,
});

export const classroomshow = atom({
  key: "classroomdetail",
  default: false,
});

export const classroomprops = atom({
  key: "classroomheader",
  default: false,
});

export const classroomsteacher = atom({
  key: "classroomsteach",
  default: false,
});

export const attendancelist = atom({
  key: "attendances",
  default: false,
});

export const attendancehow = atom({
  key: "attendance",
  default: false,
});

export const studentlist = atom({
  key: "students",
  default: false,
});

export const studentdetail = atom({
  key: "student",
  default: false,
});

export const lessonslist = atom({
  key: "lessons",
  default: false,
});

export const lessonshow = atom({
  key: "lessondetail",
  default: false,
});

export const lcategorielist = atom({
  key: "lcategories",
  default: false,
});

export const lcategoriedetail = atom({
  key: "lcategorie",
  default: false,
});

export const positionslist = atom({
  key: "positions",
  default: false,
});

export const curriculumslist = atom({
  key: "curriculums",
  default: false,
});

export const criteriaslist = atom({
  key: "criterias",
  default: false,
});

export const criteriadetail = atom({
  key: "criteria",
  default: false,
});

export const competencelist = atom({
  key: "competences",
  default: false,
});

export const competencedetail = atom({
  key: "competence",
  default: false,
});

export const nilailist = atom({
  key: "nilais",
  default: false,
});

export const scorelist = atom({
  key: "scores",
  default: false,
});

export const scoredetail = atom({
  key: "score",
  default: false,
});

export const adminattitudelist = atom({
  key: "adminattitudes",
  default: false,
});

export const adminattitudeshow = atom({
  key: "adminattitude",
  default: false,
});

export const attitudelist = atom({
  key: "attitudes",
  default: false,
});

export const reportlist = atom({
  key: "reports",
  default: false,
});

export const reportshow = atom({
  key: "report",
  default: false,
});

export const homeroomscorelist = atom({
  key: "homeroomscores",
  default: false,
});

export const ptsdetail = atom({
  key: "pts",
  default: false,
});

export const pasdetail = atom({
  key: "pas",
  default: false,
});

export const extralist = atom({
  key: "extras",
  default: false,
});

export const extradetail = atom({
  key: "extra",
  default: false,
});

export const achievementlist = atom({
  key: "achievements",
  default: false,
});

export const achievementdetail = atom({
  key: "achievement",
  default: false,
});

export const archivelist = atom({
  key: "archives",
  default: false,
});

export const resurats = atom({
  key: "surats",
  default: [],
});

export const disablemenu = atom({
  key: "disable",
  default: false,
});
