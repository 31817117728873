import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const AttitudeComponent = (props) => {
  const [errors, setErrors] = useState("");

  const delData = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/competences/" + id, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Your work has been deleted",
                  showConfirmButton: false,
                  timer: 1500,
                });
                props.updateData();
              }
            });
        }
      });

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  return (
    <div className="col p-0 mb-2">
      <div className="p-3">
        <ul className="list-group pe-0 mb-4">
          <li className="list-group-item active" aria-current="true">
            <b>Kelas {props.data.name}</b>
            <br />
            <Link
              to={
                "/competences/" +
                props.data.id +
                "/" +
                props.categorie_id +
                "/create"
              }
            >
              <button className="btn btn-warning float-end">
                Tambah Kompetensi
              </button>
            </Link>
          </li>
          {/* {props.data.competences.map((row, index) => (
            <li key={index} className="list-group-item">
              <Link to={"/competences/" + row.id}>
                <span className="badge bg-success">
                  {row.name} | {row.period.name}
                </span>
              </Link>
              <span
                className="badge bg-danger float-end pointer"
                onClick={() => delData(row.id)}
              >
                X
              </span>
            </li>
          ))} */}
          <li className="list-group-item bg-secondary"></li>
        </ul>
      </div>
    </div>
  );
};

export default AttitudeComponent;
