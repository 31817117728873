import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, competencedetail } from "../store";

const CompetenceEditFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [competence] = useRecoilState(competencedetail);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [minimum_score, setMinimum_score] = useState("");
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const { id } = useParams();

  const record = {
    name,
    description,
    minimum_score,
    type,
  };

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.put("/competences/" + id, record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setErrors([]);
      }
      //   console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  const handleScore = (value) => {
    const onlyNumbers = value.replace(/[^\d.]/g, "");
    if (onlyNumbers === "NaN") {
      onlyNumbers = 0;
    }
    setMinimum_score(onlyNumbers);
  };

  const handleNull = (value) => {
    if (value === null) {
      value = "";
    } else {
      value = value;
    }

    return value;
  };

  useEffect(() => {
    if (competence) {
      setName(competence?.detail.name);
      setDescription(competence?.detail.description);
      setMinimum_score(competence?.detail.minimum_score);
      setType(competence?.detail.type);
    }
    // console.log(students);
  }, [auth.login]);

  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-6 table-responsive">
          <table className="table">
            <tbody>
              <tr>
                <td>Nama</td>
                <td>:</td>
                <td>{auth.user?.name}</td>
              </tr>
              <tr>
                <td>Kelas</td>
                <td>:</td>
                <td>
                  <span className="badge bg-success fs-6">
                    {competence?.detail.classroom.name}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Kategori</td>
                <td>:</td>
                <td>{competence?.detail.categorie.name}</td>
              </tr>
              <tr>
                <td>Pelajaran</td>
                <td>:</td>
                <td>{competence?.detail.lesson.name}</td>
              </tr>
              <tr>
                <td>Semester</td>
                <td>:</td>
                <td>
                  <span className="badge bg-success fs-6">
                    {competence?.detail.period.name}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Tahun Ajaran</td>
                <td>:</td>
                <td>{competence?.detail.academic.nickname}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mb-5">
        <form onSubmit={(e) => formHandle(e)}>
          <div className="row mb-4">
            <div className="col-md-6 col-xs-12 mb-2">
              <label htmlFor="nama" className="form-label">
                Nama Kompetensi
              </label>
              <input
                className={`form-control mb-1 ${
                  errors.name ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Nama"
                id="nama"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name ? (
                <div className="invalid-feedback">{errors.name[0]}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 col-xs-12 mb-2">
              <label htmlFor="description" className="form-label">
                Deskripsi
              </label>
              <textarea
                className={`form-control mb-1 ${
                  errors.description ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Deskripsi"
                id="description"
                value={handleNull(description)}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              {errors.description ? (
                <div className="invalid-feedback">{errors.description[0]}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 col-xs-12 mb-2">
              <label htmlFor="minimum_score" className="form-label">
                Nilai Minimal
              </label>
              {competence?.detail.categorie.score_type === "tulisan" ? (
                <select
                  className={`form-select mb-1 ${
                    errors.minimum_score ? "is-invalid" : ""
                  }`}
                  type="text"
                  id="minimum_score"
                  value={minimum_score}
                  onChange={(e) => setMinimum_score(e.target.value)}
                >
                  <option value="">===Pilih Nilai Minimal===</option>
                  <option value="sk">Sangat Kurang</option>
                  <option value="k">Kurang</option>
                  <option value="c">Cukup</option>
                  <option value="b">Baik</option>
                  <option value="sb">Sangat Baik</option>
                </select>
              ) : (
                <input
                  className={`form-control mb-1 ${
                    errors.minimum_score ? "is-invalid" : ""
                  }`}
                  type="text"
                  placeholder="Nilai Minimal"
                  id="minimum_score"
                  value={minimum_score}
                  onChange={(e) => handleScore(e.target.value)}
                />
              )}
              {errors.minimum_score ? (
                <div className="invalid-feedback">
                  {errors.minimum_score[0]}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 col-xs-12 mb-4">
              <label htmlFor="type" className="form-label">
                Kode
              </label>
              <select
                className={`form-select mb-1 ${
                  errors.type ? "is-invalid" : ""
                }`}
                type="text"
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">===Pilih Kode===</option>
                {competence?.detail.categorie.name.toLowerCase() ===
                "keterampilan" ? (
                  <option value="KD">KD</option>
                ) : (
                  <Fragment>
                    <option value="KD">KD</option>
                    <option value="PTS">PTS</option>
                    <option value="PAS">PAS</option>
                  </Fragment>
                )}
              </select>
              {errors.type ? (
                <div className="invalid-feedback">{errors.type[0]}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary float-end">
                UPDATE
              </button>

              <button
                type="button"
                onClick={() => redirect(-1)}
                className="btn btn-warning mx-4 float-end"
              >
                BACK
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompetenceEditFormComponent;
