import React from "react";

const PaginationComponent = (props) => {
  const page = (url = null) => {
    if (url !== null) {
      let link = url.split("?", 2);
      // console.log(url);
      return link[1];
    }
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination flex-wrap">
        {props.data.map((link, index) => (
          <li
            key={index}
            className={link.active ? "page-item active" : "page-item"}
          >
            <a
              className="page-link"
              onClick={() => props.handlePaginate(page(link.url))}
              href={"#page/" + page(link.url)}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: link.label,
                }}
              />
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default PaginationComponent;
