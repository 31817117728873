import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import NoticeListComponent from "../components/NoticeListComponent";
import { authenticated } from "../store";

const NoticeListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [notices, setNotices] = useState([]);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/notices?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setNotices(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const handleSearch = (e) => {
    const src = "src=" + e.target.value;
    getData(src);
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    getData();
  }, [auth.login, notices.total]);

  return (
    <div className="card">
      <div className="card-header mb-4">
        <h2>List Pengumuman</h2>
      </div>
      <div className="card-body">
        <div className="row mb-4">
          <div className="col">
            <input
              type="text"
              className="form-control float-end mb-4 col-2"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <div className="col">
            <Link to="/notices/create">
              <button className="btn btn-success float-end">CREATE</button>
            </Link>
          </div>
        </div>
        <div className="row">
          {notices.data?.length > 0 ? (
            <NoticeListComponent
              updateData={(option) => getData(option)}
              notices={notices}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NoticeListContainer;
