import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userslist } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";
import PaginationComponent from "./PaginationComponent";

const UserListComponent = () => {
  const [users, setUsers] = useRecoilState(userslist);
  const [errors, setErrors] = useState("");

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/users?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setUsers(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const handleSearch = (e) => {
    const src = "src=" + e.target.value;
    getData(src);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="card-header">
        <h2>List Users</h2>
      </div>
      {errors ? errors : ""}
      {users.data ? (
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <Link to="/users/create">
                <button className="btn btn-success mb-4">Create</button>
              </Link>
            </div>
            <div className="col-4 offset-2">
              <input
                type="text"
                className="form-control float-end mb-4 col-2"
                placeholder="Search..."
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nama</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {users.data.map((row, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{row.nama}</td>
                  <td>{row.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <PaginationComponent
            data={users.links}
            handlePaginate={(page) => getData(page)}
          />
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default UserListComponent;
