import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingComponent from "../components/LoadingComponent";
import PeriodEditFormComponent from "../components/PeriodEditFormComponent";

const PeriodEditFormContainer = () => {
  const [period, setPeriod] = useState("");
  const [errors, setErrors] = useState([]);
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/periods/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setPeriod(response.data);
      }
      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Edit Semester {period?.name}</h2>
      </div>
      <div className="card-body">
        {period ? (
          <PeriodEditFormComponent period={period} />
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  );
};

export default PeriodEditFormContainer;
