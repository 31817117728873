import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import ExtracurricularClassroomDetailComponent from "../components/ExtracurricularClassroomDetailComponent";
import LoadingComponent from "../components/LoadingComponent";
import { authenticated } from "../store";

const ExtracurricularClassroomDetailContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [classroom, setClassroom] = useState(false);
  const [errors, setErrors] = useState([]);
  const { classroom_id, academic_id } = useParams();
  const params = classroom_id + "/" + academic_id + "/detail";
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/reports/" + params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassroom(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      {classroom ? (
        <ExtracurricularClassroomDetailComponent data={classroom} />
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default ExtracurricularClassroomDetailContainer;
