import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import ExtracurricularDetailComponent from "../components/ExtracurricularDetailComponent";
import LoadingComponent from "../components/LoadingComponent";
import { authenticated } from "../store";

const ExtracurricularDetailContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [extras, setExtras] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { student_id, classroom_id, academic_id } = useParams();
  const params = student_id + "/" + classroom_id + "/" + academic_id;

  const getData = async () => {
    try {
      let response = await axios.get("/extracurriculars/" + params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setExtras(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }

    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Extrakurikuler Siswa{" "}
          <span className="badge bg-success fs-5">{extras?.student?.name}</span>
        </h2>
      </div>
      {extras ? (
        <ExtracurricularDetailComponent
          extras={extras}
          updateData={() => getData()}
        />
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default ExtracurricularDetailContainer;
