import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import AdministratorEditFormComponent from "../components/AdministratorEditFormComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { administratordetail } from "../store";

const AdministratorEditFormContainer = () => {
  const [administrator, setAdministrator] = useRecoilState(administratordetail);
  const [errors, setErrors] = useState("");
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/administrators/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAdministrator(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Edit Administrator</h2>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            {administrator ? (
              <AdministratorEditFormComponent />
            ) : (
              <SmallLoadingComponent />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdministratorEditFormContainer;
