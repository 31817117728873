import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import AlertComponent from "../components/AlertComponent";
import ReportClassroomAdminComponent from "../components/ReportClassroomAdminComponent";
import ReportIsmubaAdminComponent from "../reports/ReportIsmubaAdminComponent";
import { authenticated, waiting } from "../store";

const ReportClassroomAdminContainer = () => {
  const auth = useRecoilValue(authenticated);
  const setLoading = useSetRecoilState(waiting);
  const [classrooms, setClassrooms] = useState([]);
  const [classroom_id, setClassroom_id] = useState("");
  const [academics, setAcademics] = useState([]);
  const [academic_id, setAcademic_id] = useState("");
  const [type, setType] = useState("");
  const [report, setReport] = useState(false);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const getClassrooms = async () => {
    try {
      let response = await axios.get("/classrooms", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassrooms(response.data.data);

      // console.log(response);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const getAcademics = async (classroom_id) => {
    try {
      setLoading(true);
      if (classroom_id !== "") {
        setClassroom_id(classroom_id);
        setAcademic_id("");
        let response = await axios.get("/academics/classroom/" + classroom_id, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });

        setAcademics(response.data.academics);
        setReport(false);
        setErrors([]);
      }

      //   console.log(response.data.academics);
      setLoading(false);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
      // console.log(e.response.data);
    }
  };

  const getReportData = async (academic_id) => {
    try {
      setLoading(true);
      setAcademic_id(academic_id);
      const params = classroom_id + "/" + academic_id;
      let response = await axios.get("/reports/" + params + "/detail", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setReport(response.data);
      setErrors([]);

      setLoading(false);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }

    getClassrooms();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Rapot Kelas</h2>
      </div>
      <div className="card-body">
        <div className="col-12 mb-4">
          <div className="row">
            {errors.message ? (
              <AlertComponent color={"danger"} message={errors.message} />
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <label htmlFor="classroom_id" className="mb-2">
                Tipe Rapot
              </label>
              <select
                name="classroom_id"
                id="classroom_id"
                className="form-select"
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">==Pilih Type==</option>
                <option value="akademik">Akademik</option>
                <option value="ismuba">Ismuba</option>
              </select>
            </div>
            <div className="col-xs-12 col-md-4">
              <label htmlFor="classroom_id" className="mb-2">
                Kelas
              </label>
              <select
                name="classroom_id"
                id="classroom_id"
                className="form-select"
                onChange={(e) => getAcademics(e.target.value)}
              >
                <option value="">==Pilih Kelas==</option>
                {classrooms.map((classroom, index) => (
                  <option key={index} value={classroom.id}>
                    {classroom.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xs-12 col-md-4">
              <label htmlFor="academic_id" className="mb-2">
                Tahun Ajaran
              </label>
              <select
                name="academic_id"
                id="academic_id"
                className="form-select"
                onChange={(e) => getReportData(e.target.value)}
                value={academic_id}
              >
                <option value="">==Pilih Tahun Ajaran==</option>
                {academics.map((row, index) => (
                  <option key={index} value={row.academic_id}>
                    {row.academic.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
        <div className="col-12">
          {type === "akademik" ? (
            <Fragment>
              {report ? (
                <ReportClassroomAdminComponent
                  classroom={report.classroom}
                  academic={report.academic}
                  students={report.students}
                />
              ) : (
                <div className="alert alert-warning">
                  Pilih Kelas Dan Tahun Ajaran
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {type === "ismuba" ? (
                <Fragment>
                  {report ? (
                    <ReportIsmubaAdminComponent
                      classroom={report.classroom}
                      academic={report.academic}
                      students={report.students}
                    />
                  ) : (
                    <div className="alert alert-warning">
                      Pilih Kelas Dan Tahun Ajaran
                    </div>
                  )}
                </Fragment>
              ) : (
                <div className="alert alert-warning">Pilih Tipe Rapot</div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportClassroomAdminContainer;
