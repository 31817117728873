import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated } from "../store";

const StudentFormComponent = (props) => {
  const [nisn, setNisn] = useState("");
  const [nis, setNis] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const record = { nisn, nis, name, gender };
  const [errors, setErrors] = useState([]);
  const [auth, setAuth] = useRecoilState(authenticated);
  const redirect = useNavigate();

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post("/students", record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setNisn("");
        setNis("");
        setName("");
        setGender("");
        setErrors([]);
      }

      //   console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col-md-6 col-xs-12 mb-2">
          <label htmlFor="nisn" className="form-label">
            NISN
          </label>
          <input
            className={`form-control mb-1 ${errors.nisn ? "is-invalid" : ""}`}
            type="text"
            placeholder="NISN"
            id="nisn"
            value={nisn}
            onChange={(e) => setNisn(e.target.value)}
          />
          {errors.nisn ? (
            <div className="invalid-feedback">{errors.nisn[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-xs-12 mb-2">
          <label htmlFor="nis" className="form-label">
            NIS
          </label>
          <input
            className={`form-control mb-1 ${errors.nis ? "is-invalid" : ""}`}
            type="text"
            placeholder="NIS"
            id="nis"
            value={nis}
            onChange={(e) => setNis(e.target.value)}
          />
          {errors.nis ? (
            <div className="invalid-feedback">{errors.nis[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-xs-12 mb-2">
          <label htmlFor="nama" className="form-label">
            Nama Siswa
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-xs-12 mb-4">
          <label htmlFor="gender" className="form-label">
            Gender
          </label>
          <select
            className={`form-select mb-1 ${errors.gender ? "is-invalid" : ""}`}
            id="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="">Pilih Gender</option>
            <option value="Laki - Laki">Laki - Laki</option>
            <option value="Perempuan">Perempuan</option>
          </select>
          {errors.gender ? (
            <div className="invalid-feedback">{errors.gender[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary float-end">
            SUBMIT
          </button>

          <Link to="/students">
            <button className="btn btn-warning mx-4 float-end">BACK</button>
          </Link>
        </div>
      </div>
    </form>
  );
};

export default StudentFormComponent;
