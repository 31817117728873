import Button from "react-bootstrap/Button";
import React from "react";
import { useNavigate } from "react-router-dom";

const KemuhInputNilaiComponent = ({
  students,
  updateStudent,
  refresh,
  updateKemuh,
}) => {
  const redirect = useNavigate();

  const inputHandle = (input, index) => {
    const inputName = input.name;
    let inputValue = input.value.replace(/[^\d.]/g, "");
    if (isNaN(inputValue)) inputValue = 0;

    const update = { ...students[index] };
    update[inputName] = inputValue;
    students[index] = update;

    updateStudent(students);
    refresh();
  };

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12 table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="text-center">
                <th>#</th>
                <th>NAMA</th>
                <th>Pengetahuan Kemuhammadiyahan</th>
                <th>Keterampilan Kemuhammadiyahan</th>
                <th>Project Kemuhammadiyahan</th>
                <th>Ibadah Praktis</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{student.name}</td>
                  <td className="text-center">
                    <input
                      type="text"
                      maxLength="5"
                      className="form-control text-center"
                      name="peng_kemuh"
                      value={student.peng_kemuh}
                      onChange={(e) => inputHandle(e.target, index)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="text"
                      maxLength="5"
                      className="form-control text-center"
                      name="ket_kemuh"
                      value={student.ket_kemuh}
                      onChange={(e) => inputHandle(e.target, index)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="text"
                      maxLength="5"
                      className="form-control text-center"
                      name="project_kemuh"
                      value={student.project_kemuh}
                      onChange={(e) => inputHandle(e.target, index)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="text"
                      maxLength="5"
                      className="form-control text-center"
                      name="ibadah_praktis"
                      value={student.ibadah_praktis}
                      onChange={(e) => inputHandle(e.target, index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <Button variant="warning float-start" onClick={() => redirect(-1)}>
            BACK
          </Button>
          <Button variant="success float-end" onClick={() => updateKemuh()}>
            UPDATE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default KemuhInputNilaiComponent;
