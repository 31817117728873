import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, perioddetail } from "../store";

const PeriodFormComponent = () => {
  const { id } = useParams();
  const [auth, setAuth] = useRecoilState(authenticated);
  const [period] = useRecoilState(perioddetail);
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const [academic_id] = useState(id);
  const [name, setName] = useState("");
  const [group_name, setGroup_name] = useState("");
  const [curriculum, setCurriculum] = useState("");
  const [report_date_pts, setReport_date_pts] = useState();
  const [report_date_pas, setReport_date_pas] = useState();
  const [headmaster_name, setHeadmaster_name] = useState("");
  const [headmaster_nip, setHeadmaster_nip] = useState("");
  const [active, setActive] = useState("false");

  const record = {
    academic_id,
    name,
    group_name,
    curriculum,
    report_date_pts,
    report_date_pas,
    headmaster_name,
    headmaster_nip,
    active,
  };

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post("/periods", record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setName("");
        setGroup_name("");
        setHeadmaster_name("");
        setHeadmaster_nip("");
        setCurriculum("");
        setReport_date_pts("");
        setReport_date_pas("");
        setActive("false");
        setErrors([]);
      }

      //   console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    // console.log(record);
  }, [auth.login]);

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col-xs-12 col-md-4 mb-2">
          <label htmlFor="nama" className="form-label">
            Nama Semester
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            name="name"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-xs-12 col-md-4 mb-2">
          <label htmlFor="group_name" className="form-label">
            Nama Group Kelas
          </label>
          <select
            className={`form-select mb-1 ${
              errors.group_name ? "is-invalid" : ""
            }`}
            type="text"
            name="group_name"
            id="group_name"
            value={group_name}
            onChange={(e) => setGroup_name(e.target.value)}
          >
            <option>==Pilih Nama Group===</option>
            {Array.from(Array(period.max_group).keys()).map((row, index) => (
              <option key={index} value={"Group Kelas " + (index + 10)}>
                {"Group Kelas " + (index + 10)}
              </option>
            ))}
          </select>
          {errors.group_name ? (
            <div className="invalid-feedback">{errors.group_name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-xs-12 col-md-4 mb-2">
          <label htmlFor="curriculum" className="form-label">
            Kurikulum
          </label>
          <input
            className={`form-control mb-1 ${
              errors.curriculum ? "is-invalid" : ""
            }`}
            type="text"
            name="curriculum"
            placeholder="Nama Kurikulum"
            id="curriculum"
            value={curriculum}
            onChange={(e) => setCurriculum(e.target.value)}
          />
          {errors.curriculum ? (
            <div className="invalid-feedback">{errors.curriculum[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-xs-12 col-md-6 mb-2">
          <label htmlFor="headmaster_name" className="form-label">
            Nama Kepala Sekolah
          </label>
          <input
            className={`form-control mb-1 ${
              errors.headmaster_name ? "is-invalid" : ""
            }`}
            type="text"
            name="headmaster_name"
            placeholder="Nama kepala sekolah"
            id="headmaster_name"
            value={headmaster_name}
            onChange={(e) => setHeadmaster_name(e.target.value)}
          />
          {errors.headmaster_name ? (
            <div className="invalid-feedback">{errors.headmaster_name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-xs-12 col-md-6 mb-2">
          <label htmlFor="headmaster_nip" className="form-label">
            NBM Kepala Sekolah
          </label>
          <input
            className={`form-control mb-1 ${
              errors.headmaster_nip ? "is-invalid" : ""
            }`}
            type="text"
            name="headmaster_nip"
            placeholder="NBM kepala sekolah"
            id="headmaster_nip"
            value={headmaster_nip}
            onChange={(e) => setHeadmaster_nip(e.target.value)}
          />
          {errors.headmaster_nip ? (
            <div className="invalid-feedback">{errors.headmaster_nip[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-xs-12 col-md-6 mb-2">
          <label htmlFor="report_date_pts" className="form-label">
            Tanggal Rapot PTS
          </label>
          <input
            className={`form-control mb-1 ${
              errors.report_date_pts ? "is-invalid" : ""
            }`}
            type="date"
            name="report_date_pts"
            id="report_date_pts"
            onChange={(e) => setReport_date_pts(e.target.value)}
          />
          {errors.report_date_pts ? (
            <div className="invalid-feedback">{errors.report_date_pts[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col-xs-12 col-md-6 mb-2">
          <label htmlFor="report_date_pas" className="form-label">
            Tanggal Rapot PAS
          </label>
          <input
            className={`form-control mb-1 ${
              errors.report_date_pas ? "is-invalid" : ""
            }`}
            type="date"
            name="report_date_pas"
            id="report_date_pas"
            onChange={(e) => setReport_date_pas(e.target.value)}
          />
          {errors.report_date_pas ? (
            <div className="invalid-feedback">{errors.report_date_pas[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label htmlFor="active" className="form-label">
            Semester Active
          </label>
          <select
            className={`form-select mb-1 ${errors.active ? "is-invalid" : ""}`}
            type="date"
            name="active"
            id="active"
            onChange={(e) => setActive(e.target.value)}
          >
            <option value={"false"}>Belum Active</option>
            <option value={"true"}>Active</option>
          </select>
          {errors.active ? (
            <div className="invalid-feedback">{errors.active[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <button type="submit" className="btn btn-primary float-end">
        SUBMIT
      </button>

      <Link to={"/academics/" + id + "/edit"}>
        <button className="btn btn-warning mx-4 float-end">BACK</button>
      </Link>
    </form>
  );
};

export default PeriodFormComponent;
