import React from "react";
import ResetPasswordComponent from "../components/ResetPasswordComponent";

const ResetPasswordContainer = () => {
  return (
    <div className="row justify-content-lg-center">
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header">
            <h2>Reset Password</h2>
          </div>
          <div className="card-body">
            <ResetPasswordComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordContainer;
