import React, { Fragment } from "react";

const StatusNilaiAkademikComponent = ({ classroomusers }) => {
  return (
    <Fragment>
      <div className="col-12">
        <h3 className="badge bg-success fs-5">Status Nilai Akademik</h3>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr className="text-center">
            <th>PELAJARAN</th>
            <th>NAMA GURU</th>
            <th>STATUS NILAI</th>
          </tr>
        </thead>
        <tbody>
          {classroomusers.map((row, index) => (
            <tr key={index}>
              <td>{row.lesson}</td>
              <td>{row.teacher}</td>
              <td className="text-center">
                <span
                  className={`badge ${
                    row.score_status === "selesai"
                      ? "bg-success"
                      : "bg-warning text-dark"
                  } text-uppercase fs-6`}
                >
                  {row.score_status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default StatusNilaiAkademikComponent;
