import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, studentdetail } from "../store";
import StudentInNoteList from "./StudentInNoteList";
import StudentOutNoteList from "./StudentOutNoteList";

const StudentEditFormComponent = (props) => {
  const [student, setStudent] = useRecoilState(studentdetail);
  const [nisn, setNisn] = useState("");
  const [nis, setNis] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [agama, setAgama] = useState("");
  const [place_born, setPlace_born] = useState("");
  const [status_in_family, setStatus_in_family] = useState("");
  const [anak_ke, setAnak_ke] = useState("");
  const [student_address, setStudent_address] = useState("");
  const [home_phone, setHome_phone] = useState("");
  const [from_school, setFrom_school] = useState("");
  const [class_accept_this_school, setClass_accept_this_school] = useState("");
  const [accept_this_school_date, setAccept_this_school_date] = useState("");
  const [father_name, setFather_name] = useState("");
  const [mother_name, setMother_name] = useState("");
  const [parent_address, setParent_address] = useState("");
  const [parent_phone, setParent_phone] = useState("");
  const [father_profession, setFather_profession] = useState("");
  const [mother_profession, setMother_profession] = useState("");
  const [student_guard_name, setStudent_guard_name] = useState("");
  const [student_guard_phone, setStudent_guard_phone] = useState("");
  const [student_guard_address, setStudent_guard_address] = useState("");
  const [student_guard_profession, setStudent_guard_profession] = useState("");
  const [document_date, setDocument_date] = useState("");
  const [headmaster_name, setHeadmaster_name] = useState("");
  const [headmaster_nbm, setHeadmaster_nbm] = useState("");
  const [errors, setErrors] = useState([]);
  const [auth, setAuth] = useRecoilState(authenticated);
  const { id } = useParams();
  const redirect = useNavigate();
  const record = {
    nisn,
    nis,
    name,
    gender,
    agama,
    status_in_family,
    place_born,
    anak_ke,
    student_address,
    home_phone,
    from_school,
    class_accept_this_school,
    accept_this_school_date,
    father_name,
    mother_name,
    parent_address,
    parent_phone,
    father_profession,
    mother_profession,
    student_guard_name,
    student_guard_phone,
    student_guard_address,
    student_guard_profession,
    document_date,
    headmaster_name,
    headmaster_nbm,
  };

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.put("/students/" + id, record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      //   console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
    if (student) {
      setNisn(student.nisn);
      setNis(student.nis);
      setName(student.name);
      setGender(student.gender);
      setAgama(student.agama);
      setPlace_born(student.place_born);
      setStatus_in_family(student.status_in_family);
      setAnak_ke(student.anak_ke);
      setStudent_address(student.student_address);
      setHome_phone(student.home_phone);
      setFrom_school(student.from_school);
      setClass_accept_this_school(student.class_accept_this_school);
      setAccept_this_school_date(student.accept_this_school_date);
      setFather_name(student.father_name);
      setMother_name(student.mother_name);
      setParent_address(student.parent_address);
      setParent_phone(student.parent_phone);
      setFather_profession(student.father_profession);
      setMother_profession(student.mother_profession);
      setStudent_guard_name(student.student_guard_name);
      setStudent_guard_phone(student.student_guard_phone);
      setStudent_guard_address(student.student_guard_address);
      setStudent_guard_profession(student.student_guard_profession);
      setDocument_date(student.document_date);
      setHeadmaster_name(student.headmaster_name);
      setHeadmaster_nbm(student.headmaster_nbm);
    }
  }, [auth.login]);

  return (
    <div>
      <form onSubmit={(e) => formHandle(e)}>
        <div className="row mb-4">
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="nisn" className="form-label">
              NISN
            </label>
            <input
              className={`form-control mb-1 ${errors.nisn ? "is-invalid" : ""}`}
              type="text"
              placeholder="NISN"
              id="nisn"
              value={nisn}
              onChange={(e) => setNisn(e.target.value)}
            />
            {errors.nisn ? (
              <div className="invalid-feedback">{errors.nisn[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="nis" className="form-label">
              NIS
            </label>
            <input
              className={`form-control mb-1 ${errors.nis ? "is-invalid" : ""}`}
              type="text"
              placeholder="NIS"
              id="nis"
              value={nis}
              onChange={(e) => setNis(e.target.value)}
            />
            {errors.nis ? (
              <div className="invalid-feedback">{errors.nis[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="nama" className="form-label">
              Nama Siswa
            </label>
            <input
              className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
              type="text"
              placeholder="Agama"
              id="nama"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name ? (
              <div className="invalid-feedback">{errors.name[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="gender" className="form-label">
              Gender
            </label>
            <select
              className={`form-select mb-1 ${
                errors.gender ? "is-invalid" : ""
              }`}
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="">Pilih Gender</option>
              <option value="Laki - Laki">Laki - Laki</option>
              <option value="Perempuan">Perempuan</option>
            </select>
            {errors.gender ? (
              <div className="invalid-feedback">{errors.gender[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="agama" className="form-label">
              Agama
            </label>
            <input
              className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
              type="text"
              placeholder="Nama"
              id="agama"
              value={agama}
              onChange={(e) => setAgama(e.target.value)}
            />
            {errors.agama ? (
              <div className="invalid-feedback">{errors.agama[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="place_born" className="form-label">
              Tempat, Tanggal Lahir
            </label>
            <input
              className={`form-control mb-1 ${
                errors.place_born ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Tempat, Tanggal Lahir"
              id="place_born"
              value={place_born}
              onChange={(e) => setPlace_born(e.target.value)}
            />
            {errors.place_born ? (
              <div className="invalid-feedback">{errors.place_born[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="status_in_family" className="form-label">
              Status Dalam Keluarga
            </label>
            <input
              className={`form-control mb-1 ${
                errors.status_in_family ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Status Dalam Keluarga"
              id="status_in_family"
              value={status_in_family}
              onChange={(e) => setStatus_in_family(e.target.value)}
            />
            {errors.status_in_family ? (
              <div className="invalid-feedback">
                {errors.status_in_family[0]}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="anak_ke" className="form-label">
              Anak ke
            </label>
            <input
              className={`form-control mb-1 ${
                errors.anak_ke ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Anak ke"
              id="anak_ke"
              value={anak_ke}
              onChange={(e) => setAnak_ke(e.target.value)}
            />
            {errors.anak_ke ? (
              <div className="invalid-feedback">{errors.anak_ke[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="student_address" className="form-label">
              Alamat Peserta Didik
            </label>
            <input
              className={`form-control mb-1 ${
                errors.student_address ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Alamat Peserta Didik"
              id="student_address"
              value={student_address}
              onChange={(e) => setStudent_address(e.target.value)}
            />
            {errors.student_address ? (
              <div className="invalid-feedback">
                {errors.student_address[0]}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="home_phone" className="form-label">
              Nomor Telepon Rumah
            </label>
            <input
              className={`form-control mb-1 ${
                errors.home_phone ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Nomor Telepon Rumah"
              id="home_phone"
              value={home_phone}
              onChange={(e) => setHome_phone(e.target.value)}
            />
            {errors.home_phone ? (
              <div className="invalid-feedback">{errors.home_phone[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="from_school" className="form-label">
              Sekolah Asal
            </label>
            <input
              className={`form-control mb-1 ${
                errors.from_school ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Sekolah Asal"
              id="from_school"
              value={from_school}
              onChange={(e) => setFrom_school(e.target.value)}
            />
            {errors.from_school ? (
              <div className="invalid-feedback">{errors.from_school[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-header">Diterima Di SMA Ini</div>
              <div className="card-body row">
                <div className="col-md-6 col-xs-12 mb-2">
                  <label
                    htmlFor="class_accept_this_school"
                    className="form-label"
                  >
                    Di kelas
                  </label>
                  <input
                    className={`form-control mb-1 ${
                      errors.class_accept_this_school ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Di kelas"
                    id="class_accept_this_school"
                    value={class_accept_this_school}
                    onChange={(e) =>
                      setClass_accept_this_school(e.target.value)
                    }
                  />
                  {errors.class_accept_this_school ? (
                    <div className="invalid-feedback">
                      {errors.class_accept_this_school[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 col-xs-12 mb-2">
                  <label
                    htmlFor="accept_this_school_date"
                    className="form-label"
                  >
                    Pada tanggal
                  </label>
                  <input
                    className={`form-control mb-1 ${
                      errors.accept_this_school_date ? "is-invalid" : ""
                    }`}
                    type="date"
                    placeholder="Pada tanggal"
                    id="accept_this_school_date"
                    value={accept_this_school_date}
                    onChange={(e) => setAccept_this_school_date(e.target.value)}
                  />
                  {errors.accept_this_school_date ? (
                    <div className="invalid-feedback">
                      {errors.accept_this_school_date[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-header">Orang Tua</div>
              <div className="card-body row">
                <div className="col-md-6 col-xs-12 mb-2">
                  <label htmlFor="father_name" className="form-label">
                    Nama Ayah
                  </label>
                  <input
                    className={`form-control mb-1 ${
                      errors.father_name ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Nama Ayah"
                    id="father_name"
                    value={father_name}
                    onChange={(e) => setFather_name(e.target.value)}
                  />
                  {errors.father_name ? (
                    <div className="invalid-feedback">
                      {errors.father_name[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 col-xs-12 mb-2">
                  <label htmlFor="mother_name" className="form-label">
                    Nama Ibu
                  </label>
                  <input
                    className={`form-control mb-1 ${
                      errors.mother_name ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Nama Ibu"
                    id="mother_name"
                    value={mother_name}
                    onChange={(e) => setMother_name(e.target.value)}
                  />
                  {errors.mother_name ? (
                    <div className="invalid-feedback">
                      {errors.mother_name[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 col-xs-12 mb-2">
                  <label htmlFor="parent_address" className="form-label">
                    Alamat Orang Tua
                  </label>
                  <input
                    className={`form-control mb-1 ${
                      errors.parent_address ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Alamat Orang Tua"
                    id="parent_address"
                    value={parent_address}
                    onChange={(e) => setParent_address(e.target.value)}
                  />
                  {errors.parent_address ? (
                    <div className="invalid-feedback">
                      {errors.parent_address[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 col-xs-12 mb-2">
                  <label htmlFor="parent_phone" className="form-label">
                    Nomor Telepon Rumah
                  </label>
                  <input
                    className={`form-control mb-1 ${
                      errors.parent_phone ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Nomor Telepon Rumah"
                    id="parent_phone"
                    value={parent_phone}
                    onChange={(e) => setParent_phone(e.target.value)}
                  />
                  {errors.parent_phone ? (
                    <div className="invalid-feedback">
                      {errors.parent_phone[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 col-xs-12 mb-2">
                  <label htmlFor="father_profession" className="form-label">
                    Pekerjaan Ayah
                  </label>
                  <input
                    className={`form-control mb-1 ${
                      errors.father_profession ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Pekerjaan Ayah"
                    id="father_profession"
                    value={father_profession}
                    onChange={(e) => setFather_profession(e.target.value)}
                  />
                  {errors.father_profession ? (
                    <div className="invalid-feedback">
                      {errors.father_profession[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 col-xs-12 mb-2">
                  <label htmlFor="mother_profession" className="form-label">
                    Pekerjaan Ibu
                  </label>
                  <input
                    className={`form-control mb-1 ${
                      errors.mother_profession ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Pekerjaan Ibu"
                    id="mother_profession"
                    value={mother_profession}
                    onChange={(e) => setMother_profession(e.target.value)}
                  />
                  {errors.mother_profession ? (
                    <div className="invalid-feedback">
                      {errors.mother_profession[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-header">Wali Peserta Didik</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-xs-12 mb-2">
                    <label htmlFor="student_guard_name" className="form-label">
                      Nama Wali
                    </label>
                    <input
                      className={`form-control mb-1 ${
                        errors.student_guard_name ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="Nama Wali"
                      id="student_guard_name"
                      value={student_guard_name}
                      onChange={(e) => setStudent_guard_name(e.target.value)}
                    />
                    {errors.student_guard_name ? (
                      <div className="invalid-feedback">
                        {errors.student_guard_name[0]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 col-xs-12 mb-2">
                    <label htmlFor="student_guard_phone" className="form-label">
                      Nomor Telepon/HP Wali
                    </label>
                    <input
                      className={`form-control mb-1 ${
                        errors.student_guard_phone ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="Nomor Telepon/HP"
                      id="student_guard_phone"
                      value={student_guard_phone}
                      onChange={(e) => setStudent_guard_phone(e.target.value)}
                    />
                    {errors.student_guard_phone ? (
                      <div className="invalid-feedback">
                        {errors.student_guard_phone[0]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 col-xs-12 mb-2">
                    <label
                      htmlFor="student_guard_address"
                      className="form-label"
                    >
                      Alamat Wali
                    </label>
                    <input
                      className={`form-control mb-1 ${
                        errors.student_guard_address ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="Alamat"
                      id="student_guard_address"
                      value={student_guard_address}
                      onChange={(e) => setStudent_guard_address(e.target.value)}
                    />
                    {errors.student_guard_address ? (
                      <div className="invalid-feedback">
                        {errors.student_guard_address[0]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 col-xs-12 mb-2">
                    <label
                      htmlFor="student_guard_profession"
                      className="form-label"
                    >
                      Pekerjaan Wali
                    </label>
                    <input
                      className={`form-control mb-1 ${
                        errors.student_guard_profession ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="Pekerjaan"
                      id="student_guard_profession"
                      value={student_guard_profession}
                      onChange={(e) =>
                        setStudent_guard_profession(e.target.value)
                      }
                    />
                    {errors.student_guard_profession ? (
                      <div className="invalid-feedback">
                        {errors.student_guard_profession[0]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="document_date" className="form-label">
              Tanggal
            </label>
            <input
              className={`form-control mb-1 ${
                errors.document_date ? "is-invalid" : ""
              }`}
              type="date"
              placeholder="Tanggal"
              id="document_date"
              value={document_date}
              onChange={(e) => setDocument_date(e.target.value)}
            />
            {errors.document_date ? (
              <div className="invalid-feedback">{errors.document_date[0]}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="headmaster_name" className="form-label">
              Nama Kepala Sekolah
            </label>
            <input
              className={`form-control mb-1 ${
                errors.headmaster_name ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Nama Kepala Sekolah"
              id="headmaster_name"
              value={headmaster_name}
              onChange={(e) => setHeadmaster_name(e.target.value)}
            />
            {errors.headmaster_name ? (
              <div className="invalid-feedback">
                {errors.headmaster_name[0]}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <label htmlFor="headmaster_nbm" className="form-label">
              NBM Kepala Sekolah
            </label>
            <input
              className={`form-control mb-1 ${
                errors.headmaster_nbm ? "is-invalid" : ""
              }`}
              type="text"
              placeholder="Nama Kepala Sekolah"
              id="headmaster_nbm"
              value={headmaster_nbm}
              onChange={(e) => setHeadmaster_nbm(e.target.value)}
            />
            {errors.headmaster_nbm ? (
              <div className="invalid-feedback">{errors.headmaster_nbm[0]}</div>
            ) : (
              ""
            )}
          </div>

          <div className="col-12 mt-4">
            <button type="submit" className="btn btn-primary float-end">
              UPDATE
            </button>

            <Link to="/students">
              <button className="btn btn-warning mx-4 float-end">BACK</button>
            </Link>
          </div>
        </div>
      </form>
      <div className="row mb-5">
        <div className="col-12">
          <StudentOutNoteList
            student_id={id}
            outData={student.studentouts}
            updateData={() => props.updateData()}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <StudentInNoteList
            student_id={id}
            inData={student.studentins}
            updateData={() => props.updateData()}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentEditFormComponent;
