import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { administratordetail, authenticated } from "../store";

const AdministratorEditFormComponent = () => {
  const [administrator, setAdministrator] = useRecoilState(administratordetail);
  const [auth, setAuth] = useRecoilState(authenticated);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const record = {
    name,
    email,
    password,
    gender,
  };
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const { id } = useParams();

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.put("/administrators/" + id, record, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      //   console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    if (administrator) {
      setName(administrator.name);
      setEmail(administrator.email);
      setPassword(administrator.password);
      setGender(administrator.gender);
    }
  }, [auth.login]);

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col">
          <label htmlFor="nama" className="form-label">
            Nama
          </label>
          <input
            className={`form-control mb-1 ${errors.name ? "is-invalid" : ""}`}
            type="text"
            placeholder="Nama"
            id="nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name ? (
            <div className="invalid-feedback">{errors.name[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col">
          <label htmlFor="gender" className="form-label">
            Gender
          </label>
          <select
            className={`form-select mb-1 ${errors.gender ? "is-invalid" : ""}`}
            id="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="">Pilih Gender</option>
            <option value="Laki - Laki">Laki - Laki</option>
            <option value="Perempuan">Perempuan</option>
          </select>
          {errors.gender ? (
            <div className="invalid-feedback">{errors.gender[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            className={`form-control mb-1 ${errors.email ? "is-invalid" : ""}`}
            type="email"
            placeholder="Email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email ? (
            <div className="invalid-feedback">{errors.email[0]}</div>
          ) : (
            ""
          )}
        </div>
        <div className="col">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            className={`form-control mb-1 ${
              errors.password ? "is-invalid" : ""
            }`}
            type="password"
            placeholder="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password ? (
            <div className="invalid-feedback">{errors.password[0]}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <button type="submit" className="btn btn-primary float-end">
        UPDATE
      </button>

      <button
        type="button"
        className="btn btn-warning mx-4 float-end"
        onClick={() => redirect(-1)}
      >
        BACK
      </button>
    </form>
  );
};

export default AdministratorEditFormComponent;
