import axios from "axios";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { updateStatusNilai } from "../../utils/ClassroomFunct";
import { templateExport } from "../../utils/GetFileFunct";

const KemuhClassroomListComponent = ({ classrooms, refreshData }) => {
  const statusNilai = (period, getkemuh, zerokemuh) => {
    const findkemuh = getkemuh.find((muba) => {
      return muba.period_id === period.id;
    });

    const findzero = zerokemuh.find((zero) => {
      return zero.period_id === period.id;
    });

    if (findkemuh !== undefined && findzero !== undefined) {
      return (
        <span
          key={period.id}
          className="badge bg-warning fs-6 text-uppercase m-2"
        >
          Nilai Semester {period.name} Miss
        </span>
      );
    } else if (findkemuh === undefined) {
      return (
        <span
          key={period.id}
          className="badge bg-secondary fs-6 text-uppercase m-2"
        >
          Semester {period.name} belum ada nilai
        </span>
      );
    } else {
      return (
        <span
          key={period.id}
          className="badge bg-success fs-6 text-uppercase m-2"
        >
          Nilai Semester {period.name} Complete
        </span>
      );
    }
  };

  const kumpulNilai = (id) => {
    Swal.fire({
      title: "Yakin kumpulkan nilai ?",
      showDenyButton: true,
      confirmButtonText: "OK",
      denyButtonText: `CANCEL`,
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatusNilai("kemuhs", id).then((result) => {
          if (result.status === 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: result.data.message,
              showConfirmButton: false,
              timer: 2000,
            });

            refreshData();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: result.data.message,
              showConfirmButton: true,
            });
          }
        });
      }
    });
  };

  return (
    <div>
      <div className="col table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">NAMA KELAS</th>
              <th className="text-center">STATUS NILAI</th>
              <th className="text-center">TEMPLATE NILAI</th>
              <th className="text-center" scope="col">
                INPUT NILAI
              </th>
              <th>KUMPULKAN NILAI</th>
            </tr>
          </thead>
          <tbody>
            {classrooms.map((item, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  <span className="badge bg-success fs-6">
                    {item.classroom_name}
                  </span>
                </td>
                <td className="text-center">
                  {item.periods.map((period) =>
                    statusNilai(period, item.getkemuh, item.zerokemuh)
                  )}
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-warning"
                    onClick={() =>
                      templateExport(
                        "exports/kemuh/template",
                        `classroom_id=${item.classroom_id}`,
                        `template-nilai-kemuh-${item.classroom_name}`
                      )
                    }
                  >
                    TEMPLATE NILAI
                  </button>
                </td>
                <td className="text-center">
                  {item.periods.map((period, index) => (
                    <Link
                      key={index}
                      className="btn btn-primary m-2"
                      to={
                        "/regulers/classroom/" +
                        item.classroom_id +
                        "/period/" +
                        period.id
                      }
                    >
                      Semester {period.name}
                    </Link>
                  ))}
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-purple text-white"
                    disabled={item.score_status === "menunggu" ? false : true}
                    onClick={() => kumpulNilai(item.id)}
                  >
                    KUMPULKAN
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default KemuhClassroomListComponent;
