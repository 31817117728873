import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import AcademicListComponent from "../components/AcademicListComponent";
import LoadingComponent from "../components/LoadingComponent";
import {
  academicdetail,
  authenticated,
  classroomshow,
  waiting,
} from "../store";

const AcademicListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [academic, setAcademic] = useRecoilState(academicdetail);
  const [classroomdetail, setClassroomdetail] = useRecoilState(classroomshow);
  const [loading, setLoading] = useRecoilState(waiting);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // console.log(location.pathname);
    setAcademic(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }

    setClassroomdetail(false);
  }, [auth.login]);

  return (
    <div className="card">
      {loading ? <LoadingComponent /> : <AcademicListComponent />}
    </div>
  );
};

export default AcademicListContainer;
