import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import ClassroomListComponent from "../components/ClassroomListComponent";
import LoadingComponent from "../components/LoadingComponent";
import {
  academiclist,
  academicthis,
  authenticated,
  skslist,
  waiting,
} from "../store";

const ClassroomListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [loading, setLoading] = useRecoilState(waiting);
  const setSkss = useSetRecoilState(skslist);
  const setAcademic_id = useSetRecoilState(academicthis);
  const setAcademics = useSetRecoilState(academiclist);
  const navigate = useNavigate();

  useEffect(() => {
    setAcademic_id(false);
    setAcademics(false);
    setSkss(false);
    if (!auth.login) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }
  }, [auth.login]);

  return (
    <div className="card">
      {loading ? <LoadingComponent /> : <ClassroomListComponent />}
    </div>
  );
};

export default ClassroomListContainer;
