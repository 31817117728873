import React from "react";
import { useRecoilState } from "recoil";
import { statusadminlist } from "../store";

const AdminLessonStatusComponent = () => {
  const [statusadmins] = useRecoilState(statusadminlist);

  return (
    <div className="col mb-4">
      <div className="card">
        <div className="card-header">
          <h4>
            <i className="bi bi-book-half"></i> Pelajaran
          </h4>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>Jumlah Pelajaran: </th>
                <th>
                  <span className="badge bg-success">
                    <i className="bi bi-book-half"></i> {statusadmins.lesson}
                  </span>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminLessonStatusComponent;
