import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilValue } from "recoil";
import AddSksFormComponent from "../components/AddSksFormComponent";
import AlertComponent from "../components/AlertComponent";
import SksImportFormComponent from "../components/SksImportFormComponent";
import { authenticated } from "../store";

const AddSksFormContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [classroomdetail, setClassroomdetail] = useState(false);
  const [skss, setSkss] = useState([]);
  const [errors, setErrors] = useState([]);
  const { id } = useParams();
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/sks/" + id + "/create", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassroomdetail(response.data);
      setSkss(response.data.skslist);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Add SKS Ke Kelas{" "}
          <span className="badge bg-success fs-4">
            {classroomdetail?.classroom?.name}
          </span>{" "}
          <span className="badge bg-success fs-4 text-wrap">
            {classroomdetail?.classroom?.academic?.name}
          </span>
        </h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="col">
              <AlertComponent color={"red"} message={errors.message} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col">
            {classroomdetail.classroom ? (
              <Fragment>
                <SksImportFormComponent
                  classroomdetail={classroomdetail}
                  updateData={() => getData()}
                  setErrors={(e) => setErrors(e)}
                />
                <AddSksFormComponent
                  classroomdetail={classroomdetail}
                  skss={skss}
                  updateData={() => getData()}
                />
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSksFormContainer;
