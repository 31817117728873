import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated, classroomshow } from "../store";

const AcademicClassroomFormComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [classroomdetail, setClassroomdetail] = useRecoilState(classroomshow);
  const [classrooms, setClassrooms] = useState([]);
  const [group_names, setGroup_names] = useState([]);
  const [errors, setErrors] = useState([]);
  const redirect = useNavigate();
  const records = { classrooms };

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.put("/academics/update/classroom", records, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  const handleClassroom = (index, academic_id) => {
    const update = { ...classrooms[index] };
    update.academic_id = parseInt(academic_id);

    const record = [
      ...classrooms.slice(0, index),
      update,
      ...classrooms.slice(index + 1),
    ];

    setClassrooms(record);
  };

  const handleGroup = (index, value) => {
    const update = { ...classrooms[index] };
    update.group_name = value;

    const record = [
      ...classrooms.slice(0, index),
      update,
      ...classrooms.slice(index + 1),
    ];

    setClassrooms(record);
  };

  const selectGroup = (academic_id) => {
    const select = classroomdetail.academics.find(
      (academic) => academic.id == academic_id
    );

    if (typeof select !== "undefined") {
      return select.periods;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (auth.login === false) {
      redirect("/");
    }

    if (classroomdetail) {
      setClassrooms(classroomdetail.classrooms);
    }
  }, [auth.login]);

  return (
    <form onSubmit={(e) => formHandle(e)}>
      <div className="row mb-4">
        <div className="col">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">KELAS</th>
                <th className="text-center">TAHUN AJARAN</th>
                <th className="text-center">GROUP KELAS</th>
              </tr>
            </thead>
            <tbody>
              {classrooms.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <span className="badge bg-success fs-6">{row.name}</span>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      type="text"
                      id={"classroom" + index}
                      value={row.academic_id}
                      onChange={(e) => handleClassroom(index, e.target.value)}
                    >
                      <option value="">===Pilih Tahun Ajaran===</option>
                      {classroomdetail.academics.map((row, index) => (
                        <option key={index} value={row.id}>
                          {row.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      type="text"
                      id={"classroom_group" + index}
                      value={row.group_name}
                      onChange={(e) => handleGroup(index, e.target.value)}
                    >
                      <option value="">===Pilih Group===</option>
                      {selectGroup(row.academic_id).map((row, index) => (
                        <option key={index} value={row.group_name}>
                          {row.group_name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <button type="submit" className="btn btn-primary float-end">
        UPDATE
      </button>

      <Link to="/academics">
        <button className="btn btn-warning mx-4 float-end">BACK</button>
      </Link>
    </form>
  );
};

export default AcademicClassroomFormComponent;
