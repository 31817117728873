import axios from "axios";
import React, { useState } from "react";
import StatusNilaiAkademikComponent from "../status/StatusNilaiAkademikComponent";
import StatusNilaiIsmubaComponent from "../status/StatusNilaiIsmubaComponent";

const StatusClassroomListComponent = ({ classrooms, setErrors }) => {
  const [status, setStatus] = useState([]);

  const getStatus = async (classroom_id) => {
    try {
      if (classroom_id !== "") {
        let response = await axios.get("/status/classroom/" + classroom_id, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });

        if (response.status === 200) {
          setStatus(response.data);
          setErrors([]);
        }
      }
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  return (
    <div className="row">
      <div className="col-12 mb-4">
        <label className="mb-2" htmlFor="classroom_id">
          Kelas
        </label>
        <select
          className="form-select"
          name="classroom_id"
          id="classroom_id"
          onChange={(e) => getStatus(e.target.value)}
        >
          <option value="">===Pilih Kelas===</option>
          {classrooms.map((row, index) => (
            <option key={index} value={row.classroom_id}>
              {row.classroom.name}
            </option>
          ))}
        </select>
      </div>
      {status.kemuhclassrooms ? (
        <div className="col-12 table-responsive mb-4">
          <StatusNilaiIsmubaComponent
            title="Status Nilai Kemuhammadiyahan"
            classrooms={status.kemuhclassrooms}
          />
        </div>
      ) : (
        ""
      )}
      {status.quranteachers ? (
        <div className="col-12 table-responsive mb-4">
          <StatusNilaiIsmubaComponent
            title="Status Nilai Qur'an"
            classrooms={status.quranteachers}
          />
        </div>
      ) : (
        ""
      )}
      {status.classroomusers ? (
        <div className="col-12 table-responsive mb-4">
          <StatusNilaiAkademikComponent
            classroomusers={status.classroomusers}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default StatusClassroomListComponent;
