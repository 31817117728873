import axios from "axios";

export const getData = async (uri) => {
  try {
    let response = await axios.get(`${uri}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });

    // console.log(response.data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    // console.log(e.response.data.message);
    return e.response;
  }
};

export const postData = async (uri, data) => {
  try {
    let response = await axios.post(`${uri}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });

    // console.log(response.data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    // console.log(e.response.data.message);
    return e.response;
  }
};

export const addTeacher = async (uri, user_id, classroom_id, academic_id) => {
  try {
    let response = await axios.post(
      `${uri}`,
      { user_id, classroom_id, academic_id },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );

    // console.log(response.data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    // console.log(e.response.data.message);
    return e.response.data;
  }
};

export const deleteData = async (uri) => {
  try {
    let response = await axios.delete(`/${uri}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });

    // console.log(response.data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    // console.log(e.response.data);
    return e.response.data;
  }
};

export const updateStatusNilai = async (uri, id) => {
  try {
    let response = await axios.put(
      `/${uri}/${id}`,
      { id },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );

    // console.log(response.data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    // console.log(e.response.data);
    return e.response;
  }
};
