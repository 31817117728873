import React from "react";
import { useRecoilValue } from "recoil";
import { authenticated } from "../store";

const CompetenceDetailHeaderComponent = ({
  categorie,
  lesson,
  competence,
  classroom,
  academic,
}) => {
  const auth = useRecoilValue(authenticated);

  return (
    <table className="table">
      <tbody>
        <tr>
          <td>Nama Guru</td>
          <td>:</td>
          <td>{auth.user?.name}</td>
        </tr>
        <tr>
          <td>Kategori</td>
          <td>:</td>
          <td>{categorie?.name}</td>
        </tr>
        <tr>
          <td>Pelajaran</td>
          <td>:</td>
          <td>{lesson?.name}</td>
        </tr>
        <tr>
          <td>Kompetensi</td>
          <td>:</td>
          <td>{competence?.name}</td>
        </tr>
        <tr>
          <td>Deskripsi</td>
          <td>:</td>
          <td>{competence?.description}</td>
        </tr>
        <tr>
          <td>Kelas</td>
          <td>:</td>
          <td>
            <span className="badge bg-success fs-6">{classroom?.name}</span>
          </td>
        </tr>
        <tr>
          <td>Semester</td>
          <td>:</td>
          <td>
            <span className="badge bg-success fs-6">
              {academic?.period?.name}
            </span>
          </td>
        </tr>
        <tr>
          <td>Tahun Ajaran</td>
          <td>:</td>
          <td>
            <span className="badge bg-success fs-6">{academic?.nickname}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CompetenceDetailHeaderComponent;
