import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const CompetenceCopyComponent = ({ academics, toclassrooms, setErrors }) => {
  const [academic_id, setAcademic_id] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [lesson_id, setLesoon_id] = useState(false);
  const [classrooms, setClassrooms] = useState([]);
  const [fromclassroom_id, setFromclassroom_id] = useState(false);
  const [toclassroom_id, setToclassroom_id] = useState(false);
  const [type, setType] = useState(false);
  const records =
    academic_id +
    "/" +
    lesson_id +
    "/" +
    fromclassroom_id +
    "/" +
    toclassroom_id +
    "/" +
    type;

  const getLessonFromAcademic = async (academic_id) => {
    try {
      setLessons([]);
      setClassrooms([]);
      setAcademic_id(academic_id);

      let response = await axios.get(
        "copies/competences/" + academic_id + "/lessons",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setLessons(response.data);
      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const getClassroomFromLesson = async (lesson_id) => {
    try {
      setLesoon_id(lesson_id);

      let response = await axios.get(
        "copies/competences/" + academic_id + "/" + lesson_id + "/classrooms",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setClassrooms(response.data);
      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const handleCopy = async () => {
    try {
      let response = await axios.get(
        "copies/competences/" + records + "/copy",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // console.log(response.data);
    } catch (e) {
      if (e.response.status === 401) {
        setErrors(e.response.data);
      } else if (e.response.status === 422) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Gagal menyalin KD",
          footer: "",
        });
      }

      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  return (
    <div className="border shadow-lg p-3 mb-5 bg-body rounded animate__animated animate__bounceIn">
      <div className="row row-cols-1 p-3">
        <div className="col mb-4">
          <h3>COPY KD</h3>
        </div>
        <div className="col mb-4">
          <label className="mb-2" htmlFor="academic_id">
            Tahun Ajaran
          </label>
          <select
            className="form-select"
            name="academic_id"
            id="academic_id"
            onChange={(e) => getLessonFromAcademic(e.target.value)}
          >
            <option value="">===Pilih Tahun Ajaran===</option>
            {academics.map((row, index) => (
              <option key={index} value={row.id}>
                {row.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col mb-4">
          <label className="mb-2" htmlFor="lesson_id">
            Pelajaran
          </label>
          <select
            className="form-select"
            name="lesson_id"
            id="lesson_id"
            onChange={(e) => getClassroomFromLesson(e.target.value)}
          >
            <option value="">===Pilih Pelajaran===</option>
            {lessons.map((row, index) => (
              <option key={index} value={row.lesson.id}>
                {row.lesson.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col mb-4">
          <label className="mb-2" htmlFor="classroom_id">
            Dari Kelas
          </label>
          <select
            className="form-select"
            name="classroom_id"
            id="classroom_id"
            onChange={(e) => setFromclassroom_id(e.target.value)}
          >
            <option value="">===Pilih Kelas===</option>
            {classrooms.map((row, index) => (
              <option key={index} value={row.id}>
                {row.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col mb-4">
          <label className="mb-2" htmlFor="toclassroom_id">
            Tujuan Kelas
          </label>
          <select
            className="form-select"
            name="toclassroom_id"
            id="toclassroom_id"
            onChange={(e) => setToclassroom_id(e.target.value)}
          >
            <option value="">===Pilih Kelas Tujuan===</option>
            {toclassrooms.map((row, index) => (
              <option key={index} value={row.classroom_id}>
                {row.classroom}
              </option>
            ))}
          </select>
        </div>
        <div className="col mb-4">
          <label className="mb-2" htmlFor="competence_period">
            Periode KD
          </label>
          <select
            className="form-select"
            name="competence_period"
            id="competence_period"
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">===Pilih Periode KD===</option>
            <option value="PTS">PTS</option>
            <option value="PAS">PAS</option>
          </select>
        </div>
        <div className="col mb-4">
          <button
            type="button"
            className="btn btn-success float-end"
            onClick={() => handleCopy()}
          >
            COPY
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompetenceCopyComponent;
