import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { classroomprops } from "../../store";

const IsmubaClassroomHeader = ({ setErrors }) => {
  const [classroomheader, setClassroomheader] = useRecoilState(classroomprops);
  const { classroom_id, period_id } = useParams();

  const getClassroom = async () => {
    try {
      setClassroomheader(false);
      let response = await axios.get(
        `/classrooms/${classroom_id}/active?period_id=${period_id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setClassroomheader(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getClassroom();
  }, []);

  return (
    <ul className="list-group">
      <li className="list-group-item">
        <strong>Kelas</strong>
        <br />
        <span className="badge bg-success fs-6 text-wrap">
          {classroomheader?.name}
        </span>
        <span className="badge bg-success fs-6 text-wrap mx-2 text-uppercase">
          {classroomheader?.type}
        </span>
      </li>
      <li className="list-group-item">
        <strong>Semester</strong>
        <br />
        <span className="badge bg-success fs-6 text-wrap">
          {classroomheader?.academic?.period?.name}
        </span>
      </li>
      <li className="list-group-item">
        <strong>Tahun Ajaran</strong>
        <br />
        <span className="badge bg-success fs-6 text-wrap">
          {classroomheader?.academic?.nickname}
        </span>
      </li>
    </ul>
  );
};

export default IsmubaClassroomHeader;
