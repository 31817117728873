import axios from "axios";
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import AttitudeFormComponent from "../components/AttitudeFormComponent";
import { categorielist } from "../store";

const AttitudeFormContainer = () => {
  const [categories, setCategories] = useRecoilState(categorielist);
  const [errors, setErrors] = useState([]);

  const getData = async () => {
    try {
      let response = await axios.get("/admin/attitudes/create", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setCategories(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Deskripsi Sikap</h2>
      </div>
      <div className="card-body">
        <AttitudeFormComponent />
      </div>
    </div>
  );
};

export default AttitudeFormContainer;
