import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { newTabPdf } from "../utils/GetFileFunct";

const ReportClassroomAdminComponent = ({ classroom, academic, students }) => {
  const [scroll, setScroll] = useState(false);
  const [colheight, setColheight] = useState(54);
  const redirect = useNavigate();

  const newTabReport = async (student, period, type) => {
    const params = `exports/${student.id}/${classroom.id}/${academic.id}/${period}/${type}`;
    newTabPdf(params);
  };

  const trHeightAppend = () => {
    const tbody = document.getElementsByTagName("tbody")[0];
    if (tbody.childNodes.length > 0) {
      const trHeight = tbody.childNodes[0].cells[0].clientHeight;
      setColheight(trHeight);
    }
  };

  const tableScrollLeft = () => {
    trHeightAppend();
    const scrollValue =
      document.getElementsByClassName("table-responsive")[0].scrollLeft;

    if (scrollValue > 200) {
      setScroll(true);
    } else if (scrollValue < 200) {
      setScroll(false);
    }
  };

  return (
    <div>
      <div className="col-md-5 mb-5">
        <table className="table">
          <thead>
            <tr>
              <th>Kelas</th>
              <th>:</th>
              <th>{classroom?.name}</th>
            </tr>
            <tr>
              <th>Tahun Ajaran</th>
              <th>:</th>
              <th>{academic?.nickname}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="row">
        <div
          className="col table-responsive"
          onTouchMove={() => tableScrollLeft()}
        >
          <table className="table table-bordered table-striped">
            <thead>
              <tr className="text-center">
                <th>#</th>
                <th>KELAS</th>
                <th>NIS</th>
                <th>NAMA</th>
                <th>Rapot</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{classroom.name}</td>
                  <td className="text-center">{student.nis}</td>
                  <td
                    className={scroll ? "absolute-col" : null}
                    style={{ height: colheight + "px" }}
                  >
                    <div className="fix-width">{student.name}</div>
                  </td>
                  <td key={index} className="text-center">
                    {academic.periods.map((row, index) => (
                      <Fragment key={index}>
                        {classroom.group_name === row.group_name ? (
                          <div className="row">
                            <div className="col mb-3">
                              <button
                                className="btn btn-dark"
                                onClick={() =>
                                  newTabReport(student, row.id, "pts")
                                }
                              >
                                Rapot Semester {row.name} PTS
                              </button>
                            </div>
                            <div className="col">
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  newTabReport(student, row.id, "pas")
                                }
                              >
                                Rapot Semester {row.name} PAS
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            type="button"
            onClick={() => redirect(-1)}
            className="btn btn-warning float-end"
          >
            BACK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportClassroomAdminComponent;
