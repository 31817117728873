import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authenticated, scoredetail } from "../store";

const CompetenceRecapComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [score] = useRecoilState(scoredetail);

  return (
    <div>
      <div className="row">
        <div className="col-4 offset-8">
          <input
            type="text"
            className="form-control float-end mb-4 col-2"
            placeholder="Search..."
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-5">
          <table className="table">
            <tbody>
              <tr>
                <td>Nama Guru</td>
                <td>:</td>
                <td>{auth?.user?.name}</td>
              </tr>
              <tr>
                <td>Kelas</td>
                <td>:</td>
                <td>{score?.classroom?.name}</td>
              </tr>
              <tr>
                <td>Pelajaran</td>
                <td>:</td>
                <td>{score?.lesson?.name}</td>
              </tr>
              <tr>
                <td>Tahun Ajaran</td>
                <td>:</td>
                <td>{score?.academic?.nickname}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        {score ? (
          <div className="col table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th rowSpan={2} className="text-center">
                    Nama
                  </th>
                  <th rowSpan={2} className="text-center">
                    NIS
                  </th>
                  {score.categories.map((row, index) => (
                    <>
                      {row.competences.length > 0 ? (
                        <th
                          key={index}
                          className="text-center"
                          colSpan={row.competences.length + 1}
                          key={index}
                          scope="col"
                        >
                          {row.name}
                        </th>
                      ) : null}
                    </>
                  ))}
                </tr>
                <tr>
                  {score.categories.map((row, index) =>
                    row.competences.map((competence, index) => (
                      <>
                        <th key={index} scope="col">
                          {index + 1}
                        </th>
                        {row.competences.length === index + 1 ? (
                          <>
                            {row.score_type === "angka" ? (
                              <th scope="col">AVG</th>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ))
                  )}
                </tr>
              </thead>
              <tbody>
                {score.data.map((row, index) => (
                  <tr key={index}>
                    <td>{row.student.name}</td>
                    <td>{row.student.nis}</td>
                    {row.categories?.map((categorie, index) => (
                      <>
                        {categorie?.compentences.length > 0
                          ? categorie?.compentences.map(
                              (compentence, index) => (
                                <td key={index}>{compentence.score}</td>
                              )
                            )
                          : null}
                        {categorie?.compentences.length > 0
                          ? [
                              categorie.score_type === "angka" ? (
                                <td key={0}>{categorie.average.toFixed(2)}</td>
                              ) : null,
                            ]
                          : null}
                      </>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <Link to="/competences">
              <button className="btn btn-warning float-end me-5" type="button">
                BACK
              </button>
            </Link>
          </div>
        ) : (
          <h2>Belum ada recap nilai</h2>
        )}
      </div>
    </div>
  );
};

export default CompetenceRecapComponent;
