import axios from "axios";
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { authenticated } from "../store";

const ExportDataComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [academics, setAcademics] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [academic_id, setAcademic_id] = useState("");
  const [classroom_id, setClassroom_id] = useState("");
  const [academic_name, setAcademic_name] = useState("");
  const [classroom_name, setClassroom_name] = useState("");
  const [errors, setErrors] = useState(false);

  const getAcademics = async () => {
    try {
      let response = await axios.get("/copies/create", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAcademics(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const getClassrooms = async (academic_id) => {
    try {
      let response = await axios.get(
        "/copies/academic/" + academic_id + "/student/classroom",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setClassrooms(response.data);
      setAcademic_id(academic_id);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const handleAcademic = (index) => {
    if (index !== "") {
      const academic = academics[index];
      setAcademic_id(academic.id);
      setAcademic_name(academic.name);
      getClassrooms(academic.id);
    } else {
      setClassrooms([]);
    }
  };

  const handleClassroom = (index) => {
    if (index !== "") {
      const classroom = classrooms[index];
      setClassroom_id(classroom.id);
      setClassroom_name(classroom.name);
    }
  };

  const exportStudent = async () => {
    try {
      let response = await axios.get(
        "/export/student/" + academic_id + "/" + classroom_id,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const filename = "Siswa Kelas-" + classroom_name + "-" + academic_name;
        const blob = new Blob([response.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (academics.length === 0) getAcademics();
  }, []);

  return (
    <div className="row row-cols-3">
      <div className="col">
        <label className="mb-2" htmlFor="academic_id">
          Tahun Ajaran
        </label>
        <select
          className="form-select"
          name="academic_id"
          id="academic_id"
          onChange={(e) => handleAcademic(e.target.value)}
        >
          <option value="">===Pilih Tahun Ajaran===</option>
          {academics
            ? academics.map((academic, index) => (
                <option key={index} value={index}>
                  {academic.name}
                </option>
              ))
            : ""}
        </select>
      </div>
      <div className="col">
        <label className="mb-2" htmlFor="academic_id">
          Kelas
        </label>
        <select
          className="form-select"
          name="academic_id"
          id="academic_id"
          onChange={(e) => handleClassroom(e.target.value)}
        >
          <option value="">===Pilih Kelas===</option>
          {classrooms.map((classroom, index) => (
            <option key={index} value={index}>
              {classroom.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col">
        <button
          className="btn btn-primary float-end mt-4"
          onClick={() => exportStudent()}
        >
          DOWNLOAD
        </button>
      </div>
    </div>
  );
};

export default ExportDataComponent;
