import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CopyDataComponent = (props) => {
  const [academics, setAcademics] = useState(false);
  const [classrooms, setClassrooms] = useState(false);
  const [academic_id, setAcademic_id] = useState(false);
  const [classroom_id, setClassroom_id] = useState(false);
  const [errors, setErrors] = useState(false);
  const params = `${academic_id}/${classroom_id}`;
  const { id } = useParams();

  const getAcademics = async () => {
    try {
      let response = await axios.get("/copies/create", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAcademics(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const getClassrooms = async (academic_id) => {
    try {
      let response = await axios.get(
        `/copies/academic/${academic_id}/${props.type}/classroom`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setClassrooms(response.data);
      setAcademic_id(academic_id);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const copyData = async () => {
    try {
      let response = await axios.get(
        "/copies/classroom/" + id + "/" + props.type + "/" + params,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: true,
        });

        props.refreshData();
      }
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: e.response.data.message,
        showConfirmButton: true,
      });

      setErrors(e.response.data);
    }
  };

  useEffect(() => {
    setClassrooms(false);
    getAcademics();
  }, [props.show]);

  return (
    <div>
      {props.show ? (
        <div className="card copy-modal">
          <div className="card-header">
            <h2>
              Copy Data{" "}
              <span className="badge bg-success fs-5">
                {props.type.toLocaleUpperCase()}
              </span>
              <span
                className="badge bg-danger fs-6 float-end close"
                onClick={() => props.actionModal()}
              >
                X
              </span>
            </h2>
          </div>
          <div className="card-body">
            <div className="row row-cols-1">
              <div className="col mb-4">
                <label htmlFor="academic" className="mb-2">
                  Tahun Ajaran
                </label>
                <select
                  className="form-select"
                  name="academic"
                  id="academic"
                  onChange={(e) => getClassrooms(e.target.value)}
                >
                  <option value="">==Pilih Tahun AJaran==</option>
                  {academics
                    ? academics.map((academic, index) => (
                        <option key={index} value={academic.id}>
                          {academic.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="col mb-4">
                <label htmlFor="classroom" className="mb-2">
                  Kelas
                </label>
                {classrooms ? (
                  <select
                    className="form-select"
                    name="classroom"
                    id="classroom"
                    onChange={(e) => setClassroom_id(e.target.value)}
                  >
                    <option value="">==Pilih Kelas==</option>
                    {classrooms.map((classroom, index) => (
                      <option key={index} value={classroom.id}>
                        {classroom.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  ""
                )}
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-success float-end"
                  onClick={() => copyData()}
                >
                  COPY SISWA
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CopyDataComponent;
