import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import LessonGroupListComponent from "../components/LessonGroupListComponent";
import LoadingComponent from "../components/LoadingComponent";
import {
  authenticated,
  lcategoriedetail,
  lcategorielist,
  waiting,
} from "../store";

const LessonGroupListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [loading, setLoading] = useRecoilState(waiting);
  const [lcategories, setLcategories] = useRecoilState(lcategorielist);
  const [lcategorie, setLcategorie] = useRecoilState(lcategoriedetail);
  const navigate = useNavigate();

  useEffect(() => {
    setLcategories(false);
    setLcategorie(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }
  }, [auth.login]);

  return (
    <div className="card">
      {loading ? <LoadingComponent /> : <LessonGroupListComponent />}
    </div>
  );
};

export default LessonGroupListContainer;
