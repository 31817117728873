import React from "react";
import { Link } from "react-router-dom";
import PaginationComponent from "../../components/PaginationComponent";

const SuratListComponent = ({ surats, delData, updateData }) => {
  return (
    <div>
      {surats.data ? (
        <div>
          <div className="col-12 table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>Nama Surat</th>
                  <th>Nomor Urut</th>
                  <th colSpan={2}>Action</th>
                </tr>
              </thead>
              <tbody>
                {surats.data.map((surat, index) => (
                  <tr key={index} className="text-center">
                    <td>{index + 1}</td>
                    <td>
                      <span className="badge bg-success fs-6">
                        {surat.name}
                      </span>
                    </td>
                    <td>{surat.number}</td>
                    <td>
                      <Link to={"/surats/" + surat.id + "/edit"}>
                        <button type="button" className="btn btn-success">
                          <i className="bi bi-pencil-square"></i>
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => delData(surat.id)}
                      >
                        <i className="bi bi-x-lg"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <PaginationComponent
            data={surats.links}
            handlePaginate={(page) => updateData(page)}
          />
        </div>
      ) : (
        <div className="alert alert-warning">Tidak ada data</div>
      )}
    </div>
  );
};

export default SuratListComponent;
