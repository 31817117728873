import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import LoadingComponent from "../components/LoadingComponent";
import UserListComponent from "../components/UserListComponent";
import { authenticated } from "../store";

const UserListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const redirect = useNavigate();

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }
  }, [auth.login]);

  return (
    <div className="card">
      {auth.login ? <UserListComponent /> : <LoadingComponent />}
    </div>
  );
};

export default UserListContainer;
