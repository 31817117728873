import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import ReportOnlyClassroomListComponent from "../components/ReportOnlyClassroomListComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import {
  authenticated,
  pasdetail,
  ptsdetail,
  reportshow,
  studentlist,
} from "../store";

const ReportOnlyClassroomListContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [reports, setReports] = useState([]);
  const [report, setReport] = useRecoilState(reportshow);
  const [pts, setPts] = useRecoilState(ptsdetail);
  const [pas, setPas] = useRecoilState(pasdetail);
  const [students, setStudents] = useRecoilState(studentlist);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/reports", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setReports(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    setPts(false);
    setPas(false);
    setReport(false);
    setStudents(false);

    if (!auth.login) {
      navigate("/", { replace: true });
    }
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      {reports ? (
        <ReportOnlyClassroomListComponent reports={reports} />
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default ReportOnlyClassroomListContainer;
