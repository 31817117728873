import axios from "axios";
import React from "react";
import { Link, useParams } from "react-router-dom";

import Swal from "sweetalert2";

import Moment from "react-moment";
import "moment/locale/id";
import { useRecoilState } from "recoil";
import { waiting } from "../store";

const PeriodListComponent = ({ periods, setErrors, getData }) => {
  const [loading, setLoading] = useRecoilState(waiting);
  const { id } = useParams();

  const delData = async (id) => {
    try {
      let response = await axios.delete("/periods/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });
        getData();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const moveAction = async (period, move) => {
    try {
      setLoading(true);
      let response = await axios.get(`/sorts/period/${period.id}/${move}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        getData();
        setLoading(false);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
      // console.log(e.response.data);
    }
  };

  const moveUpBtn = (period) => {
    if (period.order_up !== null) {
      return (
        <button
          className="btn btn-warning"
          onClick={() => moveAction(period, "up")}
        >
          <i className="bi-arrow-up-circle-fill"></i>
        </button>
      );
    }
  };

  const moveDownBtn = (period) => {
    if (period.order_down !== null) {
      return (
        <button
          className="btn btn-secondary"
          onClick={() => moveAction(period, "down")}
        >
          <i className="bi-arrow-down-circle-fill"></i>
        </button>
      );
    }
  };

  return (
    <div>
      <div className="card-header">
        <h2>List Semester</h2>
      </div>
      <div className="row p-4">
        <div className="col">
          <Link to={"/periods/" + id + "/create"}>
            <button className="btn btn-success mb-4 float-end">Create</button>
          </Link>
        </div>
      </div>

      <div className="card-body">
        <div className="col table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">NAMA</th>
                <th className="text-center">ACTIVE</th>
                <th className="text-center">TANGGAL RAPOT PTS</th>
                <th className="text-center">TANGGAL RAPOT PAS</th>
                <th className="text-center">GROUP KELAS</th>
                <th className="text-center" colSpan={2}>
                  ORDER
                </th>
                <th className="text-center" colSpan={2} scope="col">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {periods.map((row, index) => (
                <tr key={index}>
                  <th className="text-center">{index + 1}</th>
                  <td>{row.name}</td>
                  <td className="text-center">
                    {row.active === "true" ? (
                      <span className="badge bg-success fs-6">ON</span>
                    ) : (
                      <span className="badge bg-secondary fs-6">OFF</span>
                    )}
                  </td>
                  <td className="text-center">
                    <Moment format="DD MMMM YYYY">{row.report_date_pts}</Moment>
                  </td>
                  <td className="text-center">
                    <Moment format="DD MMMM YYYY">{row.report_date_pas}</Moment>
                  </td>
                  <td>{row.group_name}</td>
                  <td className="text-center">{moveUpBtn(row)}</td>
                  <td className="text-center">{moveDownBtn(row)}</td>
                  <td className="text-center">
                    <Link to={"/periods/" + row.id + "/edit"}>
                      <button className="btn btn-primary">EDIT</button>
                    </Link>
                  </td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => delData(row.id)}
                    >
                      DELETE
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PeriodListComponent;
