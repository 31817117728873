import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import LoadingComponent from "../components/LoadingComponent";
import {
  adminattitudelist,
  authenticated,
  competencedetail,
  scorelist,
  studentlist,
} from "../store";
import CompetenceDetailComponent from "../components/CompetenceDetailComponent";
import CompetenceDetailHeaderComponent from "../components/CompetenceDetailHeaderComponent";
import CompetenceDetailImportComponent from "../components/CompetenceDetailImportComponent";
import CompetenceDetailExportComponent from "../components/CompetenceDetailExportComponent";
import AlertComponent from "../components/AlertComponent";

const CompetenceDetailContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [scores, setScores] = useRecoilState(scorelist);
  const [academic, setAcademic] = useState(false);
  const [categorie, setCategorie] = useState(false);
  const [lesson, setLesson] = useState(false);
  const [competence, setCompetence] = useState(false);
  const [classroom, setClassroom] = useState(false);
  const [students, setStudents] = useState([]);
  const setAdminattitudes = useSetRecoilState(adminattitudelist);
  const [errors, setErrors] = useState([]);
  const { id } = useParams();
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/competences/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setScores(response.data);
        setAcademic(response.data.academic);
        setCategorie(response.data.categorie);
        setLesson(response.data.lesson);
        setCompetence(response.data.competence);
        setClassroom(response.data.classroom);
        setStudents(response.data.students);
        setAdminattitudes(response.data.attitudes);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Nilai {competence?.name}{" "}
          <span className="badge bg-success fs-4 text-wrap">
            {classroom?.name}
          </span>
        </h2>
      </div>
      {scores ? (
        <div className="card-body">
          <div className="row">
            {errors.message ? (
              <div className="col-12 mb-4">
                <AlertComponent color={"danger"} message={errors.message} />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row mb-4">
            <div className="col-md-6 col-xs-12 mb-2">
              <CompetenceDetailHeaderComponent
                categorie={categorie}
                lesson={lesson}
                competence={competence}
                classroom={classroom}
                academic={academic}
              />
            </div>
            <div className="col-md-6 col-xs-12 mb-2">
              <div className="row row-cols-1">
                <div className="col mb-3">
                  <div className="alert alert-warning" role="alert">
                    Harap tekan tombol <b>UPDATE</b> setelah merubah nilai
                  </div>
                </div>
                <div className="col mb-3">
                  <CompetenceDetailExportComponent
                    categorie={categorie}
                    lesson={lesson}
                    competence={competence}
                    classroom={classroom}
                    setErrors={(e) => setErrors(e)}
                  />
                </div>
                <div className="col mb-3">
                  <CompetenceDetailImportComponent
                    setCompetence={(data) => setCompetence(data)}
                    setStudents={(data) => setStudents(data)}
                  />
                </div>
                <div className="col mt-4">
                  <Link to={"/competences/" + id + "/edit"}>
                    <button className="btn btn-primary float-end">
                      Edit Kompetensi
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {competence ? (
              <CompetenceDetailComponent
                categorie={categorie}
                competence={competence}
                students={students}
                setStudents={(data) => setStudents(data)}
                setErrors={(e) => setErrors(e)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default CompetenceDetailContainer;
