import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { attendancelist } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const AttendanceListComponent = () => {
  const [attendances, setAttendances] = useRecoilState(attendancelist);
  const [errors, setErrors] = useState("");

  const getData = async () => {
    try {
      let response = await axios.get("/attendances", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setAttendances(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="card-header">
        <h2>Absensi Kelas</h2>
      </div>
      {attendances ? (
        <div className="card-body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nama Kelas</th>
                <th colSpan={1} className="text-center" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {attendances.map((row, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <span className="badge bg-success fs-6">
                      {row.classroom.name}
                    </span>
                  </td>
                  <td className="text-center">
                    {row.academic.periods.map((period, index) => (
                      <Fragment key={index}>
                        {row.classroom.group_name === period.group_name ? (
                          <Link
                            to={
                              "/attendances/" +
                              row.id +
                              "/" +
                              period.id +
                              "/detail"
                            }
                          >
                            <button
                              key={index}
                              className="btn btn-warning mx-2 mb-2 fw-bold"
                            >
                              Semester {period.name}
                            </button>
                          </Link>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default AttendanceListComponent;
