import React from "react";
import StatusNilaiAkademikComponent from "../status/StatusNilaiAkademikComponent";
import StatusNilaiIsmubaComponent from "../status/StatusNilaiIsmubaComponent";

const AdminStatusClassroomListComponent = ({
  classrooms,
  status,
  getStatus,
  statusRefresh,
}) => {
  return (
    <div className="row">
      <div className="col-12 mb-4">
        <label className="mb-2" htmlFor="classroom_id">
          Kelas
        </label>
        <select
          className="form-select"
          name="classroom_id"
          id="classroom_id"
          onChange={(e) => getStatus(e.target.value)}
        >
          <option value="">===Pilih Kelas===</option>
          {classrooms.map((row, index) => (
            <option key={index} value={row.id}>
              {row.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-12 mb-4">
        {status.kemuhclassrooms ? (
          <button
            className="btn btn-primary float-end"
            onClick={() => statusRefresh()}
          >
            SETEL ULANG STATUS KELAS INI
          </button>
        ) : null}
      </div>
      {status.kemuhclassrooms ? (
        <div className="col-12 table-responsive mb-4">
          <StatusNilaiIsmubaComponent
            title="Status Nilai Kemuhammadiyahan"
            classrooms={status.kemuhclassrooms}
          />
        </div>
      ) : (
        ""
      )}
      {status.quranteachers ? (
        <div className="col-12 table-responsive mb-4">
          <StatusNilaiIsmubaComponent
            title="Status Nilai Qur'an"
            classrooms={status.quranteachers}
          />
        </div>
      ) : (
        ""
      )}
      {status.classroomusers ? (
        <div className="col-12 table-responsive mb-4">
          <StatusNilaiAkademikComponent
            classroomusers={status.classroomusers}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AdminStatusClassroomListComponent;
