import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { classroomslist } from "../store";
import SmallLoadingComponent from "./SmallLoadingComponent";

const ClassroomListComponent = () => {
  const [classrooms, setClassrooms] = useRecoilState(classroomslist);
  const [errors, setErrors] = useState([]);

  const getData = async (option = "page=0") => {
    try {
      let response = await axios.get("/classrooms?" + option, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setClassrooms(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const handleSearch = (e) => {
    const src = "src=" + e.target.value;
    getData(src);
  };

  const delData = async (id) => {
    try {
      let response = await axios.delete("/classrooms/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });
        getData();
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "",
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (classrooms === false) getData();
  }, []);

  return (
    <div>
      <div className="card-header">
        <h2>List Kelas</h2>
      </div>
      {classrooms.data ? (
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 mb-4">
              <Link to="/classrooms/create">
                <button className="btn btn-success mb-4 float-end">
                  Create
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <input
              type="text"
              className="form-control float-end mb-4 col-2"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <div className="col-12 table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>Nama Kelas</th>
                  <th colSpan={7}>Action</th>
                </tr>
              </thead>
              <tbody>
                {classrooms.data.map((classroom, index) => (
                  <tr key={index} className="text-center">
                    <td>{index + 1}</td>
                    <td>
                      <span className="badge bg-success fs-6">
                        {classroom.name}
                      </span>
                    </td>
                    <td>
                      <Link to={"/classrooms/" + classroom.id + "/edit"}>
                        <button className="btn btn-primary">Edit</button>
                      </Link>
                    </td>
                    <td>
                      <Link to={"/classrooms/" + classroom.id + "/sks"}>
                        <button className="btn btn-secondary">Add SKS</button>
                      </Link>
                    </td>
                    <td>
                      <Link to={"/classrooms/" + classroom.id + "/teacher"}>
                        <button className="btn btn-warning">Add Guru</button>
                      </Link>
                    </td>
                    <td>
                      <Link to={"/classrooms/" + classroom.id + "/homeroom"}>
                        <button className="btn btn-info">Add Walikelas</button>
                      </Link>
                    </td>
                    <td>
                      <Link to={"/classrooms/" + classroom.id + "/kemuh"}>
                        <button className="btn btn-dark">Add Guru Kemuh</button>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={"/classrooms/" + classroom.id + "/quranteacher"}
                      >
                        <button className="btn btn-purple text-white">
                          Add Guru Qur'an
                        </button>
                      </Link>
                    </td>
                    <td>
                      <Link to={"/classrooms/" + classroom.id + "/student"}>
                        <button className="btn btn-success">Add Siswa</button>
                      </Link>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => delData(classroom.id)}
                      >
                        Hapus Kelas
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default ClassroomListComponent;
