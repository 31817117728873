import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import { postData } from "../utils/ClassroomFunct";

const CompetenceScoreComponent = ({ competence, students, setStudents }) => {
  const [scroll, setScroll] = useState(false);
  const [colheight, setColheight] = useState(54);
  const redirect = useNavigate();

  const average = (data) => {
    const number = data.map((row) => parseFloat(row.score));
    const sum = number.reduce((partialSum, a) => partialSum + a, 0);
    const avg = sum / number.length;
    const roundedString = Math.round(avg);

    return roundedString;
  };

  const handleNull = (value) => {
    if (value === null) {
      value = "";
    } else {
      value = value;
    }

    return value;
  };

  const handleTask = (e, sindex, tindex) => {
    let value = e.target.value;
    let oldsiswa = { ...students[sindex] };

    const onlyNumbers = value.replace(/[^\d.]/g, "");
    if (isNaN(onlyNumbers)) {
      onlyNumbers = 0;
    }

    oldsiswa.score.tasks[tindex] = {
      name: oldsiswa.score.tasks[tindex].name,
      score: onlyNumbers,
    };

    oldsiswa.score.score = average(oldsiswa.score.tasks);

    const record = [
      ...students.slice(0, sindex),
      oldsiswa,
      ...students.slice(sindex + 1),
    ];

    // console.log(record);

    setStudents(record);
  };

  const handleKeterangan = (e, index) => {
    const student = { ...students[index] };
    student.score.description = e.target.value;
    const record = [
      ...students.slice(0, index),
      student,
      ...students.slice(index + 1),
    ];

    setStudents(record);
  };

  const updateNilai = async () => {
    const send = await postData("competences/archive/update", {
      competence,
      students,
    });

    if (send.status === 200) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: send.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: send.data.message,
        showConfirmButton: true,
      });
    }
  };

  const trHeightAppend = () => {
    const tbody = document.getElementsByTagName("tbody")[1];
    if (tbody.childNodes.length > 0) {
      const trHeight = tbody.childNodes[0].cells[0].clientHeight;
      setColheight(trHeight);
    }
  };

  const tableScrollLeft = () => {
    trHeightAppend();
    const scrollValue =
      document.getElementsByClassName("table-responsive")[0].scrollLeft;

    if (scrollValue > 200) {
      setScroll(true);
    } else if (scrollValue < 200) {
      setScroll(false);
    }
  };

  return (
    <div className="col">
      {students.length > 0 ? (
        <div className="row row-cols-1">
          <div
            className="col table-responsive mb-2"
            onTouchMove={() => tableScrollLeft()}
          >
            <div className="col">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr className="text-center">
                    <th scope="col">#</th>
                    <th scope="col">NISN</th>
                    <th scope="col">NIS</th>
                    <th scope="col">Nama Siswa</th>
                    {competence.tasks !== null
                      ? competence.tasks.map((row, index) => (
                          <th key={index} className="text-center" scope="col">
                            {row}
                          </th>
                        ))
                      : null}
                    <th scope="col">
                      {competence?.categorie?.score_type === "predikat"
                        ? "Predikat"
                        : "Rata - Rata"}
                    </th>
                    <th>Keterangan</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((siswa, sindex) => (
                    <tr key={sindex}>
                      <th className="text-center">{sindex + 1}</th>
                      <td className="text-center">{siswa.nisn}</td>
                      <td className="text-center">{siswa.nis}</td>
                      <td
                        className={scroll ? "absolute-col" : null}
                        style={{ height: colheight + "px" }}
                      >
                        {siswa.name}
                      </td>
                      {siswa.score.tasks.length > 0
                        ? siswa.score.tasks.map((task, tindex) => (
                            <td className="text-center" key={tindex}>
                              <input
                                type="text"
                                className="form-control text-center"
                                value={task.score}
                                onChange={(e) => handleTask(e, sindex, tindex)}
                              />
                            </td>
                          ))
                        : null}
                      <td className="text-center">{siswa.score.score}</td>
                      <td>
                        <textarea
                          className="form-control"
                          value={handleNull(siswa.score.description)}
                          onChange={(e) => handleKeterangan(e, sindex)}
                        ></textarea>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col">
            <button
              type="button"
              onClick={() => redirect(-1)}
              className="btn btn-warning"
            >
              BACK
            </button>
            <button
              className="btn btn-primary float-end"
              onClick={() => updateNilai()}
            >
              UPDATE
            </button>
          </div>
        </div>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default CompetenceScoreComponent;
