import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import LoadingComponent from "../components/LoadingComponent";
import {
  adminattitudelist,
  authenticated,
  competencedetail,
  scorelist,
  studentlist,
} from "../store";
import NoteCreateScoreComponent from "../components/NoteCreateScoreComponent";

const NoteCreateScoreContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [scores, setScores] = useRecoilState(scorelist);
  const [students, setStudents] = useRecoilState(studentlist);
  const [competence, setCompetence] = useRecoilState(competencedetail);
  const [adminattitudes, setAdminattitudes] = useRecoilState(adminattitudelist);
  const [errors, setErrors] = useState("");
  const { classroom_id, academic_id, period_id } = useParams();
  const params = classroom_id + "/" + academic_id + "/" + period_id;
  const redirect = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get("/attitudes/" + params + "/detail", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setScores(response.data);
        setStudents(response.data.students);
        setAdminattitudes(response.data.attitudes);
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      redirect("/");
    }

    setCompetence(false);
    getData();
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Catatan Kelas{" "}
          <span className="badge bg-success fs-5">
            {scores?.classroom?.name}
          </span>{" "}
          <span className="badge bg-success fs-5">
            {scores?.academic?.nickname}
          </span>{" "}
          <span className="badge bg-success fs-5">
            {scores?.academic?.period.name}
          </span>
        </h2>
      </div>
      {scores ? (
        <div className="card-body">
          <div className="row">
            <NoteCreateScoreComponent />
          </div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default NoteCreateScoreContainer;
