import axios from "axios";
import React from "react";

const AcademicComboComponent = ({ academics, setArchives, setErrors }) => {
  const getArchive = async (academic_id) => {
    try {
      let response = await axios.get("/archives/academic/" + academic_id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setArchives(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  return (
    <div className="row mb-5">
      <div className="col">
        <select
          className="form-select"
          onChange={(e) => getArchive(e.target.value)}
        >
          <option>===Pilih Tahun Ajaran===</option>
          {academics.length > 0
            ? academics.map((row, index) => (
                <option key={index} value={row.id}>
                  {row.name}
                </option>
              ))
            : null}
        </select>
      </div>
    </div>
  );
};

export default AcademicComboComponent;
