import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authenticated } from "../../store";
import IsmubaLedgerClassroomComponent from "./IsmubaLedgerClassroomComponent";

const IsmubaLedgerClassroomContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [classrooms, setClassrooms] = useState([]);
  const [errors, setErrors] = useState([]);
  const { type } = useParams();
  const navigate = useNavigate();

  const getClassrooms = async () => {
    try {
      let response = await axios.get(`/classrooms/ismuba/homeroom/${type}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setClassrooms(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    var mounted = true;
    if (mounted) {
      getClassrooms();
    }
    return () => {
      mounted = false;
    };
  }, [auth.login, type]);

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="text-capitalize">Ledger Ismuba {type} </h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="alert alert-warning">{errors.message}</div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <IsmubaLedgerClassroomComponent classrooms={classrooms} />
        </div>
      </div>
    </div>
  );
};

export default IsmubaLedgerClassroomContainer;
