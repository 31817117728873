import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated } from "../../store";
import SuratFormComponent from "./SuratFormComponent";

const SuratFormContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [errors, setErrors] = useState([]);
  const [records, setRecords] = useState({});
  const navigate = useNavigate();

  const formHandle = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post("/surats", records, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setRecords({ name: "", number: "" });
        setErrors([]);
      }

      // console.log(response.status);
    } catch (e) {
      if (e.response.status === 401) {
        setAuth({ login: false, user: [] });
      }
      setErrors(e.response.data);
    }
  };

  const handleInput = (input) => {
    const change = { ...records };
    change[input.name] = input.value;
    setRecords(change);
  };
  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Create Surat</h2>
      </div>
      <div className="card-body">
        <SuratFormComponent
          handleInput={(input) => handleInput(input)}
          formHandle={(e) => formHandle(e)}
          records={records}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default SuratFormContainer;
