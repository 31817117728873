import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import ImportComponent from "../../components/ImportComponent";
import SmallLoadingComponent from "../../components/SmallLoadingComponent";
import { authenticated, resurats } from "../../store";
import { ismubaKeterangan } from "../../utils/NilaiStudent";
import SuratModalComponent from "../components/SuratModalComponent";
import QuranNilaiStudentComponent from "./QuranNilaiStudentComponent";

const QuranNilaiStudentContainer = () => {
  const auth = useRecoilValue(authenticated);
  const [surats, setSurats] = useRecoilState(resurats);
  const [avg_quran, setAvg_quran] = useState(0);
  const [ketquran, setKetquran] = useState("");
  const { classroom_id, period_id, student_id } = useParams();
  const [classroom, setClassroom] = useState(false);
  const [student, setStudent] = useState([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let response = await axios.get(
        "/regulers/quran/classroom/" +
          classroom_id +
          "/period/" +
          period_id +
          "/student/" +
          student_id,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setClassroom(response.data.classroom);
      setStudent(response.data.student);
      setAvg_quran(response.data.student.nilaiquran.avg_quran);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const inputNilai = async (tambahan) => {
    try {
      let response = await axios.post(
        "regulers",
        {
          classroom_id,
          period_id,
          academic_id: classroom.academic_id,
          student_id: student.id,
          surats: surats,
          hafalan_tambahan: tambahan,
          keterangan: ketquran,
          avg_quran: avg_quran,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      // console.log(response.data);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: e.response.data.message,
        showConfirmButton: true,
      });

      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  const avgQuran = (data) => {
    const number = data.map((row) => parseFloat(row.avg_surat));
    const sum = number.reduce((a, b) => a + b, 0);
    const avg = sum / number.length;
    let roundedString = Math.round(avg);
    if (isNaN(roundedString)) roundedString = 0;
    setAvg_quran(roundedString);
    setKetquran(ismubaKeterangan(roundedString));
    // const roundedString = avg.toFixed(2);
    // const rounded = Number(roundedString);

    return roundedString;
  };

  const handleImportSurat = (newsurats) => {
    setSurats(newsurats);

    const avgquran = avgQuran(newsurats);
    setAvg_quran(avgquran);
  };

  useEffect(() => {
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    var mounted = true;
    if (mounted) {
      getData();
    }
    return () => {
      mounted = false;
    };
  }, [auth.login]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Qur'an Nilai Siswa{" "}
          <span className="badge bg-success fs-5 text-wrap">
            {student?.name}
          </span>{" "}
        </h2>
      </div>
      <div className="card-body">
        <div className="row">
          {errors.message ? (
            <div className="col">
              <div className="alert alert-warning mb-5">{errors.message}</div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row mb-5">
          <div className="col-6">
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Nama:</strong>
                <br />
                <span className="badge bg-success fs-6 text-wrap">
                  {student?.name}
                </span>
              </li>
              <li className="list-group-item">
                <strong>Kelas:</strong>
                <br />
                <span className="badge bg-success fs-6 text-wrap">
                  {classroom?.name}
                </span>
              </li>
              <li className="list-group-item">
                <strong>Tahun Ajaran:</strong>
                <br />
                <span className="badge bg-success fs-6 text-wrap">
                  {classroom?.academic?.name}
                </span>
              </li>
              <li className="list-group-item">
                <span className="badge bg-success fs-6 text-wrap">
                  Semester {classroom?.academic?.period?.name}
                </span>
              </li>
            </ul>
          </div>
          <div className="col-6">
            <SuratModalComponent />
            <ImportComponent
              url="imports/nilai/surat"
              setCollection={(update) => handleImportSurat(update)}
            />
          </div>
        </div>
        <div className="row">
          {student.name ? (
            <QuranNilaiStudentComponent
              kkm={classroom?.academic?.period?.kkm}
              nilaiquran={student.nilaiquran}
              avg_quran={avg_quran}
              ketquran={ketquran}
              updateAvgQuran={(datasurats) => avgQuran(datasurats)}
              handleKeterangan={(avg) => ismubaKeterangan(avg)}
              insertNilai={(tambahan) => inputNilai(tambahan)}
            />
          ) : (
            <SmallLoadingComponent />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuranNilaiStudentContainer;
