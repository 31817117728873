import axios from "axios";
import React, { useState } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import { archivelist, authenticated } from "../store";
import StudentArchiveListComponent from "./StudentArchiveListComponent";

const StudentArchiveComboComponent = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [archives, setArchives] = useRecoilState(archivelist);
  const [errors, setErrors] = useState("");

  const getArchive = async (academic_id) => {
    try {
      let response = await axios.get("/archives/academic/" + academic_id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setArchives(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);
      setAuth({ login: false, user: [] });
      // console.log(e.response.data);
    }
  };

  return (
    <Fragment>
      <div className="row mb-4">
        <div className="col">
          <label className="mb-2">Kelas</label>
          <select
            className="form-select"
            onChange={(e) => getArchive(e.target.value)}
          >
            <option>===Pilih Kelas===</option>
            {archives
              ? archives.academics.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))
              : null}
          </select>
        </div>
        <div className="col">
          <label className="mb-2">Tahun Ajaran</label>
          <select
            className="form-select"
            onChange={(e) => getArchive(e.target.value)}
          >
            <option>===Pilih Tahun Ajaran===</option>
            {archives
              ? archives.academics.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.name}
                  </option>
                ))
              : null}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <StudentArchiveListComponent />
        </div>
      </div>
    </Fragment>
  );
};

export default StudentArchiveComboComponent;
