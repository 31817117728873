import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { useRecoilState } from "recoil";
import CompetenceClassroomComponent from "../components/CompetenceClassroomComponent";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import {
  authenticated,
  competencedetail,
  competencelist,
  scorelist,
  studentlist,
} from "../store";
import ArchiveClassroomComponent from "./ArchiveClassroomComponent";

const ArchiveClassroomContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [competences, setCompetences] = useRecoilState(competencelist);
  const [competence, setCompetence] = useRecoilState(competencedetail);
  const [scores, setScores] = useRecoilState(scorelist);
  const [students, setStudents] = useRecoilState(studentlist);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("competences/classroomuser/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setCompetences(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    setCompetence(false);
    setScores(false);
    setStudents(false);
    if (!auth.login) {
      navigate("/", { replace: true });
    }
    getData();
  }, [auth.login]);

  return (
    <div>
      {competences ? (
        <Fragment>
          {competences.categories.map((row, index) => (
            <ArchiveClassroomComponent
              key={index}
              data={row}
              classroomuser={competences.classroomuser}
            />
          ))}
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-warning float-end"
                onClick={() => navigate(-1)}
              >
                BACK
              </button>
            </div>
          </div>
        </Fragment>
      ) : (
        <SmallLoadingComponent />
      )}
    </div>
  );
};

export default ArchiveClassroomContainer;
