import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import SmallLoadingComponent from "../components/SmallLoadingComponent";
import StudentEditFormComponent from "../components/StudentEditFormComponent";
import { studentdetail } from "../store";

const StudentEditFormContainer = () => {
  const [student, setStudent] = useRecoilState(studentdetail);
  const [errors, setErrors] = useState(false);
  const { id } = useParams();

  const getData = async () => {
    try {
      let response = await axios.get("/students/" + id + "/edit", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      setStudent(response.data);

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  const browserDetect = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }

    return browserName;
  };

  const exportCover = async () => {
    try {
      const params = student.id;

      let response = await axios.get("exports/" + params + "/cover", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const filename = student.name + "-" + student.nis + "-Cover";
        const blob = new Blob([response.data], { type: "application/pdf" });

        if (browserDetect() === "firefox") {
          const fileUrl = URL.createObjectURL(blob);
          const w = window.open(fileUrl, "_blank");
          w && w.focus();
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${filename}.pdf`;
          link.click();
        }
      }

      // console.log(response.data);
    } catch (e) {
      setErrors(e.response.data);

      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Edit Siswa {student ? student.name : ""}</h2>
      </div>
      <div className="card-body">
        {student ? (
          <div className="row">
            <div className="col-12 mb-4">
              <button
                type="button"
                className="btn btn-success float-end"
                onClick={() => exportCover()}
              >
                COVER
              </button>
            </div>
            <div className="col-12">
              <StudentEditFormComponent updateData={() => getData()} />
            </div>
          </div>
        ) : (
          <SmallLoadingComponent />
        )}
      </div>
    </div>
  );
};

export default StudentEditFormContainer;
