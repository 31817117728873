import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import CompetenceFormComponent from "../components/CompetenceFormComponent";
import LoadingComponent from "../components/LoadingComponent";
import { authenticated, competencedetail } from "../store";

const CompetenceFormContainer = () => {
  const [auth] = useRecoilState(authenticated);
  const [competence, setCompetence] = useRecoilState(competencedetail);
  const [errors, setErrors] = useState(false);
  const { id, categorie_id } = useParams();
  const redirect = useNavigate();
  const params = id + "/" + categorie_id;

  const getData = async () => {
    try {
      let response = await axios.get(
        "/competences/classroomuser/" + params + "/create",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setCompetence(response.data);
      }
    } catch (e) {
      setErrors(e.response.data);
      // console.log(e.response.data);
    }
  };

  useEffect(() => {
    setCompetence(false);
    if (!auth.login) {
      redirect("/");
    }
    getData();
  }, [auth.login]);
  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Create Kompetensi Pada Kelas{" "}
          {competence?.classroomuser?.classroom?.name}
        </h2>
      </div>
      <div className="card-body">
        {competence ? (
          <CompetenceFormComponent />
        ) : (
          [
            errors ? (
              <h2 key={0}>{errors.message}</h2>
            ) : (
              <LoadingComponent key={1} />
            ),
          ]
        )}
      </div>
    </div>
  );
};

export default CompetenceFormContainer;
