import React, { useState } from "react";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { authenticated } from "../store";
import { addTeacher, deleteData } from "../utils/ClassroomFunct";
import JoinClassroomComponent from "./JoinClassroomComponent";

const TeacherClassroomListComponent = ({ classrooms, updateData }) => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [show, setShow] = useState(false);
  const [classroom_id, setClassroom_id] = useState("");
  const [classroom_name, setClassroom_name] = useState("");
  const [action, setAction] = useState("");

  const joinToClass = (classroomId, classroomName) => {
    setShow(!show);
    setClassroom_id(classroomId);
    setClassroom_name(classroomName);
    setAction("masuk");
  };

  const outFromClass = (classroomId, classroomName) => {
    setShow(!show);
    setClassroom_id(classroomId);
    setClassroom_name(classroomName);
    setAction("keluar");
  };

  const authUserChange = (property, value) => {
    let change = JSON.parse(JSON.stringify(auth));
    change.user[property] = value;
    // console.log(change);
    setAuth(change);
  };

  const addToKemuh = async (uri, user_id, classroom_id, academic_id) => {
    const action = await addTeacher(uri, user_id, classroom_id, academic_id);

    if (action.status === 200) {
      Swal.fire("Good job!", "Berhasil jadi guru kemuh!", "success");
      updateData();

      if (auth.user.kemuh === false) authUserChange("kemuh", true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: action.message,
      });
    }
  };

  const delKemuh = async (uri) => {
    const action = await deleteData(uri);
    if (action.status === 200) {
      Swal.fire("Good job!", "Berhasil keluar dari kemuh!", "success");
      updateData();

      if (action.data.delkemuhmenu) authUserChange("kemuh", false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: action.message,
      });
    }
  };

  const addToQuran = async (uri, user_id, classroom_id, academic_id) => {
    const action = await addTeacher(uri, user_id, classroom_id, academic_id);

    if (action.status === 200) {
      Swal.fire("Good job!", "Berhasil jadi guru Qur'an!", "success");
      updateData();

      if (auth.user.quran === false) authUserChange("quran", true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: action.message,
      });
    }
  };

  const delQuran = async (uri) => {
    const action = await deleteData(uri);
    if (action.status === 200) {
      Swal.fire("Good job!", "Berhasil keluar dari guru Qur'an!", "success");
      updateData();

      if (action.data.delquranmenu) authUserChange("quran", false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: action.message,
      });
    }
  };

  const buttonKemuh = (classroom) => {
    if (classroom.kemuh === null) {
      return (
        <button
          className="btn btn-warning"
          onClick={() =>
            addToKemuh(
              "kemuhs",
              auth.user.id,
              classroom.id,
              classroom.academic_id
            )
          }
        >
          JADI GURU KEMUH
        </button>
      );
    } else if (auth.user.id !== classroom.kemuh.user_id) {
      return (
        <button className="btn btn-dark disabled">SUDAH ADA GURU LAIN</button>
      );
    } else {
      return (
        <button
          disabled={true}
          className="btn btn-danger"
          onClick={() => {
            delKemuh(`kemuhs/${classroom.kemuh.id}`);
          }}
        >
          KELUAR DARI GURU KEMUH
        </button>
      );
    }
  };

  const buttonQuran = (classroom) => {
    if (classroom.quranteacher === null) {
      return (
        <button
          className="btn btn-primary"
          onClick={() =>
            addToQuran(
              "qurans",
              auth.user.id,
              classroom.id,
              classroom.academic_id
            )
          }
        >
          JADI GURU QUR'AN
        </button>
      );
    } else if (auth.user.id !== classroom.quranteacher.user_id) {
      return (
        <button className="btn btn-dark disabled">SUDAH ADA GURU LAIN</button>
      );
    } else {
      return (
        <button
          disabled={true}
          className="btn btn-danger"
          onClick={() => {
            delQuran(`qurans/${classroom.quranteacher.id}`);
          }}
        >
          KELUAR DARI GURU QUR'AN
        </button>
      );
    }
  };

  return (
    <div>
      <div className="col table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">NAMA KELAS</th>
              <th className="text-center">PELAJARAN</th>
              <th colSpan={4} className="text-center" scope="col">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            {classrooms.map((classroom, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  <span className="badge bg-success fs-6">
                    {classroom.name}
                  </span>
                </td>
                <td>
                  {classroom.classroomusers.map((classroomuser, index) => (
                    <span key={index} className="badge bg-primary mx-1">
                      {classroomuser.lesson.name}
                    </span>
                  ))}
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => joinToClass(classroom.id, classroom.name)}
                  >
                    JADI GURU DI KELAS INI
                  </button>
                </td>
                <td className="text-center">
                  <button
                    disabled={true}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => outFromClass(classroom.id, classroom.name)}
                  >
                    KELUAR DARI KELAS INI
                  </button>
                </td>
                <td className="text-center">{buttonKemuh(classroom)}</td>
                <td className="text-center">{buttonQuran(classroom)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {show ? (
        <JoinClassroomComponent
          close={() => setShow(!show)}
          classroom_id={classroom_id}
          classroom_name={classroom_name}
          action={action}
          refreshData={() => updateData()}
        />
      ) : null}
    </div>
  );
};

export default TeacherClassroomListComponent;
